import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  FlexContainer,
  Item,
  StyledLeftContainer,
  StyledRightContainer,
  StyledTextLeft,
  StyledTextRight,
  TypographyH4,
  TypographyH6
} from './style';
import { Box, Divider, Skeleton, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from '../../../../../store';
import { CommonErrorTooltip } from '../../../../../components/CommonErrorTooltip';
import { GLOBALS } from '../../../../../globals/Globals';
import { Label } from '../../../../../components/Label';

export const TokenPriceHistory = () => {
  const { t } = useTranslation();

  const { tokenDetails, basicTokenDetails, tokenPriceHistory } = useSelector(
    (state) => state.crypto
  );

  const _renderAllTimeHigh = () => {
    const { loading, error, data } = tokenDetails;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.allTimeHigh.price_in_usd}
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
        isNumberAsString
        isShortFlag
        prefixData="$"
      />
    );
  };

  const _renderAllTimeLow = () => {
    const { loading, error, data } = tokenDetails;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.allTimeLow.price_in_usd}
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
        isNumberAsString
        isShortFlag
        prefixData="$"
      />
    );
  };

  const _renderSeventhDayLowestPrice = () => {
    const { loading, error, data } = tokenPriceHistory;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.sevenDayPriceDetails.lowestPriceInUsd}
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
        isNumberAsString
        isShortFlag
        prefixData="$"
      />
    );
  };

  const _renderSeventhDayHighestPrice = () => {
    const { loading, error, data } = tokenPriceHistory;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.sevenDayPriceDetails.highestPriceInUsd}
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
        isNumberAsString
        isShortFlag
        prefixData="$"
      />
    );
  };

  const _renderThirtyDayLowestPrice = () => {
    const { loading, error, data } = tokenPriceHistory;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.thirtyDayPriceDetails.lowestPriceInUsd}
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
        isNumberAsString
        isShortFlag
        prefixData="$"
      />
    );
  };

  const _renderThirtyDayHighestPrice = () => {
    const { loading, error, data } = tokenPriceHistory;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.thirtyDayPriceDetails.highestPriceInUsd}
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
        isNumberAsString
        isShortFlag
        prefixData="$"
      />
    );
  };

  const _renderNinetyDayLowestPrice = () => {
    const { loading, error, data } = tokenPriceHistory;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.ninetyDayPriceDetails.lowestPriceInUsd}
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
        isNumberAsString
        isShortFlag
        prefixData="$"
      />
    );
  };

  const _renderNinetyDayHighestPrice = () => {
    const { loading, error, data } = tokenPriceHistory;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.ninetyDayPriceDetails.highestPriceInUsd}
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
        isNumberAsString
        isShortFlag
        prefixData="$"
      />
    );
  };

  const _renderFiftyTwoWeekLowestPrice = () => {
    const { loading, error, data } = tokenPriceHistory;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.fiftyTwoWeekPriceDetails.lowestPriceInUsd}
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
        isNumberAsString
        isShortFlag
        prefixData="$"
      />
    );
  };

  const _renderFiftyTwoWeekHighestPrice = () => {
    const { loading, error, data } = tokenPriceHistory;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.fiftyTwoWeekPriceDetails.highestPriceInUsd}
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
        isNumberAsString
        isShortFlag
        prefixData="$"
      />
    );
  };

  const _renderAllTimeHighTime = () => {
    const { loading, error, data } = tokenDetails;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return data.allTimeHigh
      ? new Date(data.allTimeHigh.__time).toDateString()
      : data.allTimeHigh
      ? parseInt(
          (new Date() - new Date(data.allTimeHigh.__time)) /
            (1000 * 60 * 60 * 24),
          10
        )
      : 'Days ago';
  };

  const _renderAllTimeLowTime = () => {
    const { loading, error, data } = tokenDetails;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return data.allTimeLow
      ? new Date(data.allTimeLow.__time).toDateString()
      : data.allTimeLow
      ? parseInt(
          (new Date() - new Date(data.allTimeLow.__time)) /
            (1000 * 60 * 60 * 24),
          10
        )
      : 'Days ago';
  };

  const _renderTokenPriceRoi = () => {
    const { loading, error, data } = basicTokenDetails;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.roi_percentage}
        isNumberAsString
        isShortFlag
        suffixData="%"
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
      />
    );
  };

  const _renderTokenCirculatingSupply = () => {
    const { loading, error, data } = basicTokenDetails;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.circulating_supply}
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
        isNumberAsString
        isShortFlag
      />
    );
  };

  const _renderTokenTotalSupply = () => {
    const { loading, error, data } = basicTokenDetails;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.total_supply}
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
        isNumberAsString
        isShortFlag
      />
    );
  };

  const _renderTokenMaxSupply = () => {
    const { loading, error, data } = basicTokenDetails;
    if (!_.isEmpty(error) || error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={null} // TODO: Max supply is not preset at the moment NULL
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
        isNumberAsString
        isShortFlag
        prefixData="$"
      />
    );
  };

  const _renderTokenName = (additionalText) => {
    const { loading, error, data } = basicTokenDetails;
    if (loading && _.isEmpty(error)) {
      return <Skeleton animation="wave" height={20} width="100px" />;
    } else if (_.isEmpty(error) && error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return t(`${data.name} ${additionalText}`);
  };

  return (
    <Item>
      <Box>
        <TypographyH4 variant="h4" component="h4">
          {_renderTokenName('Price History')}
        </TypographyH4>
      </Box>
      <FlexContainer>
        <StyledLeftContainer variant="subtitle1">
          <StyledTextLeft>{t('7d Low / 7d High')}</StyledTextLeft>
        </StyledLeftContainer>
        <StyledRightContainer>
          {tokenPriceHistory.loading ? (
            <Skeleton
              animation="wave"
              height={10}
              width="200px"
              style={{ marginBottom: 6 }}
            />
          ) : (
            <>
              <StyledTextRight>
                {_renderSeventhDayLowestPrice()}
              </StyledTextRight>
              <StyledTextRight>
                {_renderSeventhDayHighestPrice()}
              </StyledTextRight>
            </>
          )}
        </StyledRightContainer>
      </FlexContainer>
      <Divider variant="middle" />
      <FlexContainer>
        <StyledLeftContainer variant="subtitle1">
          <StyledTextLeft>{t('30d Low / 30d High')}</StyledTextLeft>
        </StyledLeftContainer>
        <StyledRightContainer>
          {tokenPriceHistory.loading ? (
            <>
              <Skeleton
                animation="wave"
                height={10}
                width="200px"
                style={{ marginBottom: 6 }}
              />
            </>
          ) : (
            <>
              <StyledTextRight>{_renderThirtyDayLowestPrice()}</StyledTextRight>
              <StyledTextRight>
                {_renderThirtyDayHighestPrice()}
              </StyledTextRight>
            </>
          )}
        </StyledRightContainer>
      </FlexContainer>
      <Divider variant="middle" />
      <FlexContainer>
        <StyledLeftContainer variant="subtitle1">
          <StyledTextLeft>{t('90d Low / 90d High')}</StyledTextLeft>
        </StyledLeftContainer>
        <StyledRightContainer>
          {tokenPriceHistory.loading ? (
            <Skeleton
              animation="wave"
              height={10}
              width="200px"
              style={{ marginBottom: 6 }}
            />
          ) : (
            <>
              <StyledTextRight>{_renderNinetyDayLowestPrice()}</StyledTextRight>
              <StyledTextRight>
                {_renderNinetyDayHighestPrice()}
              </StyledTextRight>
            </>
          )}
        </StyledRightContainer>
      </FlexContainer>
      <Divider variant="middle" />
      <FlexContainer>
        <StyledLeftContainer variant="subtitle1">
          <StyledTextLeft>{t('52 Week Low / 52 Week High')}</StyledTextLeft>
        </StyledLeftContainer>
        <StyledRightContainer>
          {tokenPriceHistory.loading ? (
            <Skeleton
              animation="wave"
              height={10}
              width="200px"
              style={{ marginBottom: 6 }}
            />
          ) : (
            <>
              <StyledTextRight>
                {_renderFiftyTwoWeekLowestPrice()}
              </StyledTextRight>
              <StyledTextRight>
                {_renderFiftyTwoWeekHighestPrice()}
              </StyledTextRight>
            </>
          )}
        </StyledRightContainer>
      </FlexContainer>
      <Divider variant="middle" />
      <FlexContainer>
        {tokenDetails.loading ? (
          <Skeleton
            animation="wave"
            height={10}
            width="200px"
            style={{ marginBottom: 6 }}
          />
        ) : (
          <>
            <StyledLeftContainer variant="subtitle1">
              <Box>
                <StyledTextLeft>{t('All Time High')}</StyledTextLeft>
                <StyledTextLeft>{_renderAllTimeHighTime()}</StyledTextLeft>
              </Box>
            </StyledLeftContainer>
            <StyledRightContainer>
              {tokenDetails.loading ? (
                <Skeleton
                  animation="wave"
                  height={10}
                  width="200px"
                  style={{ marginBottom: 6 }}
                />
              ) : (
                <>
                  <StyledTextLeft>{_renderAllTimeHigh()}</StyledTextLeft>
                  <Label
                    color={
                      Math.sign(
                        tokenDetails.data.allTimeHigh.percentage_change
                      ) === 1
                        ? 'success'
                        : 'error'
                    }
                  >
                    <CommonErrorTooltip
                      data={tokenDetails.data.allTimeHigh.percentage_change}
                      isNumberAsString
                      isShortFlag
                      suffixData="%"
                      style={{
                        whiteSpace: 'noWrap'
                      }}
                    />
                  </Label>
                </>
              )}
            </StyledRightContainer>
          </>
        )}
      </FlexContainer>
      <Divider variant="middle" />
      <FlexContainer>
        {tokenDetails.loading ? (
          <>
            <Skeleton
              animation="wave"
              height={10}
              width="200px"
              style={{ marginBottom: 6 }}
            />
          </>
        ) : (
          <>
            <StyledLeftContainer variant="subtitle1">
              <Box>
                <StyledTextLeft>{t('All Time Low')}</StyledTextLeft>
                <StyledTextLeft>{_renderAllTimeLowTime()}</StyledTextLeft>
              </Box>
            </StyledLeftContainer>
            <StyledRightContainer>
              {tokenDetails.loading ? (
                <Skeleton
                  animation="wave"
                  height={10}
                  width="200px"
                  style={{ marginBottom: 6 }}
                />
              ) : (
                <>
                  <StyledTextRight>{_renderAllTimeLow()}</StyledTextRight>{' '}
                  <Label
                    color={
                      Math.sign(
                        tokenDetails.data.allTimeLow.percentage_change
                      ) === 1
                        ? 'success'
                        : 'error'
                    }
                  >
                    <CommonErrorTooltip
                      data={tokenDetails.data.allTimeLow.percentage_change}
                      isNumberAsString
                      isShortFlag
                      suffixData="%"
                      style={{
                        whiteSpace: 'noWrap'
                      }}
                    />
                  </Label>
                </>
              )}
            </StyledRightContainer>
          </>
        )}
      </FlexContainer>
      <Divider variant="middle" />
      <FlexContainer>
        <StyledLeftContainer variant="subtitle1">
          <StyledTextLeft>{_renderTokenName('ROI')}</StyledTextLeft>
          <Box sx={{ p: 1, display: 'flex' }}>
            <Tooltip title={GLOBALS.CRYPTO_ROI} arrow>
              <InfoOutlinedIcon fontSize="small" sx={{ cursor: 'pointer' }} />
            </Tooltip>
          </Box>
        </StyledLeftContainer>
        <StyledRightContainer>
          {tokenPriceHistory.loading ? (
            <>
              <Skeleton
                animation="wave"
                height={10}
                width="200px"
                style={{ marginBottom: 6 }}
              />
            </>
          ) : (
            <StyledTextRight>{_renderTokenPriceRoi()}</StyledTextRight>
          )}
        </StyledRightContainer>
      </FlexContainer>
      <Divider variant="middle" />
      <Box>
        <TypographyH6 variant="subtitle1">
          {basicTokenDetails.loading && basicTokenDetails.error === '' ? (
            <Skeleton animation="wave" height={20} width="100px" />
          ) : (
            t(`${basicTokenDetails.data.name} Supply`)
          )}
        </TypographyH6>
        <FlexContainer>
          <StyledLeftContainer variant="subtitle1">
            <StyledTextLeft>{t('Circulating Supply')}</StyledTextLeft>
          </StyledLeftContainer>
          <StyledRightContainer>
            {tokenDetails.loading ? (
              <>
                <Skeleton
                  animation="wave"
                  height={10}
                  width="200px"
                  style={{ marginBottom: 6 }}
                />
              </>
            ) : (
              <StyledTextRight>
                {_renderTokenCirculatingSupply()}
              </StyledTextRight>
            )}
          </StyledRightContainer>
        </FlexContainer>
        <Divider variant="middle" />
        <FlexContainer>
          <StyledLeftContainer variant="subtitle1">
            <StyledTextLeft>{t('Total Supply')}</StyledTextLeft>
          </StyledLeftContainer>
          {basicTokenDetails.loading ? (
            <>
              <Skeleton
                animation="wave"
                height={10}
                width="200px"
                style={{ marginBottom: 6 }}
              />
            </>
          ) : (
            <StyledRightContainer>
              <StyledTextRight>{_renderTokenTotalSupply()}</StyledTextRight>
            </StyledRightContainer>
          )}
        </FlexContainer>
        <Divider variant="middle" />
      </Box>
    </Item>
  );
};
