import React, { useEffect } from 'react';
import Chart from './Chart';
import { Box, Typography, useTheme } from '@mui/material';
import Text from '../../i18n/Text';

const InfoCard = ({ label, value }) => {
  return (
    <>
      <Text>{label}</Text>
      <Text color="success" sx={{ pl: 1 }}>
        {value}
      </Text>
      &nbsp;&nbsp;&nbsp;
    </>
  );
};

const ChartInfoCards = ({ open, close, high, low }) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        fontSize: `${theme.typography.pxToRem(14)}`
      }}
    >
      <InfoCard label="Open" value={open} />
      <InfoCard label="Close" value={close} />
      <InfoCard label="High" value={high} />
      <InfoCard label="Low" value={low} />
    </Typography>
  );
};

function ChartWithFeatures({
  loading,
  dataStream = [],
  candleLevelsValue,
  showMarkers,
  handleOhlcOffsetChange
}) {
  const theme = useTheme();
  const [selectedData, setSelectedData] = React.useState(
    dataStream[dataStream.length - 1] || {}
  );
  const [markers, setMarkers] = React.useState([]);

  useEffect(() => {
    if (showMarkers) {
      let updatedMarkers = [];
      for (let i = 0; i < dataStream.length; i++) {
        if (dataStream[i].high - dataStream[i].low >= candleLevelsValue[0]) {
          const marker = {
            time: dataStream[i].time,
            position: 'belowBar',
            color: '#4000ff',
            shape: 'arrowUp',
            size: 0.5
          };
          updatedMarkers.push(marker);
        }
      }
      setMarkers(updatedMarkers);
    }
  }, [showMarkers, candleLevelsValue]);
  return (
    <Box sx={{ pt: 1 }}>
      <ChartInfoCards {...selectedData} />
      <Box sx={{ backgroundColor: theme.sidebar.background }}>
        <Chart
          loading={loading}
          dataStream={dataStream}
          setLegendInfo={setSelectedData}
          candleLevels={markers}
          showMarkers={showMarkers}
          handleOhlcOffsetChange={handleOhlcOffsetChange}
        />
      </Box>
    </Box>
  );
}

export { Chart, ChartWithFeatures };
