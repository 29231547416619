import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import {
  Box,
  Card,
  Link,
  Typography,
  styled,
  useTheme,
  Divider
} from '@mui/material';
import { ReactComponent as SpikingLogo } from '../../assets/FooterSpiking.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/youtube.svg';
import { ReactComponent as TwitterIcon } from '../../assets/twitter.svg';
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';
import { ReactComponent as LinkkedinIcon } from '../../assets/linkedin.svg';
import { ReactComponent as EmailIcon } from '../../assets/email.svg';
import { ReactComponent as MediumIcon } from '../../assets/medium.svg';
import deqodeIcon from '../../assets/deqode.png';
import { GLOBALS } from '../../globals/Globals';

const FooterWrapper = styled(Card)(
  ({ theme }) => `
    border-radius: 0;
    margin-top: ${theme.spacing(4)};
  `
);

const useCustomStyles = makeStyles((theme) => ({
  SocialIcons: {
    padding: 10,
    pt: { xs: 2, md: 0 },
    px: 5
  },
  FooterLinks: {
    color: '#fff !important',
    textDecoration: 'none'
  },
  FooterText: {
    color: 'rgba(255, 255, 255, 0.5)',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: 2
  }
}));

export const Footer = () => {
  const customClasses = useCustomStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <FooterWrapper
      className="footer-wrapper"
      sx={{
        background:
          theme.palette.mode === 'dark'
            ? theme.colors.alpha.trueBlack[100]
            : theme.footer.background
      }}
    >
      <Box
        p={4}
        display={{ xs: 'block', md: 'flex' }}
        alignItems="center"
        textAlign={{ xs: 'center', md: 'left' }}
        justifyContent="center"
        flexDirection="column"
      >
        <div>
          <img
            src={deqodeIcon}
            alt="Deqode"
            style={{ height: 60, width: 180 }}
          />
        </div>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pt: 2
          }}
        >
          <div className={customClasses.SocialIcons}>
            <Link
              href={GLOBALS.EMAIL_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <EmailIcon />
            </Link>
          </div>
          <div className={customClasses.SocialIcons}>
            <Link
              href={GLOBALS.FACEBOOK_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </Link>
          </div>
          <div className={customClasses.SocialIcons}>
            <Link
              href={GLOBALS.LINKDIN_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkkedinIcon />
            </Link>
          </div>
          <div className={customClasses.SocialIcons}>
            <Link
              href={GLOBALS.MEDIUM_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MediumIcon />
            </Link>
          </div>
          <div className={customClasses.SocialIcons}>
            <Link
              href={GLOBALS.TWITTER_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </Link>
          </div>
          <div className={customClasses.SocialIcons}>
            <Link
              href={GLOBALS.YOUTUBE_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <YoutubeIcon />
            </Link>
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            mt: 3
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ color: theme.colors.alpha.trueWhite[70] }}
          >
            {t('Deqode - an Aly Product - All Rights Reserved © 2022')}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: theme.colors.alpha.trueWhite[70] }}
          >
            {t(' Made with ')}
            <span role="img" aria-label="img" style={{ color: 'red' }}>
              ❤️
            </span>{' '}
            {t('in India')}
          </Typography>
        </Box>
      </Box>
    </FooterWrapper>
  );
};
