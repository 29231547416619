import _ from 'lodash';
import { format } from 'timeago.js';
import { GLOBALS } from '../globals/Globals';

// Get the current year
export const CurrentYear = () => new Date().getFullYear();

//  Get the ISO string from date
export const getISOString = (date) => new Date(date).toISOString();

// Check for date is in the ISO format
export const isISODate = (str) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d.toISOString() === str;
};

// Get the date string
export const getDateString = (date) => {
  if (date === null) return null;
  return format(date);
};

// Get the timestamp from number of days
export const getTimestampFromDays = (days) => {
  if (days === 0) return new Date().getTime();
  return new Date().getTime() - 24 * 60 * 60 * 1000 * days;
};

// Merge Sort
function _merge(left, right) {
  let results = [];
  let i = 0;
  let j = 0;
  while (i < left.length && j < right.length) {
    if (right[j].time > left[i].time) {
      results.push(left[i]);
      i++;
    } else {
      results.push(right[j]);
      j++;
    }
  }
  while (i < left.length) {
    results.push(left[i]);
    i++;
  }
  while (j < right.length) {
    results.push(right[j]);
    j++;
  }
  return results;
}

function mergeSort(arry) {
  if (arry.length <= 1) return arry;
  const mid = Math.floor(arry.length / 2);
  const left = mergeSort(arry.slice(0, mid));
  const right = mergeSort(arry.slice(mid));
  return _merge(left, right);
}

function sortedUniqueBy(array, iteratee) {
  if (!(array != null && array.length)) return [];
  let seen;
  let index = -1;
  let resIndex = 0;

  const result = [];

  while (++index < array.length) {
    const value = array[index];
    const computed = iteratee(value);

    if (!index || computed !== seen) {
      seen = computed;
      result[resIndex++] = value;
    }
  }
  return result;
}

function findHighLow(arr) {
  let highest = arr[0];
  let lowest = arr[0];

  for (let i = 1, len = arr.length; i < len; i++) {
    let temp = arr[i];
    lowest = temp.low < lowest.low ? temp : lowest;
    highest = temp.high > highest.high ? temp : highest;
  }

  return { lowest, highest };
}

const sortBy = (function () {
  let toString = Object.prototype.toString;
  // default parser function
  let parse = function (x) {
    return x;
  };
  // gets the item to be sorted
  let getItem = function (x) {
    let isObject = x != null && typeof x === 'object';
    let isProp = isObject && this.prop in x;
    return this.parser(isProp ? x[this.prop] : x);
  };

  /**
   * Sorts an array of elements.
   *
   * @param {Array} array: the collection to sort
   * @param {Object} cfg: the configuration options
   * @property {String}   cfg.prop: property name (if it is an Array of objects)
   * @property {Boolean}  cfg.desc: determines whether the sort is descending
   * @property {Function} cfg.parser: function to parse the items to expected type
   * @return {Array}
   */
  return function sortby(array, cfg) {
    if (!(array instanceof Array && array.length)) return [];
    if (toString.call(cfg) !== '[object Object]') cfg = {};
    if (typeof cfg.parser !== 'function') cfg.parser = parse;
    cfg.desc = cfg.desc ? -1 : 1;
    return array.sort(function (a, b) {
      a = getItem.call(cfg, a);
      b = getItem.call(cfg, b);
      return cfg.desc * (a < b ? -1 : +(a > b));
    });
  };
})();

export { mergeSort, findHighLow, sortedUniqueBy, sortBy };

// Get the unique elements from object
export const getUniqueElements = (arr, comp) => {
  return [...new Set(arr.map((o) => JSON.stringify(o)))].map((s) =>
    JSON.parse(s)
  );
};

// Custom Numeral handler function
export const customNumeral = ({ value, shortFlag }) => {
  try {
    value = value.toString();
    const numToStr = value ? value.toString() : '';

    // handle the decimals numbers only exclude the numbers like (-20.123)
    if (numToStr.indexOf('.') !== -1 && numToStr[0] !== '-') {
      // handle the decimals places which bigger than > 1.0
      if (Number(numToStr) > 0.9) {
        return nFormatter(numToStr);

        // handle the decimals places which are lesser than <= 1.0 & bigger than >= 0.01
      } else if (Number(numToStr) <= 0.9 && Number(numToStr) >= 0.01) {
        return Number(numToStr.slice(0, numToStr.indexOf('.') + 5))
          .toFixed(GLOBALS.DIGITS_AFTER_DECIMAL)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // handle the decimals places which are lessar than < 0.01
      } else if (Number(numToStr) < 0.01) {
        const stringLength = numToStr.length;

        if (shortFlag) {
          // get the first index of the value
          const firstIndexOf = getIndexOf(numToStr);
          const lastIndexOf = Math.min(numToStr.length, firstIndexOf + 2);
          return numToStr.substr(0, lastIndexOf);
        }

        return `0.00...${numToStr[stringLength - 2]}${
          numToStr[stringLength - 1]
        }`;
      }

      // handle the negative values with decimals
    } else if (numToStr[0] === '-') {
      // handle the decimals places which bigger than > 1.0
      if (Math.abs(Number(numToStr)) > 0.9) {
        return `-${nFormatter(Math.abs(numToStr))}`;

        // handle the decimals places which are lesser than <= 1.0 & bigger than >= 0.01
      } else if (
        Math.abs(Number(numToStr)) <= 0.9 &&
        Math.abs(Number(numToStr)) >= 0.01
      ) {
        return Number(numToStr.slice(0, numToStr.indexOf('.') + 5))
          .toFixed(GLOBALS.DIGITS_AFTER_DECIMAL)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // handle the decimals places which are lessar than < 0.01
      } else if (Math.abs(Number(numToStr)) < 0.01) {
        const stringLength = numToStr.length;

        if (shortFlag) {
          // get the first index of the value
          const firstIndexOf = getIndexOf(numToStr);
          const lastIndexOf = Math.min(numToStr.length, firstIndexOf + 2);
          return numToStr.substr(0, lastIndexOf);
        }

        return `-0.00...${numToStr[stringLength - 2]}${
          numToStr[stringLength - 1]
        }`;
      }

      // handle the big absolute values and seperate with comma's
    } else {
      return nFormatter(numToStr);
      // return numToStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  } catch {
    return value;
  }
};

const getIndexOf = (strArr) => {
  let firstIndexOf;

  for (let i = 0; i < strArr.length; i++) {
    if (strArr[i] !== '0' && strArr[i] !== '.') {
      firstIndexOf = strArr.indexOf(strArr[i]);
      return firstIndexOf;
    }
  }
};

export const nFormatter = (num, digits = GLOBALS.DIGITS_AFTER_DECIMAL) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? Number(
        (num / item.value).toFixed(digits).replace(rx, '$1')
      ).toLocaleString() + item.symbol
    : num > 0
    ? Number(num).toFixed(digits)
    : '0';
};

// TODO; getFriendly address
export const getFriendlyAddress = ({
  address,
  digits = 4,
  separator = '...',
  prefix = '0x'
}) => {
  return _.join(
    [
      ..._.slice(address, 0, digits + prefix.length),
      separator,
      ..._.slice(address, -digits)
    ],
    ''
  );
};
