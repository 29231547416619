import { ReactComponent as CoinIcon } from '../../../assets/control_point_duplicate.svg';

const menuItems = [
  {
    items: [
      {
        id: 'daily_coins',
        name: 'Daily Coins',
        icon: CoinIcon,
        link: '/crypto'
      }
    ]
  }
];

export default menuItems;
