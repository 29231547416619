import { Box, Typography, styled, Paper } from '@mui/material';
import { experimental_sx as sx } from '@mui/system';

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left'
}));

export const TypographyH4 = styled(Typography)(
  sx({
    p: 2,
    fontSize: 16
  })
);

export const TypographyH6 = styled(Typography)(
  sx({
    backgroundColor: (theme) =>
      theme.palette.mode === 'dark'
        ? theme.colors.alpha.white[100]
        : theme.general.bodyBg,
    px: 2,
    py: 1,
    fontWeight: 'regular',
    fontSize: 13
  })
);

export const FlexContainer = styled(Box)(
  sx({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 2
  })
);

export const StyledTextLeft = styled(Typography)(
  sx({
    variant: 'subtitle1'
  })
);

export const StyledLeftContainer = styled(Typography)(
  sx({
    display: 'flex',
    gap: 1,
    alignItems: 'center'
  })
);

export const StyledRightContainer = styled(Typography)(
  sx({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  })
);

export const StyledTextRight = styled(Typography)(
  sx({
    variant: 'subtitle1',
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  })
);
