import React from 'react';
import {
  Box,
  IconButton,
  alpha,
  styled,
  useTheme,
  Typography,
  Divider
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import HeadphonesTwoToneIcon from '@mui/icons-material/HeadphonesTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import { Redirect } from 'react-router-dom';
import { GLOBALS } from '../../../globals/Globals';

export const SidebarFooter = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleLogout = async () => {
    try {
      Redirect('/crypto');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      sx={{
        height: 0
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Divider variant="fulWidth" sx={{ color: '#000', width: '200px' }} />
      <a
        href={GLOBALS.SETTING}
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <IconButton
          sx={{
            background: `${theme.colors.alpha.white[10]}`,
            color: `${theme.colors.alpha.black[70]}`,
            transition: `${theme.transitions.create(['all'])}`,

            '&:hover': {
              background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
              color: `${theme.colors.alpha.black[100]}`
            }
          }}
        >
          <SettingsTwoToneIcon fontSize="small" />
          <StyledText
            variant="subtitle1"
            px={{ xs: 0.7, md: 2 }}
            py={0.5}
            borderRadius={1}
          >
            {t('Setting')}
          </StyledText>
        </IconButton>
      </a>
      <a
        href={GLOBALS.SUPPORT}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <IconButton
          sx={{
            background: `${theme.colors.alpha.white[10]}`,
            color: `${theme.colors.alpha.black[70]}`,
            transition: `${theme.transitions.create(['all'])}`,

            '&:hover': {
              background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
              color: `${theme.colors.alpha.black[100]}`
            },
            mx: 1
          }}
        >
          <HeadphonesTwoToneIcon fontSize="small" />
          <StyledText
            variant="subtitle1"
            px={{ xs: 0.7, md: 2 }}
            py={0.5}
            borderRadius={1}
          >
            {t('Support')}
          </StyledText>
        </IconButton>
      </a>
      <a
        href={GLOBALS.LOGOUT}
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <IconButton
          sx={{
            background: `${theme.colors.alpha.white[10]}`,
            color: `${theme.colors.alpha.black[70]}`,
            transition: `${theme.transitions.create(['all'])}`,

            '&:hover': {
              background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
              color: `${theme.colors.alpha.black[100]}`
            }
          }}
          onClick={handleLogout}
        >
          <LogoutTwoToneIcon fontSize="small" />
          <StyledText
            variant="subtitle1"
            px={{ xs: 0.7, md: 2 }}
            py={0.5}
            borderRadius={1}
          >
            {t('Logout')}
          </StyledText>
        </IconButton>
      </a>
    </Box>
  );
};

const StyledText = styled(Typography)(
  ({ theme }) => `
  white-space:nowrap;
  &:hover {
    color: ${theme.colors.alpha.black[100]};
  }
`
);
