import React, { useState } from 'react';
import _ from 'lodash';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Typography
} from '@mui/material';
import { CommonErrorTooltip } from '../../../../../components/CommonErrorTooltip';
import { SkeletonLoad } from '../../../../../components/SkeletonLoad';
import {
  EnhancedTableHead,
  getComparator,
  stableSort
} from '../../../../../components/Table';
import { getDateString } from '../../../../../utils/utils';
import { useSelector } from '../../../../../store';

const headCells = [
  {
    id: 'coin_name',
    numeric: false,
    label: 'Coin',
    showArrow: true
  },
  {
    id: 'balance_number',
    numeric: true,
    label: 'Balance',
    showArrow: true
  },
  {
    id: 'weight_number',
    numeric: true,
    label: '% Weight',
    showArrow: true
  },
  {
    id: 'received_number',
    numeric: true,
    label: 'Bought',
    showArrow: true
  },
  {
    id: 'sold_number',
    numeric: true,
    label: 'Sold',
    showArrow: true
  },
  {
    id: 'firstIn',
    numeric: false,
    label: 'First In',
    showArrow: false
  }
];

export const AddressRangeTable = () => {
  const { walletAddress } = useSelector((state) => state.insiders);
  const { loading, error, data } = walletAddress;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('balance_number');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
          stickyHeader
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headCells={headCells}
          />
          <TableBody>
            {loading && error === '' ? (
              <SkeletonLoad rows={5} cols={6} />
            ) : (
              stableSort(
                data.map((item, index) => {
                  return {
                    ...item,
                    coin_name: String(item.ticker_symbol),
                    balance_number: _.toNumber(item.balance),
                    balance_string: String(item.balance),
                    weight_number: _.toNumber(item.weight),
                    weight_string: String(item.weight),
                    received_number: _.toNumber(item.received),
                    received_string: String(item.received),
                    sold_number: _.toNumber(item.send),
                    sold_string: String(item.send)
                  };
                }),
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const {
                    ticker_symbol,
                    balance_string,
                    weight_string,
                    received_string,
                    sold_string,
                    first_in
                  } = row;
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0
                        }
                      }}
                    >
                      <TableCell
                        align="center"
                        sx={{
                          position: 'sticky',
                          left: 0,
                          background: (theme) => theme.sidebar.background,
                          zIndex: 10
                        }}
                      >
                        <Typography noWrap variant="body2">
                          <b>
                            <CommonErrorTooltip
                              data={ticker_symbol}
                              isText
                              style={{ whiteSpace: 'nowrap' }}
                            />
                          </b>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography noWrap variant="body2">
                          <CommonErrorTooltip
                            data={balance_string}
                            style={{ whiteSpace: 'nowrap' }}
                            isNumberAsString
                            prefixData="$"
                          />
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography noWrap variant="body2">
                          <CommonErrorTooltip
                            data={weight_string}
                            style={{ whiteSpace: 'nowrap' }}
                            isNumberAsString
                            suffixData="%"
                          />
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography noWrap variant="body2">
                          <CommonErrorTooltip
                            data={received_string}
                            isNumberAsString
                            style={{ whiteSpace: 'nowrap' }}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography noWrap variant="body2">
                          <CommonErrorTooltip
                            data={sold_string}
                            isNumberAsString
                            style={{ whiteSpace: 'nowrap' }}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <CommonErrorTooltip
                          data={getDateString(first_in)}
                          isFirstInDate
                          style={{ whiteSpace: 'nowrap' }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
