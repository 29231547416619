import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import _ from 'lodash';
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Skeleton,
  Typography
} from '@mui/material';
import {
  Item,
  TypographyH4,
  TypographyH6,
  FlexContainer,
  StyledTextLeft,
  StyledLeftContainer,
  StyledTextRight,
  StyledRightContainer
} from './style';
import { Label } from '../../../../../components/Label';
import { useSelector, useDispatch } from '../../../../../store';
import { getVolume, getTokenPrice } from '../../../../../slices/crypto';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import { CommonErrorTooltip } from '../../../../../components/CommonErrorTooltip';
import { GLOBALS } from '../../../../../globals/Globals';

export const TokenPriceStatistics = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const tickerSymbol = location.pathname.split('/')[2];

  const dispatch = useDispatch();
  const {
    tokenDetails,
    basicTokenDetails,
    tokenPrice,
    volume,
    marketCap,
    fullyDilutedMarketCap
  } = useSelector((state) => state.crypto);

  const change = [
    {
      value: '1D',
      text: t('1D')
    },
    {
      value: '1W',
      text: t('1W')
    },
    {
      value: '2W',
      text: t('2W')
    },
    {
      value: '3W',
      text: t('3W')
    },
    {
      value: '1M',
      text: t('1M')
    }
  ];

  const [priceChangeAction, setPriceChangeAction] = useState(change[0].value);
  const [openPeriodPrice, setOpenMenuPeriodPrice] = useState(false);
  const actionPriceRef = useRef(null);

  const [priceTime, setPriceTime] = useState('1D');
  const handlePriceChangeInStatistics = (value) => {
    setPriceChangeAction(value);
    setOpenMenuPeriodPrice(false);
    setPriceTime(value);
  };

  const [volumeChangeAction, setVolumeChangeAction] = useState(change[0].value);
  const [openPeriodVolume, setOpenMenuPeriodVolume] = useState(false);
  const actionVolumeRef = useRef(null);

  const [volumeTime, setVolumeTime] = useState('1D');
  const handleVolumeChangeInStatistics = (value) => {
    setVolumeChangeAction(value);
    setOpenMenuPeriodVolume(false);
    setVolumeTime(value);
  };

  useEffect(() => {
    dispatch(getVolume(tickerSymbol, volumeTime));
  }, [volumeChangeAction]);

  useEffect(() => {
    dispatch(getTokenPrice(tickerSymbol, priceTime));
  }, [priceChangeAction]);

  // useEffect(() => {
  //   dispatch(getBasicTokenDetails(location.pathname.split('/')[2]));
  // }, []);

  const _renderTokenNameH4 = (additionalText) => {
    const { loading, error, data } = basicTokenDetails;
    if (loading && _.isEmpty(error)) {
      return <Skeleton animation="wave" height={20} width="100px" />;
    } else if (_.isEmpty(error) && error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return t(`${data.name} ${additionalText}`);
  };

  const _renderTokenNameH6 = (additionalText) => {
    const { loading, error, data } = basicTokenDetails;
    if (loading && _.isEmpty(error)) {
      return <Skeleton animation="wave" height={20} width="100px" />;
    } else if (_.isEmpty(error) && error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <TypographyH6>{GLOBALS.API_ERROR_MSG}</TypographyH6>
        </Box>
      );
    }
    return t(`${data.name} ${additionalText}`);
  };

  const _renderClosePrice = () => {
    const { loading, error, data } = tokenPrice;
    if (loading && _.isEmpty(error)) {
      return <Skeleton animation="wave" height={20} width="100px" />;
    } else if (_.isEmpty(error) && error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.closePriceInUsd}
        isNumberAsString
        isShortFlag
        prefixData="$"
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
      />
    );
  };

  const _renderOpenPrice = () => {
    const { loading, error, data } = tokenPrice;
    if (loading && _.isEmpty(error)) {
      return <Skeleton animation="wave" height={20} width="100px" />;
    } else if (_.isEmpty(error) && error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.openPriceInUsd}
        isNumberAsString
        isShortFlag
        prefixData="$"
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
      />
    );
  };

  const _render24LowestPrice = () => {
    const { loading, error, data } = tokenPrice;
    if (loading && _.isEmpty(error)) {
      return <Skeleton animation="wave" height={20} width="100px" />;
    } else if (_.isEmpty(error) && error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.twentyFourHourLowValue}
        isNumberAsString
        isShortFlag
        prefixData="$"
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
      />
    );
  };

  const _render24HighestPrice = () => {
    const { loading, error, data } = tokenPrice;
    if (loading && _.isEmpty(error)) {
      return <Skeleton animation="wave" height={20} width="100px" />;
    } else if (_.isEmpty(error) && error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.twentyFourHourHighValue}
        isNumberAsString
        isShortFlag
        prefixData="$"
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
      />
    );
  };

  const _renderCurrentVolume = () => {
    const { loading, error, data } = volume;
    if (loading && _.isEmpty(error)) {
      return <Skeleton animation="wave" height={20} width="100px" />;
    } else if (_.isEmpty(error) && error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.currentVolumeInUsd}
        isNumberAsString
        isShortFlag
        prefixData="$"
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
      />
    );
  };

  const _renderVolumeInUsd = () => {
    const { loading, error, data } = basicTokenDetails;
    if (loading && _.isEmpty(error)) {
      return <Skeleton animation="wave" height={20} width="100px" />;
    } else if (_.isEmpty(error) && error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.total_volume_usd}
        isNumberAsString
        isShortFlag
        suffixData="%"
        style={{
          whiteSpace: 'noWrap'
        }}
      />
    );
  };

  const _renderVolumeMarketCap = () => {
    if (volume.loading && _.isEmpty(volume.error)) {
      return <Skeleton animation="wave" height={20} width="100px" />;
    } else if (_.isEmpty(volume.error) && volume.error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      // TODO: something tooltip hovering issue
      <CommonErrorTooltip
        data={(
          _.toNumber(volume.data.currentVolumeInUsd) /
          _.toNumber(marketCap.data.currentMarketCapInUsd)
        ).toString()}
        isNumberAsString
        isShortFlag
        prefixData="$"
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
      />
    );
  };

  const _renderMarketRank = () => {
    const { loading, error, data } = basicTokenDetails;
    if (loading && _.isEmpty(error)) {
      return <Skeleton animation="wave" height={20} width="100px" />;
    } else if (_.isEmpty(error) && error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.market_cap_rank}
        isText
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
      />
    );
  };

  const _renderCurrentMarketCap = () => {
    const { loading, error, data } = marketCap;
    if (loading && _.isEmpty(error)) {
      return <Skeleton animation="wave" height={20} width="100px" />;
    } else if (_.isEmpty(error) && error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.currentMarketCapInUsd}
        isNumberAsString
        isShortFlag
        prefixData="$"
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
      />
    );
  };

  const _renderCurrentFullyMarketCap = () => {
    const { loading, error, data } = fullyDilutedMarketCap;
    if (loading && _.isEmpty(error)) {
      return <Skeleton animation="wave" height={20} width="100px" />;
    } else if (_.isEmpty(error) && error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.valueInUsd}
        isNumberAsString
        isShortFlag
        prefixData="$"
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
      />
    );
  };

  const _renderTokenLowestPrice = () => {
    const { loading, error, data } = tokenPrice;
    if (loading && _.isEmpty(error)) {
      return <Skeleton animation="wave" height={20} width="100px" />;
    } else if (_.isEmpty(error) && error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.lowestPriceInUsd}
        isNumberAsString
        isShortFlag
        prefixData="$"
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
      />
    );
  };

  const _renderTokenHighestPrice = () => {
    const { loading, error, data } = tokenPrice;
    if (loading && _.isEmpty(error)) {
      return <Skeleton animation="wave" height={20} width="100px" />;
    } else if (_.isEmpty(error) && error === undefined) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <CommonErrorTooltip
        data={data.highestPriceInUsd}
        isNumberAsString
        isShortFlag
        prefixData="$"
        style={{
          variant: 'subtitle1',
          fontWeight: 'bold',
          whiteSpace: 'nowrap'
        }}
      />
    );
  };

  return (
    <Item>
      <Box>
        <TypographyH4 variant="h4" component="h4">
          {_renderTokenNameH4('Price Statistics')}
        </TypographyH4>
      </Box>
      <Box>
        <TypographyH6 variant="h6" component="h6">
          {_renderTokenNameH6('Token Price')}
        </TypographyH6>
        <FlexContainer>
          <StyledLeftContainer>
            <StyledTextLeft variant="subtitle1">
              {tokenPrice.loading ? (
                <Skeleton
                  animation="wave"
                  height={10}
                  width="200px"
                  style={{ marginBottom: 6 }}
                />
              ) : (
                _renderTokenNameH4('Price')
              )}
            </StyledTextLeft>
          </StyledLeftContainer>
          <StyledRightContainer>
            {tokenPrice.loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="200px"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <StyledTextRight>{_renderClosePrice()}</StyledTextRight>
            )}
          </StyledRightContainer>
        </FlexContainer>
        <Divider variant="middle" />
        <FlexContainer>
          <StyledLeftContainer>
            <StyledTextLeft variant="subtitle1">
              {t('Price Change')}
            </StyledTextLeft>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Button
                variant="text"
                color="secondary"
                size="small"
                ref={actionPriceRef}
                onClick={() => setOpenMenuPeriodPrice(true)}
                sx={{
                  mr: 1
                }}
                endIcon={<KeyboardArrowDownTwoToneIcon fontSize="small" />}
              >
                {t(priceChangeAction)}
              </Button>
              <Menu
                disableScrollLock
                anchorEl={actionPriceRef.current}
                onClose={() => setOpenMenuPeriodPrice(false)}
                open={openPeriodPrice}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                {change.map((_change) => (
                  <MenuItem
                    key={_change.value}
                    onClick={() => handlePriceChangeInStatistics(_change.value)}
                  >
                    {t(_change.text)}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </StyledLeftContainer>
          <StyledRightContainer>
            {tokenPrice.loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="200px"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <>
                <StyledTextRight>{_renderClosePrice()}</StyledTextRight>
                <StyledTextRight>
                  <Label
                    color={
                      Math.sign(
                        tokenPrice.data
                          .percentageDifferenceOfOpenAndClosePriceInUsd
                      ) === 1
                        ? 'success'
                        : 'error'
                    }
                  >
                    <CommonErrorTooltip
                      data={
                        tokenPrice.data
                          .percentageDifferenceOfOpenAndClosePriceInUsd
                      }
                      isNumberAsString
                      isShortFlag
                      suffixData="%"
                      style={{
                        whiteSpace: 'noWrap'
                      }}
                    />
                  </Label>
                </StyledTextRight>
              </>
            )}
          </StyledRightContainer>
        </FlexContainer>
        <Divider variant="middle" />
        <FlexContainer>
          <StyledLeftContainer>
            <StyledTextLeft variant="subtitle1">
              {t('24h Low / 24h High')}
            </StyledTextLeft>
          </StyledLeftContainer>
          <StyledRightContainer>
            {tokenPrice.loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="200px"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <>
                <StyledTextRight textAlign="right">
                  {_render24LowestPrice()}
                </StyledTextRight>
                <StyledTextRight textAlign="right">
                  {_render24HighestPrice()}
                </StyledTextRight>
              </>
            )}
          </StyledRightContainer>
        </FlexContainer>
        <Divider variant="middle" />
        <FlexContainer>
          <StyledLeftContainer>
            <StyledTextLeft variant="subtitle1">
              {t('Trading Volume')}
            </StyledTextLeft>
          </StyledLeftContainer>
          <StyledRightContainer>
            {volume.loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="200px"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <StyledTextRight>{_renderCurrentVolume()}</StyledTextRight>
            )}
          </StyledRightContainer>
        </FlexContainer>
        <Divider variant="middle" />
        <FlexContainer>
          <StyledLeftContainer>
            <StyledTextLeft variant="subtitle1">
              {t('Volume / Market Cap')}
            </StyledTextLeft>
          </StyledLeftContainer>
          <StyledRightContainer>
            {volume.loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="200px"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <StyledTextRight>{_renderVolumeMarketCap()}</StyledTextRight>
            )}
          </StyledRightContainer>
        </FlexContainer>
        <Divider variant="middle" />
        <FlexContainer>
          <StyledLeftContainer>
            <StyledTextLeft variant="subtitle1">
              {t('Market Rank')}
            </StyledTextLeft>
          </StyledLeftContainer>
          <StyledRightContainer>
            {basicTokenDetails.loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="200px"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <StyledTextRight>{_renderMarketRank()}</StyledTextRight>
            )}
          </StyledRightContainer>
        </FlexContainer>
        <Divider variant="middle" />
      </Box>
      <Box>
        <TypographyH6 variant="h6" component="h6">
          {_renderTokenNameH6('Market Cap')}
        </TypographyH6>
        <FlexContainer>
          <StyledLeftContainer>
            <StyledTextLeft variant="subtitle1">
              {t('Market Cap')}
            </StyledTextLeft>
          </StyledLeftContainer>
          <StyledRightContainer>
            {marketCap.loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="200px"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <>
                <StyledTextRight>{_renderCurrentMarketCap()}</StyledTextRight>
                <Label
                  color={
                    Math.sign(
                      marketCap.data.percentageDifferenceOfMarketCapInUsd
                    ) === 1
                      ? 'success'
                      : 'error'
                  }
                >
                  <CommonErrorTooltip
                    data={marketCap.data.percentageDifferenceOfMarketCapInUsd}
                    isNumberAsString
                    isShortFlag
                    suffixData="%"
                    style={{
                      whiteSpace: 'noWrap'
                    }}
                  />
                </Label>
              </>
            )}
          </StyledRightContainer>
        </FlexContainer>
        <Divider variant="middle" />
        <FlexContainer>
          <StyledTextLeft variant="subtitle1">
            {t('Fully Diluted Market Cap')}
          </StyledTextLeft>
          <StyledRightContainer>
            {marketCap.loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="200px"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <>
                <StyledTextRight>
                  {_renderCurrentFullyMarketCap()}
                </StyledTextRight>
                <Label
                  color={
                    Math.sign(
                      tokenPrice.data
                        .percentageDifferenceOfOpenAndClosePriceInUsd
                    ) === 1
                      ? 'success'
                      : 'error'
                  }
                >
                  <CommonErrorTooltip
                    data={
                      tokenPrice.data
                        .percentageDifferenceOfOpenAndClosePriceInUsd
                    }
                    isNumberAsString
                    isShortFlag
                    suffixData="%"
                    style={{
                      whiteSpace: 'noWrap'
                    }}
                  />
                </Label>
              </>
            )}
          </StyledRightContainer>
        </FlexContainer>
        <Divider variant="middle" />
      </Box>
      <Box>
        <TypographyH6 variant="h6" component="h6">
          {_renderTokenNameH6('Price Yesterday')}
        </TypographyH6>
        <FlexContainer>
          <StyledLeftContainer>
            <StyledTextLeft variant="subtitle1">
              {t("Yesterday's Low / High")}
            </StyledTextLeft>
          </StyledLeftContainer>
          <StyledRightContainer>
            {tokenPrice.loading ? (
              <>
                <Skeleton
                  animation="wave"
                  height={10}
                  width="200px"
                  style={{ marginBottom: 6 }}
                />
              </>
            ) : (
              <>
                <StyledTextRight textAlign="right">
                  {_renderTokenLowestPrice()}
                </StyledTextRight>
                <StyledTextRight textAlign="right">
                  {_renderTokenHighestPrice()}
                </StyledTextRight>
              </>
            )}
          </StyledRightContainer>
        </FlexContainer>
        <Divider variant="middle" />
        <FlexContainer>
          <StyledLeftContainer>
            <StyledTextLeft variant="subtitle1">
              {t("Yesterday's Open / Close")}
            </StyledTextLeft>
          </StyledLeftContainer>
          <StyledRightContainer>
            {tokenPrice.loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="200px"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <>
                <StyledTextRight textAlign="right">
                  {_renderOpenPrice()}
                </StyledTextRight>
                <StyledTextRight textAlign="right">
                  {_renderClosePrice()}
                </StyledTextRight>
              </>
            )}
          </StyledRightContainer>
        </FlexContainer>
        <Divider variant="middle" />
        <FlexContainer>
          <StyledLeftContainer>
            <StyledTextLeft variant="subtitle1">
              {t("Yesterday's Change")}
            </StyledTextLeft>
          </StyledLeftContainer>
          <StyledRightContainer>
            {tokenPrice.loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="200px"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <>
                <Label
                  color={
                    Math.sign(
                      tokenPrice.data
                        .percentageDifferenceOfOpenAndClosePriceInUsd
                    ) === 1
                      ? 'success'
                      : 'error'
                  }
                >
                  <CommonErrorTooltip
                    data={
                      tokenPrice.data
                        .percentageDifferenceOfOpenAndClosePriceInUsd
                    }
                    isNumberAsString
                    isShortFlag
                    suffixData="%"
                    style={{
                      whiteSpace: 'noWrap'
                    }}
                  />
                </Label>
              </>
            )}
          </StyledRightContainer>
        </FlexContainer>
        <Divider variant="middle" />
        <FlexContainer>
          <StyledLeftContainer variant="subtitle1">
            <StyledTextLeft>{t("Yesterday's Volume")}</StyledTextLeft>
          </StyledLeftContainer>
          <StyledRightContainer>
            {volume.loading ? (
              <>
                <Skeleton
                  animation="wave"
                  height={10}
                  width="200px"
                  style={{ marginBottom: 6 }}
                />
              </>
            ) : (
              <StyledTextRight>{_renderCurrentVolume()}</StyledTextRight>
            )}
          </StyledRightContainer>
        </FlexContainer>
      </Box>
    </Item>
  );
};
