import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { TopTxnBtExAndInChart } from './TopTxnBtwExAndInChart';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from '../../../../../store';
import { getTopTransferBtwExchangeAndIndividualWallet } from '../../../../../slices/owner';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  Menu,
  Button,
  MenuItem
} from '@mui/material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { GLOBALS } from '../../../../../globals/Globals';
import { TopTxnBtwExAndInTable } from './TopTxnBtwExAndInTable';

export const TopTxnBtwExAndIn = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const tickerSymbol = location.pathname.split('/')[2];

  const periods = [
    {
      value: '1D',
      text: t('1D')
    },
    {
      value: '1W',
      text: t('1W')
    },
    {
      value: '2W',
      text: t('2W')
    },
    {
      value: '3W',
      text: t('3W')
    },
    {
      value: '1M',
      text: t('1M')
    }
  ];

  const [changeAction, setChangeAction] = useState(periods[1].text);
  const actionRef = useRef(null);
  const [openTopTxnBtwExAndInChange, setOpenTopTxnBtwExAndInChange] =
    useState(false);
  const [topTxnBtwExAndInTime, setTopTxnBtwExAndInTime] = useState('1W');

  const handleTopTxnBtwExAndInChange = (value) => {
    setChangeAction(value);
    setOpenTopTxnBtwExAndInChange(false);
    if (value === '1D') {
      setTopTxnBtwExAndInTime(value);
    }
    if (value === '1W') {
      setTopTxnBtwExAndInTime(value);
    }
    if (value === '2W') {
      setTopTxnBtwExAndInTime(value);
    }
    if (value === '3W') {
      setTopTxnBtwExAndInTime(value);
    }
    if (value === '1M') {
      setTopTxnBtwExAndInTime(value);
    }
  };

  useEffect(() => {
    dispatch(
      getTopTransferBtwExchangeAndIndividualWallet(
        tickerSymbol,
        topTxnBtwExAndInTime
      )
    );
  }, [changeAction]);

  const { topTransferBtwExchangeAndIndividualWallet } = useSelector(
    (state) => state.owner
  );
  const { loading, error, data } = topTransferBtwExchangeAndIndividualWallet;

  const _render = () => {
    if (!loading && _.isEmpty(error) && _.isEmpty(data)) {
      return (
        <Typography
          sx={{
            py: 10
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {t("We couldn't find any data")}
        </Typography>
      );
    } else if (!loading && !_.isEmpty(error)) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">{GLOBALS.ERROR_MSG}</Typography>
        </Box>
      );
    }
    // render table
    return <TopTxnBtwExAndInTable />;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sm={6}>
          <Card sx={{ minWidth: '140px', minHeight: '500px' }}>
            <Box px={2}>
              <Box px={2}>
                <Grid container display="flex" justifyContent="space-between">
                  <Grid item xs={12}>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Box
                        sx={{ display: 'flex', flexDirection: 'column', py: 2 }}
                      >
                        <Typography variant="h4" fontWeight="bold">
                          {t('Top Transfers')}
                        </Typography>
                        <Typography variant="subtitle">
                          ( {t('Between Exchange and Individual Wallet')} )
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'nowrap',
                          alignItems: 'center'
                        }}
                      >
                        <Box>
                          <Button
                            color="secondary"
                            ref={actionRef}
                            onClick={() => setOpenTopTxnBtwExAndInChange(true)}
                            endIcon={<ExpandMoreTwoToneIcon />}
                            size="small"
                          >
                            {changeAction}
                          </Button>
                        </Box>
                        <Menu
                          disableScrollLock
                          anchorEl={actionRef.current}
                          onClose={() => setOpenTopTxnBtwExAndInChange(false)}
                          open={openTopTxnBtwExAndInChange}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        >
                          {periods.map((_location) => (
                            <MenuItem
                              key={_location.value}
                              onClick={() =>
                                handleTopTxnBtwExAndInChange(_location.value)
                              }
                            >
                              {_location.text}
                            </MenuItem>
                          ))}
                        </Menu>
                        {/* <Tooltip arrow title="Filter"> */}
                        {/*  <IconButtonWrapper color="primary"> */}
                        {/*    <FilterAltTwoToneIcon fontSize="small" /> */}
                        {/*  </IconButtonWrapper> */}
                        {/* </Tooltip> */}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Divider />
            {/* Render conditionally and handle errors */}
            {_render()}
          </Card>
        </Grid>
        {/* Top Txn btw and-in chart */}
        <Grid item xs={12} md={8} sm={6}>
          <TopTxnBtExAndInChart />
        </Grid>
      </Grid>
    </Box>
  );
};
