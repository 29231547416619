import { combineReducers } from '@reduxjs/toolkit';
import { reducer as topTokenReducer } from '../slices/topTokens';
import { reducer as newsReducer } from '../slices/news';
import { reducer as cryptoReducer } from '../slices/crypto';
import { reducer as insidersReducer } from '../slices/insiders';
import { reducer as onwerReducer } from '../slices/owner';
// import homeReducer from '../../../store/reducers/homeReducer';

const rootReducer = combineReducers({
  topToken: topTokenReducer,
  crypto: cryptoReducer,
  owner: onwerReducer,
  insiders: insidersReducer,
  news: newsReducer
  // home: homeReducer,
});

export default rootReducer;
