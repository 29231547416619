import { createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';

const initialState = {
  tokenDistribution: {
    loading: true,
    error: '',
    data: []
  },
  uniqueAddressForToken: {
    loading: true,
    error: '',
    data: {}
  },
  walletAddressRange: {
    loading: true,
    error: '',
    data: {}
  },
  walletAddress: {
    loading: true,
    error: '',
    data: []
  },
  walletAddresses: {
    loading: true,
    error: '',
    data: []
  },
  topHolders: {
    loading: true,
    error: '',
    data: {}
  },
  costInformation: {
    loading: true,
    error: '',
    data: []
  },
  richList: {
    loading: true,
    error: '',
    data: []
  }
};

const slice = createSlice({
  name: 'insiders',
  initialState,
  reducers: {
    // Set Token Distribution
    setTokenDistribution(state, action) {
      state.tokenDistribution.data = action.payload;
    },
    setTokenDistributionError(state, action) {
      state.tokenDistribution.error = action.payload;
    },
    setTokenDistributionLoading(state, action) {
      state.tokenDistribution.loading = action.payload;
    },

    // Set Unique Address for Token
    setUniqueAddressForToken(state, action) {
      state.uniqueAddressForToken.data = action.payload;
    },
    setUniqueAddressForTokenError(state, action) {
      state.uniqueAddressForToken.error = action.payload;
    },
    setUniqueAddressForTokenLoading(state, action) {
      state.uniqueAddressForToken.loading = action.payload;
    },

    // Set Wallet Address
    setWalletAddress(state, action) {
      state.walletAddress.data = action.payload;
    },
    setWalletAddressError(state, action) {
      state.walletAddress.error = action.payload;
    },
    setWalletAddressLoading(state, action) {
      state.walletAddress.loading = action.payload;
    },

    // Set Cost Information
    setCostInformation(state, action) {
      state.costInformation.data = action.payload;
    },
    setCostInformationError(state, action) {
      state.costInformation.error = action.payload;
    },
    setCostInformationLoading(state, action) {
      state.costInformation.loading = action.payload;
    },

    // Top Holders Graph
    setTopHoldersGraph(state, action) {
      state.topHolders.data = action.payload;
    },
    setTopHoldersGraphError(state, action) {
      state.topHolders.error = action.payload;
    },
    setTopHoldersGraphLoading(state, action) {
      state.topHolders.loading = action.payload;
    },

    // Set Rich List
    setRichList(state, action) {
      state.richList.data = action.payload;
    },
    setRichListError(state, action) {
      state.richList.error = action.payload;
    },
    setRichListLoading(state, action) {
      state.richList.loading = action.payload;
    },

    // Set Wallet Addresses
    setWalletAddresses(state, action) {
      state.walletAddresses.data = action.payload;
    },
    setWalletAddressesError(state, action) {
      state.walletAddresses.error = action.payload;
    },
    setWalletAddressesLoading(state, action) {
      state.walletAddresses.loading = action.payload;
    },

    // Set Wallet Address Range
    setWalletAddressRange(state, action) {
      state.walletAddressRange.data = action.payload;
    },
    setWalletAddressRangeLoading(state, action) {
      state.walletAddressRange.loading = action.payload;
    },
    setWalletAddressRangeError(state, action) {
      state.walletAddressRange.error = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getTokenDistribution = (ticker_symbol) => async (dispatch) => {
  dispatch(slice.actions.setTokenDistributionLoading(true));
  try {
    const response = await axios.get(
      `/insiders/${ticker_symbol}/token-distribution`
    );
    const reversedResponse = response.data.reverse();
    dispatch(slice.actions.setTokenDistribution(reversedResponse));
  } catch (err) {
    dispatch(slice.actions.setTokenDistributionError(err));
  }
  dispatch(slice.actions.setTokenDistributionLoading(false));
};

export const getUniqueAddressForToken =
  (ticker_symbol, time) => async (dispatch) => {
    dispatch(slice.actions.setUniqueAddressForTokenLoading(true));
    try {
      const response = await axios.get(
        `/insiders/${ticker_symbol}/unique-address/${time}/graph`
      );
      dispatch(slice.actions.setUniqueAddressForToken(response.data));
    } catch (err) {
      dispatch(slice.actions.setUniqueAddressForTokenError(err));
    }
    dispatch(slice.actions.setUniqueAddressForTokenLoading(false));
  };

// TODO: Add ticker symbol at the end of the url
export const getWalletAddress = (address) => async (dispatch) => {
  dispatch(slice.actions.setWalletAddressLoading(true));
  try {
    const response = await axios.get(
      `/insiders/${address}/wallet/token-distribution`
    );
    dispatch(slice.actions.setWalletAddress(response.data));
  } catch (err) {
    dispatch(slice.actions.setWalletAddressError(err));
  }
  dispatch(slice.actions.setWalletAddressLoading(false));
};

// TODO: Not usable yet
export const getCostInformation =
  (ticker_symbol, address, interval) => async (dispatch) => {
    dispatch(slice.actions.setCostInformationLoading(true));
    try {
      const response = await axios.get(
        `/insiders/${address}/wallet/token-distribution`
      );
      dispatch(slice.actions.setCostInformation(response.data));
    } catch (err) {
      dispatch(slice.actions.setCostInformationError(err));
    }
    dispatch(slice.actions.setCostInformationLoading(false));
  };

export const getTopHolders =
  (ticker_symbol, time, from, to) => async (dispatch) => {
    dispatch(slice.actions.setTopHoldersGraphLoading(true));
    try {
      const response = await axios.get(
        `insiders/${ticker_symbol}/top-holders/balance-change/${time}/${from}/${to}/graph`
      );
      dispatch(slice.actions.setTopHoldersGraph(response.data));
    } catch (err) {
      dispatch(slice.actions.setTopHoldersGraphError(err));
    }
    dispatch(slice.actions.setTopHoldersGraphLoading(false));
  };

export const getRichList = (ticker_symbol) => async (dispatch) => {
  dispatch(slice.actions.setRichListLoading(true));
  try {
    const response = await axios.get(`/insiders/${ticker_symbol}/richer-list`);
    dispatch(slice.actions.setRichList(response.data));
  } catch (err) {
    dispatch(slice.actions.setRichListError(err));
  }
  dispatch(slice.actions.setRichListLoading(false));
};

export const getWalletAddresses =
  (ticker_symbol, fromRange, toRange) => async (dispatch) => {
    dispatch(slice.actions.setWalletAddressesLoading(true));
    try {
      const response = await axios.get(
        `/insiders/${ticker_symbol}/top-wallets/${fromRange}/${toRange}`
      );
      dispatch(slice.actions.setWalletAddresses(response.data));
    } catch (err) {
      dispatch(slice.actions.setWalletAddressesError(err));
    }
    dispatch(slice.actions.setWalletAddressesLoading(false));
  };

export const getWalletAddressRange = (balanceRange) => async (dispatch) => {
  dispatch(slice.actions.setWalletAddressRangeLoading(true));
  try {
    dispatch(slice.actions.setWalletAddressRange(balanceRange));
  } catch (err) {
    dispatch(slice.actions.setWalletAddressRangeError({}));
  }
  dispatch(slice.actions.setWalletAddressRangeLoading(false));
};
