import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import _ from 'lodash';
import {
  Box,
  Card,
  Skeleton,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Label } from '../../../../../components/Label';
import { ReactComponent as FullyDilutedMarketCapIcon } from '../../../../../assets/DilutedMarketCap.svg';
import { useDispatch, useSelector } from '../../../../../store';
import {
  getTokenPrice,
  getFullyDilutedMarketCap
} from '../../../../../slices/crypto';
import { GLOBALS } from '../../../../../globals/Globals';
import { CommonErrorTooltip } from '../../../../../components/CommonErrorTooltip';
import { nFormatter } from '../../../../../utils/utils';

export const FullyDilutedMarketCap = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const tokenSymbol = location.pathname.split('/')[2];
  const { fullyDilutedMarketCap, tokenPrice } = useSelector(
    (state) => state.crypto
  );

  const { loading, error, data } = fullyDilutedMarketCap;

  const marketCapChanges = [
    {
      value: '1D',
      text: t('1D')
    },
    {
      value: '1W',
      text: t('1W')
    },
    {
      value: '1M',
      text: t('1M')
    }
  ];

  const [dillutedMarketCapChange, setDillutedMarketCapChange] = useState(
    marketCapChanges[0].text
  );
  const actionRef = useRef(null);
  const [openDilutedMarketCapChange, setOpenDilutedMarketCapChange] =
    useState(false);
  const [dilutedMarketCapTime, setDilutedMarketCapTime] = useState('1D');
  const [dillutedMarketCap, setdillutedMarketCap] = useState(0);

  useEffect(() => {
    dispatch(getTokenPrice(tokenSymbol, dilutedMarketCapTime, false));
  }, [dilutedMarketCapTime]);

  useEffect(() => {
    dispatch(getFullyDilutedMarketCap(tokenSymbol));
  }, []);

  const handleMarketCapChange = (value) => {
    setDillutedMarketCapChange(value);
    setOpenDilutedMarketCapChange(false);
    // if (value === '24H') {
    //   setDilutedMarketCapTime(value);
    // }
    // if (value === '1M') {
    //   setDilutedMarketCapTime(value);
    // }
    // if (value === '1W') {
    //   setDilutedMarketCapTime(value);
    // }
  };

  return (
    <Card
      sx={{
        px: 3,
        pb: 3,
        pt: 3,
        height: '100%'
      }}
    >
      <Box display="flex" alignItems="center">
        <FullyDilutedMarketCapIcon />
        <Typography
          sx={{
            ml: 1,
            fontSize: `${theme.typography.pxToRem(15)}`,
            fontWeight: 'bold'
          }}
          variant="subtitle2"
          component="div"
        >
          {t('Fully Diluted Market Cap')}
        </Typography>
        <Box sx={{ p: 1, display: 'flex' }}>
          <Tooltip title={GLOBALS.CRYPTO_DILUTED_MARKET_CAP} arrow>
            <InfoOutlinedIcon fontSize="small" sx={{ cursor: 'pointer' }} />
          </Tooltip>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          ml: -2,
          pt: 2,
          pb: 1.5,
          justifyContent: 'center'
        }}
      >
        {loading ? (
          <>
            <Skeleton
              animation="wave"
              height={20}
              width="200px"
              style={{ marginBottom: 6 }}
            />
          </>
        ) : (
          <Tooltip placement="top" arrow title={data.valueInUsd}>
            <Typography
              variant="h4"
              sx={{
                pl: 1,
                whiteSpace: 'noWrap',
                fontSize: 18
              }}
            >
              {`$ ${nFormatter(data.valueInUsd)}`}
            </Typography>
          </Tooltip>
        )}
      </Box>
      {tokenPrice.loading ? (
        <>
          <Skeleton
            animation="wave"
            height={20}
            width="200px"
            style={{ marginBottom: 6 }}
          />
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Label
              color={
                Math.sign(
                  tokenPrice.data.percentageDifferenceOfOpenAndClosePriceInUsd
                ) === 1
                  ? 'success'
                  : 'error'
              }
            >
              <CommonErrorTooltip
                data={
                  tokenPrice.data.percentageDifferenceOfOpenAndClosePriceInUsd
                }
                isNumberAsString
                suffixData="%"
                style={{ whiteSpace: 'noWrap' }}
              />
            </Label>
            {/*  <Box> */}
            {/*    <Button */}
            {/*      color="secondary" */}
            {/*      ref={actionRef} */}
            {/*      onClick={() => setOpenDilutedMarketCapChange(true)} */}
            {/*      endIcon={<ExpandMoreTwoToneIcon />} */}
            {/*      size={'small'} */}
            {/*    > */}
            {/*      {dillutedMarketCapChange} */}
            {/*    </Button> */}
            {/*  </Box> */}
            {/*  <Menu */}
            {/*    disableScrollLock */}
            {/*    anchorEl={actionRef.current} */}
            {/*    onClose={() => setOpenDilutedMarketCapChange(false)} */}
            {/*    open={openDilutedMarketCapChange} */}
            {/*    anchorOrigin={{ */}
            {/*      vertical: 'bottom', */}
            {/*      horizontal: 'right' */}
            {/*    }} */}
            {/*    transformOrigin={{ */}
            {/*      vertical: 'top', */}
            {/*      horizontal: 'right' */}
            {/*    }} */}
            {/*  > */}
            {/*    {marketCapChanges.map((_location) => ( */}
            {/*      <MenuItem */}
            {/*        key={_location.value} */}
            {/*        onClick={() => handleMarketCapChange(_location.value)} */}
            {/*      > */}
            {/*        {_location.text} */}
            {/*      </MenuItem> */}
            {/*    ))} */}
            {/*  </Menu> */}
          </Box>
        </Box>
      )}
    </Card>
  );
};
