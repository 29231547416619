import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import {
  Box,
  Card,
  Skeleton,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Label } from '../../../../../components/Label';
import { ReactComponent as MarketCapIcon } from '../../../../../assets/MarketCap.svg';
import { useDispatch, useSelector } from '../../../../../store';
import { getMarketCap } from '../../../../../slices/crypto';
import { GLOBALS } from '../../../../../globals/Globals';
import { CommonErrorTooltip } from '../../../../../components/CommonErrorTooltip';
import { nFormatter } from '../../../../../utils/utils';

export const MarketCap = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const tickerSymbol = location.pathname.split('/')[2];
  const { marketCap } = useSelector((state) => state.crypto);

  const marketCapChanges = [
    {
      value: '1D',
      text: t('1D')
    },
    {
      value: '1W',
      text: t('1W')
    },
    {
      value: '2W',
      text: t('2W')
    },
    {
      value: '3W',
      text: t('3W')
    },
    {
      value: '1M',
      text: t('1M')
    }
  ];

  const [marketCapChange, setMarketCapChange] = useState(
    marketCapChanges[1].text
  );
  const actionRef = useRef(null);
  const [openMarketCapChange, setOpenMarketCapChange] = useState(false);
  const [marketCapTime, setMarketCapTime] = useState('1D');

  useEffect(() => {
    dispatch(getMarketCap(tickerSymbol, marketCapTime));
  }, [marketCapTime]);

  const handleMarketCapChange = (value) => {
    setMarketCapChange(value);
    setOpenMarketCapChange(false);
    if (value === '1D') {
      setMarketCapTime(value);
    }
    if (value === '1M') {
      setMarketCapTime(value);
    }
    if (value === '1W') {
      setMarketCapTime(value);
    }
    if (value === '2W') {
      setMarketCapTime(value);
    }
    if (value === '3W') {
      setMarketCapTime(value);
    }
  };

  return (
    <Card
      sx={{
        px: 3,
        pb: 3,
        pt: 3,
        height: '100%'
      }}
    >
      <Box display="flex" alignItems="center">
        <MarketCapIcon />
        <Typography
          sx={{
            ml: 1,
            fontSize: `${theme.typography.pxToRem(15)}`,
            fontWeight: 'bold'
          }}
          variant="subtitle2"
          component="div"
        >
          {t('Market Cap')}
        </Typography>
        <Box sx={{ p: 1, display: 'flex' }}>
          <Tooltip title={GLOBALS.CRYPTO_MARKET_CAP} arrow>
            <InfoOutlinedIcon fontSize="small" sx={{ cursor: 'pointer' }} />
          </Tooltip>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          ml: -2,
          pt: 2,
          pb: 1.5,
          justifyContent: 'center'
        }}
      >
        {marketCap.loading ? (
          <>
            <Skeleton
              animation="wave"
              height={20}
              width="200px"
              style={{ marginBottom: 6 }}
            />
          </>
        ) : (
          <Tooltip
            placement="top"
            arrow
            title={marketCap.data.currentMarketCapInUsd}
          >
            <Typography
              variant="h4"
              sx={{
                pl: 1,
                whiteSpace: 'noWrap',
                fontSize: 18
              }}
            >
              {`$ ${nFormatter(marketCap.data.currentMarketCapInUsd)}`}
            </Typography>
          </Tooltip>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          mb={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Label
            color={
              Math.sign(marketCap.data.percentageDifferenceOfMarketCapInUsd) ===
              1
                ? 'success'
                : 'error'
            }
          >
            <CommonErrorTooltip
              data={marketCap.data.percentageDifferenceOfMarketCapInUsd}
              isNumberAsString
              suffixData="%"
              style={{ whiteSpace: 'noWrap' }}
            />
          </Label>
          {/* <Box>
            <Button
              color="secondary"
              ref={actionRef}
              onClick={() => setOpenMarketCapChange(true)}
              endIcon={<ExpandMoreTwoToneIcon />}
              size={'small'}
            >
              {marketCapChange}
            </Button>
          </Box>
          <Menu
            disableScrollLock
            anchorEl={actionRef.current}
            onClose={() => setOpenMarketCapChange(false)}
            open={openMarketCapChange}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {marketCapChanges.map((_location) => (
              <MenuItem
                key={_location.value}
                onClick={() => handleMarketCapChange(_location.value)}
              >
                {_location.text}
              </MenuItem>
            ))}
          </Menu>  */}
        </Box>
      </Box>
    </Card>
  );
};
