import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';

const Wealth = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="xl" style={{ height: '480px' }}>
      {t('Coming Soon')}
    </Container>
  );
};

export default Wealth;
