import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Box,
  Typography,
  useTheme,
  Card,
  Skeleton,
  Tooltip,
  Popover,
  CircularProgress
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ReactComponent as CirculatingSupplyIcon } from '../../../../../assets/CirculatingSupply.svg';
import { useSelector } from '../../../../../store';
import { GLOBALS } from '../../../../../globals/Globals';
import { CommonErrorTooltip } from '../../../../../components/CommonErrorTooltip';
import { CustomLinearProgress } from '../../../../../components/CustomLinearProgress';
import { customNumeral, nFormatter } from '../../../../../utils/utils';

export const CirculatingSupply = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { basicTokenDetails } = useSelector((state) => state.crypto);
  const { loading, error, data } = basicTokenDetails;

  // Calculating
  let percentage = 0;
  percentage =
    (Number(data.circulating_supply) / Number(data.total_supply)) * 100;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Card
      sx={{
        px: 3,
        pb: 3,
        pt: 3,
        height: '100%'
      }}
    >
      <Box display="flex" alignItems="center">
        <CirculatingSupplyIcon />
        <Typography
          sx={{
            ml: 1,
            fontSize: `${theme.typography.pxToRem(15)}`,
            fontWeight: 'bold'
          }}
          variant="subtitle2"
          component="div"
        >
          {t('Circulating Supply')}
        </Typography>
        <Box sx={{ p: 1, display: 'flex' }}>
          <Tooltip title={GLOBALS.CRYPTO_CICULATING_SUPPLY} arrow>
            <InfoOutlinedIcon fontSize="small" sx={{ cursor: 'pointer' }} />
          </Tooltip>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          ml: -2,
          pt: 2,
          pb: 1.5,
          justifyContent: 'center'
        }}
      >
        {loading ? (
          <>
            <Skeleton
              animation="wave"
              height={20}
              width="200px"
              style={{ marginBottom: 6 }}
            />
          </>
        ) : (
          <>
            <Tooltip placement="top" arrow title={data.circulating_supply}>
              <Typography
                variant="h4"
                sx={{
                  pl: 1,
                  whiteSpace: 'noWrap',
                  fontSize: 18
                }}
              >
                {`${nFormatter(data.circulating_supply)}`}
              </Typography>
            </Tooltip>
            <Typography
              style={{
                pl: 1,
                fontSize: 18
              }}
            >
              &nbsp;
              {data.ticker_symbol}
            </Typography>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          mt: 3,
          cursor: 'pointer'
        }}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <CustomLinearProgress value={percentage} />
        <span>{`${customNumeral({ value: percentage })} %`}</span>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none'
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box
            sx={{
              gap: 1,
              mt: 1
            }}
          >
            {loading ? (
              <Box sx={{ textAlign: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: 16,
                      fontWeight: 'bold'
                    }}
                    variant="subtitle1"
                  >
                    {t('Percentage')}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: 10,
                      p: 1
                    }}
                    variant="subtitle1"
                  >
                    <span
                      style={{
                        fontWeight: 900,
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.colors.alpha.trueWhite[100]
                            : theme.colors.alpha.black[100]
                      }}
                    >
                      <CommonErrorTooltip
                        data={percentage}
                        isNumberAsString
                        suffixData="%"
                        style={{ whiteSpace: 'noWrap' }}
                      />
                    </span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: 16,
                      fontWeight: 'bold'
                    }}
                    variant="subtitle1"
                  >
                    {t('Circulating Supply :')}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: 10,
                      p: 1
                    }}
                    variant="subtitle1"
                  >
                    <span
                      style={{
                        fontWeight: 900,
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.colors.alpha.trueWhite[100]
                            : theme.colors.alpha.black[100]
                      }}
                    >
                      <CommonErrorTooltip
                        data={data.circulating_supply}
                        isNumberAsString
                        style={{ whiteSpace: 'noWrap' }}
                      />
                    </span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: 16,
                      fontWeight: 'bold'
                    }}
                    variant="subtitle1"
                  >
                    {t('Total Supply :')}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: 10,
                      p: 1
                    }}
                    variant="subtitle1"
                  >
                    <span
                      style={{
                        fontWeight: 900,
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.colors.alpha.trueWhite[100]
                            : theme.colors.alpha.black[100]
                      }}
                    >
                      <CommonErrorTooltip
                        data={data.total_supply}
                        isNumberAsString
                        style={{
                          whiteSpace: 'noWrap'
                        }}
                      />
                    </span>
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Popover>
      </Box>
    </Card>
  );
};
