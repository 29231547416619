import React, { useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  useTheme,
  Box,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { EnhancedTableHead } from '../../../../../components/Table';
import { useDispatch, useSelector } from '../../../../../store';
import { getRichList } from '../../../../../slices/insiders';
import { useLocation } from 'react-router';
import { SkeletonLoad } from '../../../../../components/SkeletonLoad';
import { GLOBALS } from '../../../../../globals/Globals';
import { CommonErrorTooltip } from '../../../../../components/CommonErrorTooltip';

const headCells = [
  {
    id: 'oneDollar',
    numeric: true,
    label: '$1',
    infoTooltip: true,
    infoMsg: GLOBALS.INSIDERS_RICH_LIST_1,
    showArrow: true,
    align: 'center',
    disablePadding: false,
    isSticky: false
  },
  {
    id: 'hundredHDollar',
    numeric: true,
    label: '$100',
    infoTooltip: true,
    infoMsg: GLOBALS.INSIDERS_RICH_LIST_100,
    showArrow: true,
    align: 'center',
    disablePadding: true,
    isSticky: false
  },
  {
    id: 'oneThousandDollar',
    numeric: true,
    label: '$10,000',
    infoTooltip: true,
    infoMsg: GLOBALS.INSIDERS_RICH_LIST_10000,
    showArrow: true,
    align: 'center',
    disablePadding: true,
    isSticky: false
  },
  {
    id: 'oneMillionTDollar',
    numeric: true,
    label: '$1,000,000',
    infoTooltip: true,
    infoMsg: GLOBALS.INSIDERS_RICH_LIST_1000000,
    showArrow: true,
    align: 'center',
    disablePadding: true,
    isSticky: false
  },
  {
    id: 'tenMillionLDollar',
    numeric: true,
    label: '$100,000,000',
    infoTooltip: true,
    infoMsg: GLOBALS.INSIDERS_RICH_LIST_100000000,
    showArrow: true,
    align: 'center',
    disablePadding: true,
    isSticky: false
  }
];

export const RichAddressesCount = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const tickerSymbol = location.pathname.split('/')[2];

  const dispatch = useDispatch();
  const { richList } = useSelector((state) => state.insiders);
  const { loading, error, data } = richList;

  useEffect(() => {
    dispatch(getRichList(tickerSymbol));
  }, []);

  const RenderTable = () => {
    return (
      <>
        {/* TODO: fixed height */}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
            stickyHeader
          >
            <EnhancedTableHead rowCount={data.length} headCells={headCells} />
            <TableBody>
              {loading && error === '' && _.isEmpty(data) ? (
                <SkeletonLoad rows={1} cols={5} />
              ) : (
                <TableRow
                  key={data[0]?.usd}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 }
                  }}
                >
                  <TableCell align="center">
                    <CommonErrorTooltip
                      data={data[0]?.coins}
                      isNumberAsString
                      style={{ whiteSpace: 'nowrap' }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <CommonErrorTooltip
                      data={data[1]?.coins}
                      isNumberAsString
                      style={{ whiteSpace: 'nowrap' }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <CommonErrorTooltip
                      data={data[2]?.coins}
                      isNumberAsString
                      style={{ whiteSpace: 'nowrap' }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <CommonErrorTooltip
                      data={data[3]?.coins}
                      isNumberAsString
                      style={{ whiteSpace: 'nowrap' }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <CommonErrorTooltip
                      data={data[4]?.coins}
                      isNumberAsString
                      style={{ whiteSpace: 'nowrap' }}
                    />
                  </TableCell>
                  {/* <TableCell align="center">{data[5].coins}</TableCell>
                  <TableCell align="center">{data[6].coins}</TableCell> */}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const _render = () => {
    if (
      !loading &&
      _.isEmpty(error) &&
      _.isEmpty(data) &&
      tickerSymbol === undefined
    ) {
      return (
        <Typography
          sx={{
            py: 10
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {t("We couldn't find any data")}
        </Typography>
      );
    } else if (
      !loading &&
      _.isEmpty(data) &&
      (!_.isEmpty(error) || error === undefined)
    ) {
      return (
        <Box sx={{ textAlign: 'center', p: 2 }}>
          <Typography variant="h4">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return RenderTable();
  };

  return (
    <>
      <Card>
        <Box px={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2
            }}
          >
            <Typography
              variant="h5"
              sx={{ color: theme.colors.alpha.black[100] }}
            >
              {t('Addresses Richer Than')}
            </Typography>
          </Box>
        </Box>
        <Divider />
        {/* Render conditionally */}
        {_render()}
      </Card>
    </>
  );
};
