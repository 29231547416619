import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useDispatch, useSelector } from '../../../../../store';
import { getAddressDepositing } from '../../../../../slices/owner';
import { useLocation } from 'react-router';
import { customNumeral } from '../../../../../utils/utils';
import { CircularProgress } from '@material-ui/core';
import { GLOBALS } from '../../../../../globals/Globals';

function RenderChart({ addressDepositing, changeAction }) {
  const theme = useTheme();

  const { loading, error, data } = addressDepositing;

  const [dateArray, setDateArray] = useState([]);
  const [credits, setCredits] = useState([]);
  const [debits, setDebits] = useState([]);
  const [changes, setChagnes] = useState([]);

  useEffect(() => {
    _.map(data.dates, (item, index) => {
      setDateArray((oldState) => [
        ...oldState,
        item ? new Date(item).toDateString() : ''
      ]);
    });
    _.map(data.credits, (item, index) => {
      setCredits((oldState) => [...oldState, item]);
    });
    _.map(data.debits, (item, index) => {
      setDebits((oldState) => [...oldState, item]);
    });
    _.map(data.changes, (item, index) => {
      setChagnes((oldState) => [...oldState, item]);
    });
  }, [loading, data]);

  let options = {
    chart: {
      height: '200',
      id: 'basic-line', // id of the chart
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        autoSelected: 'zoom'
      },
      offsetY: 20,
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      }
    },
    noData: {
      text: 'No Data Available.',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined
      }
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      type: 'datetime',
      categories: dateArray
    },
    yaxis: {
      title: {
        text: 'Addresses'
      },
      reversed: true,
      labels: {
        formatter(value) {
          if (typeof value !== 'undefined') {
            return customNumeral({ value });
          }
          return value;
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },

    colors: ['#F4002C', '#F49200', '#0061F4'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: undefined,
      width: 2,
      dashArray: 0
    },
    markers: { size: 5 },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter(value) {
          if (typeof value !== 'undefined') {
            return value;
          }
          return value;
        }
      }
    },
    series: [
      {
        name: 'Depositers',
        type: 'line',
        data: credits
      },
      {
        name: 'Withdrawers',
        type: 'line',
        data: debits
      },
      {
        name: 'Balance Change',
        type: 'column',
        data: changes
      }
    ]
  };

  const [chart, setChart] = useState(options);

  useEffect(() => {
    setChart(options);
  }, [changeAction, dateArray, credits, debits, changes]);

  return (
    <Chart
      options={options}
      series={chart.series}
      type="bar"
      width="100%"
      height="85%"
    />
  );
}

export const TopTransferBtwExchangeChart = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const tickerSymbol = location.pathname.split('/')[2];

  const periods = [
    {
      value: '1D',
      text: '1D'
    },
    {
      value: '1W',
      text: '1W'
    },
    {
      value: '2W',
      text: '2W'
    },
    {
      value: '3W',
      text: '3W'
    },
    {
      value: '1M',
      text: '1M'
    }
  ];

  const actionRef = useRef(null);
  const [changeAction, setChangeAction] = useState(periods[1].text);
  const [
    openTopTransferBtwExchangeChartChange,
    setOpenTopTransferBtwExchangeChartChange
  ] = useState(false);
  const [topTransferBtwExchangeChartTime, setTopTransferBtwExchangeChartTime] =
    useState('1W');

  const handleTopTransferBtwExchangeChartChange = (value) => {
    setChangeAction(value);
    setOpenTopTransferBtwExchangeChartChange(false);
    if (value === '1D') {
      setTopTransferBtwExchangeChartTime(value);
    }
    if (value === '1W') {
      setTopTransferBtwExchangeChartTime(value);
    }
    if (value === '2W') {
      setTopTransferBtwExchangeChartTime(value);
    }
    if (value === '3W') {
      setTopTransferBtwExchangeChartTime(value);
    }
    if (value === '1M') {
      setTopTransferBtwExchangeChartTime(value);
    }
  };

  const dispatch = useDispatch();
  const { addressDepositing } = useSelector((state) => state.owner);
  const { loading, error, data } = addressDepositing;

  useEffect(() => {
    dispatch(
      getAddressDepositing(tickerSymbol, topTransferBtwExchangeChartTime)
    );
  }, [changeAction]);

  const _render = () => {
    if (loading) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      );
    } else if (!loading && (!_.isEmpty(error) || error === undefined)) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <RenderChart
        addressDepositing={addressDepositing}
        changeAction={changeAction}
      />
    );
  };

  return (
    <>
      {/* TODO: fixed height */}
      <Box
        component={Paper}
        sx={{ height: { xs: '400px', sm: '100%', md: '100%' }, p: 2 }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4">{t('Address Depositing')}</Typography>
            <Box sx={{ p: 1, display: 'flex', cursor: 'pointer' }}>
              <Tooltip title={GLOBALS.OWNER_ADDRESS_DEPOSITING_GRAPH} arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </Box>
          </Box>
          <Box>
            <Button
              color="secondary"
              ref={actionRef}
              onClick={() => setOpenTopTransferBtwExchangeChartChange(true)}
              endIcon={<ExpandMoreTwoToneIcon />}
              size="small"
            >
              {changeAction}
            </Button>
          </Box>
          <Menu
            disableScrollLock
            anchorEl={actionRef.current}
            onClose={() => setOpenTopTransferBtwExchangeChartChange(false)}
            open={openTopTransferBtwExchangeChartChange}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {periods.map((_location) => (
              <MenuItem
                key={_location.value}
                onClick={() =>
                  handleTopTransferBtwExchangeChartChange(_location.value)
                }
              >
                {_location.text}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {_render()}
      </Box>
    </>
  );
};
