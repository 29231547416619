import React from 'react';
import { Container } from '@mui/material';
import { TopTokensComponent } from './TopTokensComponent';

const TopCrypto = () => {
  return (
    <Container maxWidth="xl" spacing={4}>
      <TopTokensComponent />
    </Container>
  );
};

export default TopCrypto;
