import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import Chart from 'react-apexcharts';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useDispatch, useSelector } from '../../../../../store';
import { getTokenOnExchange } from '../../../../../slices/owner';
import { GLOBALS } from '../../../../../globals/Globals';
import { customNumeral } from '../../../../../utils/utils';

const tokenOnExchangeChanges = [
  {
    value: '1D',
    text: '1D'
  },
  {
    value: '1W',
    text: '1W'
  },
  {
    value: '2W',
    text: '2W'
  },
  {
    value: '3W',
    text: '3W'
  },
  {
    value: '1M',
    text: '1M'
  }
];
function RenderChart(props) {
  const theme = useTheme();
  const [tokenOnExchangeSeries, setTokenOnExchangeSeries] = useState({});
  const [totalExchanges, setTotalExchanges] = useState([]);
  const [dates, setDates] = useState([]);

  let options = {
    chart: {
      height: '200',
      id: 'basic-line', // id of the chart
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        autoSelected: 'zoom'
      },
      offsetY: 20,
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      }
    },
    noData: {
      text: 'No Data Available.',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined
      }
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      categories: dates,
      type: 'datetime'
    },
    yaxis: {
      labels: {
        formatter(value) {
          if (typeof value !== 'undefined') {
            return customNumeral({ value });
          }
          return value;
        }
      },
      title: {
        text: 'Tokens'
      }
    },
    tooltip: {
      shared: true,
      y: {
        formatter(value) {
          if (typeof value !== 'undefined') {
            return value;
          }
          return value;
        }
      }
    },
    colors: ['#E8BD0D', '#1B98F5', '#50DBB4'],
    stroke: {
      show: true,
      curve: 'straight',
      lineCap: 'butt',
      colors: undefined,
      width: 2,
      dashArray: 0
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    }
  };

  const seriesData = totalExchanges.map((exchange) => {
    return {
      name: exchange.charAt(0).toUpperCase() + exchange.slice(1),
      data: tokenOnExchangeSeries[exchange].values
    };
  });

  const handleChangeCallback = useCallback(() => {
    if (props.tokenOnExchanges.data) {
      const exchanges = Object.keys(props.tokenOnExchanges.data);
      setTokenOnExchangeSeries(props.tokenOnExchanges.data);
      setTotalExchanges(exchanges);
      setDates(props.tokenOnExchanges.data[exchanges[0]].dates);
    }
  }, [props.tokenOnExchanges]);

  useEffect(handleChangeCallback, [props.tokenOnExchanges]);

  return (
    <Chart
      options={options}
      series={seriesData}
      type="line"
      width="100%"
      height="85%"
    />
  );
}
export const TopTxWithinExchangeChart = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const tickerSymbol = location.pathname.split('/')[2];
  const dispatch = useDispatch();

  const [changeAction, setChangeAction] = useState(
    tokenOnExchangeChanges[1].text
  );
  const actionRef = useRef(null);
  const [openTokenOnExchangeChange, setOpenTokenOnExchangeChange] =
    useState(false);
  const [
    tokenOnExchangeChangeCurrentTime,
    setTokenOnExchangeChangeCurrentTime
  ] = useState('1W');
  useEffect(() => {
    dispatch(
      getTokenOnExchange(tickerSymbol, tokenOnExchangeChangeCurrentTime)
    );
  }, [changeAction]);

  const { tokenOnExchanges } = useSelector((state) => state.owner);
  const { loading, error, data } = tokenOnExchanges;

  const handleTokenOnExchangeChange = (value) => {
    setChangeAction(value);
    setOpenTokenOnExchangeChange(false);
    if (value === '1D') {
      setTokenOnExchangeChangeCurrentTime(value);
    }
    if (value === '1W') {
      setTokenOnExchangeChangeCurrentTime(value);
    }
    if (value === '2W') {
      setTokenOnExchangeChangeCurrentTime(value);
    }
    if (value === '3W') {
      setTokenOnExchangeChangeCurrentTime(value);
    }
    if (value === '1M') {
      setTokenOnExchangeChangeCurrentTime(value);
    }
    if (value === '1Y') {
      setTokenOnExchangeChangeCurrentTime(value);
    }
  };

  const _render = () => {
    if (loading) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      );
    } else if (!loading && (!_.isEmpty(error) || error === undefined)) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">{GLOBALS.ERROR_MSG}</Typography>
        </Box>
      );
    }
    return <RenderChart tokenOnExchanges={tokenOnExchanges} />;
  };

  return (
    <>
      {/* TODO: fixed height */}
      <Box
        component={Paper}
        sx={{ height: { xs: '400px', sm: '100%', md: '100%' }, p: 2 }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4">{t('Token On Exchanges')}</Typography>
            <Box sx={{ p: 1, display: 'flex', cursor: 'pointer' }}>
              <Tooltip title={GLOBALS.OWNER_TOKEN_ON_EXCHANGE_GRAPH} arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </Box>
          </Box>
          <Box>
            <Button
              color="secondary"
              ref={actionRef}
              onClick={() => setOpenTokenOnExchangeChange(true)}
              endIcon={<ExpandMoreTwoToneIcon />}
              size="small"
            >
              {changeAction}
            </Button>
          </Box>
          <Menu
            disableScrollLock
            anchorEl={actionRef.current}
            onClose={() => setOpenTokenOnExchangeChange(false)}
            open={openTokenOnExchangeChange}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {tokenOnExchangeChanges.map((_location) => (
              <MenuItem
                key={_location.value}
                onClick={() => handleTokenOnExchangeChange(_location.value)}
              >
                {_location.text}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {_render()}
      </Box>
    </>
  );
};
