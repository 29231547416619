import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled, alpha } from '@mui/material/styles';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import LightModeTwoToneIcon from '@mui/icons-material/LightModeTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import NotificationImportantTwoToneIcon from '@mui/icons-material/NotificationImportantTwoTone';
import MovingRoundedIcon from '@mui/icons-material/MovingRounded';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import { ReactComponent as WatchlistIcon } from '../../assets/Watchlist.svg';
import deqodeIcon from '../../assets/deqode.png';
import {
  IconButton,
  Typography,
  useTheme,
  Box,
  lighten,
  Tooltip,
  InputAdornment,
  TextField,
  Hidden
} from '@mui/material';
import _ from 'lodash';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { GLOBALS } from '../../globals/Globals';
import { SidebarContext } from '../../context/SidebarContext';
import { SearchContext } from '../../context/SearchContext';
import { ThemeContext } from '../../theme/ThemeProvider';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from '../../store';
import { getTopTokens } from '../../slices/topTokens';
import { DialogWrapperComponent } from './DialogWrapper';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    right: 0;
    background-color: ${alpha(theme.header.background, 0.95)};
    backdrop-filter: blur(3px);
    justify-content: space-between;
    width: 100%;  
  `
);

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledText = styled(Typography)(
  ({ theme }) => `
  white-space:nowrap;
  &:hover {
    background-color: ${theme.colors.secondary.main};
    color: ${theme.palette.getContrastText(theme.colors.secondary.main)};
  }
  font-weight:'bold';
`
);

export const Header = () => {
  const { t } = useTranslation();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { requestSearch } = useContext(SearchContext);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const { tokens, loading, error } = useSelector((state) => state.topToken);

  // dispatch, only once
  useEffect(() => {
    if (_.isEmpty(tokens)) {
      dispatch(getTopTokens('1D'));
    }
  }, [tokens]);

  const handleSearchClick = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        requestSearch(query.toUpperCase());
      }
    },
    [requestSearch, query]
  );

  const setThemeName = useContext(ThemeContext);

  const themeToggle = () => {
    const themeName =
      theme.palette.mode === 'dark' ? 'PureLightTheme' : 'DarkSpacesTheme';
    setThemeName(themeName);
  };

  const [searchValue, setSearchValue] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(false);
  const [tokenResults, setTokenResults] = useState([]);

  const handleSearchChange = async (event) => {
    event.preventDefault();

    if (event.target.value) {
      setSearchResults(false);
      setSearchValue(event.target.value);
      setSearchLoading(true);

      const token = tokens.filter((_token) => {
        let tokens = [];
        if (_token.name !== null) {
          if (
            _token.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
            _token.ticker_symbol
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) >= 0
          ) {
            tokens.push(_token);
            return tokens;
          }
        }
      });
      setTokenResults(token);
      setSearchLoading(false);
      setSearchResults(true);
    } else {
      setSearchValue('');
      setSearchResults(false);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    const tokenDialogSearchElement =
      document.getElementById('token_dialog_input');
    setOpen(true);
    setTimeout(() => {
      tokenDialogSearchElement.focus();
    }, 500);
  };

  const handleClose = () => {
    const tokenSearchElement = document.getElementById('token_input');
    setOpen(false);
    setTimeout(() => {
      tokenSearchElement.blur();
    }, 100);
  };

  return (
    <>
      <HeaderWrapper
        display="flex"
        mb={3}
        py={1}
        px={1}
        gap={{ xs: 1, md: 0 }}
        alignItems="center"
        flexDirection={{ sm: 'row' }}
        sx={{
          boxShadow:
            theme.palette.mode === 'dark'
              ? `0 1px 0 ${alpha(
                  lighten(theme.colors.primary.main, 0.7),
                  0.15
                )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
              : `0px 2px 8px -3px ${alpha(
                  theme.colors.alpha.black[100],
                  0.2
                )}, 0px 5px 22px -4px ${alpha(
                  theme.colors.alpha.black[100],
                  0.1
                )}`
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: { md: 'row' },
            pt: 2
          }}
        >
          <IconButton onClick={toggleSidebar} sx={{ color: '#1A75FF' }}>
            {!sidebarToggle ? (
              <MenuTwoToneIcon fontSize="small" />
            ) : (
              <CloseTwoToneIcon fontSize="small" />
            )}
          </IconButton>
          <Hidden only={['xs', 'sm']}>
            <Typography
              variant="h3"
              component="div"
              color="inherit"
              sx={{ paddingLeft: 1 }}
            >
              <Link to={GLOBALS.HOME_ROUTE}>
                <LazyLoadComponent>
                  <img
                    src={deqodeIcon}
                    alt="Deqode"
                    style={{ height: 60, width: 210 }}
                  />
                </LazyLoadComponent>
              </Link>
            </Typography>
          </Hidden>
          <Hidden only={['xs', 'sm']}>
            <Box
              sx={{ display: 'flex', gap: 0.1, alignItems: 'center' }}
              ml={2}
            >
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchTwoToneIcon sx={{ cursor: 'pointer' }} />
                    </InputAdornment>
                  )
                }}
                onClick={handleClickOpen}
                placeholder={t('Search')}
                value={query}
                fullWidth
                variant="outlined"
                onKeyPress={handleSearchClick}
                id="token_input"
              />
            </Box>
          </Hidden>
          <Hidden only={['md', 'lg', 'xl']}>
            <Box
              sx={{ display: 'flex', gap: 0.1, alignItems: 'center' }}
              ml={3}
            >
              <Box margin={{ md: 1 }}>
                <Tooltip arrow title={t('Search')}>
                  <StyledText borderRadius={1}>
                    <SearchTwoToneIcon
                      sx={{ cursor: 'pointer', display: 'block' }}
                      onClick={handleClickOpen}
                    />
                  </StyledText>
                </Tooltip>
              </Box>
            </Box>
          </Hidden>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: { md: 'row' }
          }}
        >
          <Box marginLeft={{ xs: 0, md: 3 }}>
            <StyledLink to={GLOBALS.WATCHLIST_ROUTE}>
              <StyledText
                variant="subtitle1"
                px={{ xs: 0.5, md: 2 }}
                py={0.5}
                borderRadius={1}
              >
                <Hidden only={['md', 'lg', 'xl']}>
                  <Box margin={{ md: 1 }}>
                    <Tooltip arrow title={t('Watchlist')}>
                      <StyledText
                        px={{ xs: 0.5, md: 2 }}
                        py={0.5}
                        borderRadius={1}
                      >
                        <WatchlistIcon
                          fontSize="medium"
                          sx={{ m: 0.5, fontWeight: 'bolder' }}
                        />
                      </StyledText>
                    </Tooltip>
                  </Box>
                </Hidden>
                <Hidden only={['xs', 'sm']}>{t('Watchlist')}</Hidden>
              </StyledText>
            </StyledLink>
          </Box>
          <Box marginLeft={{ xs: 0, md: 3 }}>
            <StyledLink to={GLOBALS.SMART_ALERT_ROUTE}>
              <StyledText
                variant="subtitle1"
                px={{ xs: 0.5, md: 2 }}
                py={0.5}
                borderRadius={1}
              >
                <Hidden only={['md', 'lg', 'xl']}>
                  <Box margin={{ md: 1 }}>
                    <Tooltip arrow title={t('Smart Alert')}>
                      <StyledText
                        px={{ xs: 0.5, md: 2 }}
                        py={0.5}
                        borderRadius={1}
                      >
                        <NotificationImportantTwoToneIcon
                          fontSize="medium"
                          sx={{ m: 0.5 }}
                        />
                      </StyledText>
                    </Tooltip>
                  </Box>
                </Hidden>
                <Hidden only={['xs', 'sm']}>{t('Smart Alert')}</Hidden>
              </StyledText>
            </StyledLink>
          </Box>
          <Box margin={{ md: 2 }}>
            <Tooltip arrow title={t('Dark Mode')}>
              <StyledText
                px={{ xs: 0.5, md: 2 }}
                py={0.5}
                borderRadius={1}
                onClick={() => themeToggle()}
              >
                <LightModeTwoToneIcon fontSize="medium" sx={{ m: 0.5 }} />
              </StyledText>
            </Tooltip>
          </Box>
          <Box margin={{ md: 1 }}>
            <Tooltip arrow title={t('Notifications')}>
              <StyledText px={{ xs: 0.5, md: 2 }} py={0.5} borderRadius={1}>
                <NotificationsActiveTwoToneIcon
                  fontSize="medium"
                  sx={{ m: 0.5 }}
                />
              </StyledText>
            </Tooltip>
          </Box>
        </Box>
      </HeaderWrapper>

      {/* render dialogWrapper component */}
      <DialogWrapperComponent
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        searchValue={searchValue}
        handleSearchChange={handleSearchChange}
        searchLoading={searchLoading}
        searchResults={searchResults}
        tokenResults={tokenResults}
      />
    </>
  );
};
