import { createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';

const initialState = {
  news: [],
  loading: true,
  error: ''
};

const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNews(state, action) {
      return {
        ...state,
        news: state.news.concat(action.payload)
      };
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getNews = (ticker_symbol, limit, offset) => async (dispatch) => {
  dispatch(slice.actions.setLoading(true));
  try {
    const response = await axios.get(
      `/exchanges/top-transaction-news/${ticker_symbol}?offset=${offset}&limit=${limit}`
    );
    dispatch(slice.actions.setNews(response.data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  }
  dispatch(slice.actions.setLoading(false));
};

export default slice;
