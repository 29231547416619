import { createSlice } from '@reduxjs/toolkit';

import axios from '../utils/axios';
import { getUniqueElements, mergeSort, sortedUniqueBy } from '../utils/utils';

const initialState = {
  ohlc: {
    loading: true,
    error: '',
    data: []
  },
  tokenDetails: {
    loading: true,
    error: '',
    data: {}
  },
  basicTokenDetails: {
    loading: true,
    error: '',
    data: []
  },
  marketCap: {
    loading: true,
    error: '',
    data: {}
  },
  fullyDilutedMarketCap: {
    loading: true,
    error: '',
    data: {}
  },
  volume: {
    loading: true,
    error: '',
    data: {}
  },
  circulatingSupply: {
    loading: true,
    error: '',
    data: []
  },
  tokenPriceHistory: {
    loading: true,
    error: '',
    data: {}
  },
  tokenPrice: {
    loading: true,
    error: '',
    data: {},
    last24h: {}
  }
};

const slice = createSlice({
  name: 'crypto',
  initialState,
  reducers: {
    // Set Token Details
    setTokenDetails(state, action) {
      state.tokenDetails.data = action.payload;
    },
    setTokenDetailsLoading(state, action) {
      state.tokenDetails.loading = action.payload;
    },
    setTokenDetailsError(state, action) {
      state.tokenDetails.loading = false;
      state.tokenDetails.error = action.payload;
    },

    // Set Basic Token Details
    setBasicTokenDetails(state, action) {
      state.basicTokenDetails.data = action.payload;
    },
    setBasicTokenDetailsError(state, action) {
      state.basicTokenDetails.error = action.payload;
    },
    setBasicTokenDetailsLoading(state, action) {
      state.basicTokenDetails.loading = action.payload;
    },

    // Set MarketCap
    setMarketCap(state, action) {
      state.marketCap.data = action.payload;
    },
    setMarketCapLoading(state, action) {
      state.marketCap.loading = action.payload;
    },
    setMarketCapError(state, action) {
      state.marketCap.loading = false;
      state.error = action.payload;
    },

    // Set Volume
    setVolume(state, action) {
      state.volume.data = action.payload;
    },
    setVolumeLoading(state, action) {
      state.volume.loading = action.payload;
    },
    setVolumeError(state, action) {
      state.volume.loading = false;
      state.volume.error = action.payload;
    },

    // Set FullDiluted
    setFullDiluted(state, action) {
      state.fullyDilutedMarketCap.data = action.payload;
    },
    setFullDilutedLoading(state, action) {
      state.fullyDilutedMarketCap.loading = action.payload;
    },
    setFullDilutedError(state, action) {
      state.fullyDilutedMarketCap.loading = false;
      state.fullyDilutedMarketCap.error = action.payload;
    },

    // Set Circulating Supply
    getCirculatingSupply(state, action) {
      const { circulatingSupplyDetails } = action.payload;
      state.circulatingSupply.loading = false;
      state.circulatingSupply.data = circulatingSupplyDetails;
    },
    setCirculatingSupplyError(state, action) {
      state.circulatingSupply.loading = false;
      state.circulatingSupply.error = action.payload;
    },

    // Set OHLC
    setOhlc(state, action) {
      state.ohlc.data = getUniqueElements(
        mergeSort(state.ohlc.data.concat(action.payload), (i) => i.time)
      );
    },
    setOhlcLoading(state, action) {
      state.ohlc.loading = action.payload;
    },
    setOhlcError(state, action) {
      state.error = action.payload;
    },
    setInitialOhlc(state, action) {
      state.ohlc = action.payload;
    },

    // Set Token Price
    setTokenPrice(state, action) {
      state.tokenPrice.data = action.payload;
    },
    set24hTokenPrice(state, action) {
      state.tokenPrice.last24h = action.payload;
    },
    setTokenPriceLoading(state, action) {
      state.tokenPrice.loading = action.payload;
    },
    setTokenPriceError(state, action) {
      state.tokenPrice.loading = false;
      state.tokenPrice.error = action.payload;
    },

    // Set Token PriceHistory
    setTokenPriceHistory(state, action) {
      state.tokenPriceHistory.data = action.payload;
    },
    setTokenPriceHistoryLoading(state, action) {
      state.tokenPriceHistory.loading = action.payload;
    },
    setTokenPriceHistoryError(state, action) {
      state.tokenPriceHistory.loading = false;
      state.tokenPriceHistory.error = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getTokenDetails = (tickerSymbol) => async (dispatch) => {
  dispatch(slice.actions.setTokenDetailsLoading(true));
  try {
    const response = await axios.get(`/token-details/${tickerSymbol}`);
    dispatch(slice.actions.setTokenDetails(response.data));
  } catch (err) {
    dispatch(slice.actions.setTokenDetailsError(err));
  }
  dispatch(slice.actions.setTokenDetailsLoading(false));
};

export const getBasicTokenDetails = (tickerSymbol) => async (dispatch) => {
  dispatch(slice.actions.setBasicTokenDetailsLoading(true));
  try {
    const response = await axios.get(
      `/token-details/basic-details/${tickerSymbol}`
    );
    dispatch(slice.actions.setBasicTokenDetails(response.data));
  } catch (err) {
    dispatch(slice.actions.setBasicTokenDetailsError(err));
  }
  dispatch(slice.actions.setBasicTokenDetailsLoading(false));
};

export const getMarketCap = (tickerSymbol, time) => async (dispatch) => {
  dispatch(slice.actions.setMarketCapLoading(true));
  try {
    const response = await axios.get(
      `/token-details/market-cap/${tickerSymbol}/${time}`
    );
    dispatch(slice.actions.setMarketCap(response.data));
    dispatch(slice.actions.setMarketCapLoading(false));
  } catch (err) {
    dispatch(slice.actions.setMarketCapError(err));
  }
};

export const getVolume = (tickerSymbol, time) => async (dispatch) => {
  dispatch(slice.actions.setVolumeLoading(true));
  try {
    const response = await axios.get(
      `/token-details/volume/${tickerSymbol}/${time}`
    );
    dispatch(slice.actions.setVolume(response.data));
    dispatch(slice.actions.setVolumeLoading(false));
  } catch (err) {
    dispatch(slice.actions.setVolumeError(err));
  }
};

export const getFullyDilutedMarketCap = (tickerSymbol) => async (dispatch) => {
  dispatch(slice.actions.setFullDilutedLoading(true));
  try {
    // TODO: change in marketcap
    const response = await axios.get(
      `/token-details/diluted-market-cap/${tickerSymbol}`
    );
    dispatch(slice.actions.setFullDiluted(response.data));
    dispatch(slice.actions.setFullDilutedLoading(false));
  } catch (err) {
    dispatch(slice.actions.setFullDilutedError(err));
  }
};

export const getTokenPrice =
  (tickerSymbol, time, is24h) => async (dispatch) => {
    dispatch(slice.actions.setTokenPriceLoading(true));
    try {
      const response = await axios.get(
        `/token-details/price/${tickerSymbol}/${time}`
      );
      if (is24h) {
        dispatch(slice.actions.set24hTokenPrice(response.data));
      } else {
        dispatch(slice.actions.setTokenPrice(response.data));
      }
    } catch (err) {
      dispatch(slice.actions.setTokenPriceError(err));
    }
    dispatch(slice.actions.setTokenPriceLoading(false));
  };

export const getTokenPriceHistory = (tickerSymbol) => async (dispatch) => {
  dispatch(slice.actions.setTokenPriceHistoryLoading(true));
  try {
    const response = await axios.get(
      `/token-details/price-history/${tickerSymbol}`
    );
    dispatch(slice.actions.setTokenPriceHistory(response.data));
    dispatch(slice.actions.setTokenPriceHistoryLoading(false));
  } catch (err) {
    dispatch(slice.actions.setTokenPriceHistoryError(err));
  }
};

export const getOHLC =
  (tickerSymbol, period, range, offset) => async (dispatch) => {
    dispatch(slice.actions.setOhlcLoading(true));
    try {
      const response = await axios.get(
        `/ohlc/${tickerSymbol}/${range}/${period}/${offset}`
      );
      const unsortedData = response.data.map((item) => {
        const { time, open, high, low, close } = item;
        return {
          time: Number(time),
          open: Number(open),
          high: Number(high),
          low: Number(low),
          close: Number(close)
        };
      });
      // const sortedData = mergeSort(unsortedData);
      const sortedData = sortedUniqueBy(mergeSort(unsortedData), (i) => i.time);
      dispatch(slice.actions.setOhlc(sortedData));
      dispatch(slice.actions.setOhlcLoading(false));
    } catch (err) {
      dispatch(slice.actions.setOhlcError(err));
    }
  };

export const clearOHLC = () => async (dispatch) => {
  dispatch(
    slice.actions.setInitialOhlc({
      data: [],
      loading: true,
      error: ''
    })
  );
};

export default slice;
