import React, { useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  Skeleton
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useDispatch } from '../../../store';
import { GLOBALS } from '../../../globals/Globals';
import userImage from '../../../assets/user-image.jpg';

export const SidebarTopSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <>
      <Box
        sx={{
          textAlign: 'center',
          pt: 1,
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          background: theme.sidebar.topSectionBackground
        }}
      >
        {false ? (
          <Skeleton
            animation="wave"
            variant="circular"
            width={60}
            height={60}
          />
        ) : (
          <Box>
            <a href={GLOBALS.PROFILE}>
              <Avatar
                sx={{
                  width: 68,
                  height: 68,
                  mb: 2,
                  mx: 'auto',
                  color: 'rgba(34, 51, 84, 0.5)'
                }}
                alt={'User Image'}
                src={userImage}
              />
            </a>
          </Box>
        )}
        {false ? (
          <Skeleton animation="wave" height={20} width="100px" />
        ) : (
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                color: `${theme.colors.alpha.black[100]}`,
                fontSize: 16,
                fontWeight: 600
              }}
            >
              Randy Smith
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
