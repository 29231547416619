import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Grid, useTheme } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { Label } from '../../../components/Label';
import CryptoTab from './Crypto';
import OwnerTab from './Owner';
import InsidersTab from './Insiders';
import NewsTimeline from './News';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`navtab-tabpanel-${index}`}
      aria-labelledby={`navtab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const useCustomStyles = makeStyles((theme) => ({
  styledTabs: {
    border: '1px solid rgba(34, 51, 84, 0.5)',
    boxSizing: 'border-box',
    borderRadius: 4,
    marginLeft: 10
  },
  styledInactiveButtons: {
    width: 160,
    position: 'relative',
    zIndex: 9,
    backgroud: 'none',
    color: '#8C95A7',
    border: '0.1px solid #8C95A7',
    '&:hover': {
      background: 'none',
      border: '0.1px solid #8C95A7'
    }
  },
  styledInactiveButtonsText: (props) => ({
    position: 'absolute',
    top: -8,
    left: 10,
    fontSize: 10,
    color: '#8C95A7',
    background: props.general.background, // #44484e
    padding: '0px 2px 0px 2px',
    borderBottom: 'none',
    borderRadius: 4,
    height: '14px'
  }),
  styledActiveButtons: (props) => ({
    width: 160,
    position: 'relative',
    zIndex: 9,
    background: props.tabs.background,
    border: '0.1px solid #E8F1FF',
    '&:hover': {
      background: props.tabs.background,
      border: '0.1px solid #E8F1FF'
    }
  }),
  styledActiveButtonsText: (props) => ({
    position: 'absolute',
    top: -8,
    left: 10,
    fontSize: 10,
    color: '#8C95A7',
    background: props.tabs.background,
    border: '0.1px solid #E8F1FF',
    borderBottom: 'none',
    borderRadius: 4,
    height: '14px'
  })
}));

export const CryptoNavTabs = () => {
  const theme = useTheme();
  const customClasses = useCustomStyles(theme);
  const [values, setValue] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (values === null) {
      setValue(0);
    }
  }, []);

  const tickerSymbol = location.pathname.split('/')[2];
  let currentTab = location.pathname;

  useEffect(() => {
    const insiderTab =
      currentTab === `/crypto/${tickerSymbol}/insiders`
        ? `/crypto/${location.pathname.split('/')[2]}/insiders`
        : `/crypto/${location.pathname.split('/')[2]}/insiders/`;

    if (location.pathname === '/crypto') {
      setValue(0);
      currentTab = `/crypto/${tickerSymbol}`;
    } else if (location.pathname === `/crypto/${tickerSymbol}/owners`) {
      setValue(1);
      currentTab = `/crypto/${tickerSymbol}/owners`;
    } else if (location.pathname === insiderTab) {
      setValue(2);
      currentTab = `/crypto/${tickerSymbol}/insiders`;
    } else if (location.pathname === `/crypto/${tickerSymbol}/news`) {
      setValue(3);
      currentTab = `/crypto/${tickerSymbol}/news`;
    }
  }, [currentTab]);

  const handleClick = (event) => {
    if (event.target.value === undefined) return;
    if (event.target.value != values) setValue(event.target.value);
  };

  const renderStepsWithLabelIcon = ({ step, label, colorScheme }) => {
    return (
      <div>
        <Label color={colorScheme}>{label}</Label>
      </div>
    );
  };

  return (
    <Box sx={{ width: '100%' }} mx="auto">
      <Box
        sx={{
          borderColor: 'divider',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }
        }}
      >
        <Grid
          container
          spacing={12}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 4 }}
          sx={{ pl: 1 }}
        >
          <Box
            sx={{
              padding: 1,
              display: 'flex',
              flexWrap: 'wrap',
              alignItem: 'center'
            }}
          >
            <Grid item xs={6} md={4} sx={{ p: 1 }}>
              <Link
                to={`/crypto/${location.pathname.split('/')[2]}`}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="outlined"
                  startIcon={renderStepsWithLabelIcon({
                    step: 0,
                    label: 'C',
                    colorScheme: 'success'
                  })}
                  className={
                    values == 0
                      ? customClasses.styledActiveButtons
                      : customClasses.styledInactiveButtons
                  }
                  value={0}
                  onClick={(event) => handleClick(event)}
                >
                  <span
                    className={
                      values == 0
                        ? customClasses.styledActiveButtonsText
                        : customClasses.styledInactiveButtonsText
                    }
                  >
                    Step 1
                  </span>
                  Crypto
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6} md={4} sx={{ p: 1 }}>
              <Link
                to={`/crypto/${location.pathname.split('/')[2]}/owners`}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="outlined"
                  startIcon={renderStepsWithLabelIcon({
                    step: 1,
                    label: 'O',
                    colorScheme: 'warning'
                  })}
                  className={
                    values == 1
                      ? customClasses.styledActiveButtons
                      : customClasses.styledInactiveButtons
                  }
                  value={1}
                  onClick={(event) => handleClick(event)}
                >
                  <span
                    className={
                      values == 1
                        ? customClasses.styledActiveButtonsText
                        : customClasses.styledInactiveButtonsText
                    }
                  >
                    Step 2
                  </span>
                  Owners
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6} md={4} sx={{ p: 1 }}>
              <Link
                to={`/crypto/${location.pathname.split('/')[2]}/insiders`}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="outlined"
                  startIcon={renderStepsWithLabelIcon({
                    step: 2,
                    label: 'I',
                    colorScheme: 'error'
                  })}
                  className={
                    values == 2
                      ? customClasses.styledActiveButtons
                      : customClasses.styledInactiveButtons
                  }
                  value={2}
                  onClick={(event) => handleClick(event)}
                >
                  <span
                    className={
                      values == 2
                        ? customClasses.styledActiveButtonsText
                        : customClasses.styledInactiveButtonsText
                    }
                  >
                    Step 3
                  </span>
                  Insiders
                </Button>
              </Link>
            </Grid>
          </Box>
        </Grid>
      </Box>
      <TabPanel
        value={values}
        active={`/crypto/${location.pathname.split('/')[2]}`}
        index={0}
      >
        <LazyLoad>
          <CryptoTab />
        </LazyLoad>
      </TabPanel>
      <TabPanel
        value={values}
        active={`/crypto/${location.pathname.split('/')[2]}/owners`}
        index={1}
      >
        <LazyLoad>
          <OwnerTab />
        </LazyLoad>
      </TabPanel>
      <TabPanel
        value={values}
        active={`/crypto/${location.pathname.split('/')[2]}/owners`}
        index={2}
      >
        <LazyLoad>
          <InsidersTab />
        </LazyLoad>
      </TabPanel>
    </Box>
  );
};
