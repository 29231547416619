import { createSlice } from '@reduxjs/toolkit';

import axios from '../utils/axios';

const initialState = {
  tokens: [],
  loading: true,
  error: ''
};

const slice = createSlice({
  name: 'topToken',
  initialState,
  reducers: {
    setTopTokens(state, action) {
      state.tokens = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getTopTokens = (time) => async (dispatch) => {
  dispatch(slice.actions.setLoading(true));
  try {
    const response = await axios.get(`/top-tokens/${time}`);
    dispatch(slice.actions.setTopTokens(response.data.tokenDetails));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  }
  dispatch(slice.actions.setLoading(false));
};

export default slice;
