import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Avatar,
  Box,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import { CommonErrorTooltip } from '../../components/CommonErrorTooltip';
import { Label } from '../../components/Label';
import { ArrowDownwardWrapper } from '../../components/StyledComponents/ArrowDownwardWrapper';
import { ArrowUpwardWrapper } from '../../components/StyledComponents/ArrowUpwardWrapper';
import {
  EnhancedTableHead,
  getComparator,
  stableSort
} from '../../components/Table';
import { GLOBALS } from '../../globals/Globals';
import { useSelector } from '../../store';
import { coinLogos } from '../../utils/coinLogos';

const headCells = [
  {
    id: 'indexId',
    numeric: true,
    label: '#',
    showArrow: false,
    align: 'left',
    isSticky: true,
    left: 0
  },
  {
    id: 'name',
    numeric: false,
    label: 'Name',
    showArrow: true,
    align: 'left',
    isSticky: true,
    left: 35
  },
  {
    id: 'net_purchase_number',
    numeric: true,
    label: 'Net Purchase',
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'current_price_usd_number',
    numeric: true,
    label: 'Price',
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'market_cap_usd_number',
    numeric: true,
    label: 'Market Cap',
    showArrow: true,
    infoTooltip: true,
    infoMsg: GLOBALS.CRYPTO_MARKET_CAP,
    align: 'center',
    isSticky: false
  },
  {
    id: 'total_volume_usd_number',
    numeric: true,
    label: 'Volume Traded',
    showArrow: true,
    infoTooltip: true,
    infoMsg: GLOBALS.CRYPTO_VOLUME_TRADED,
    align: 'center',
    isSticky: false
  },
  {
    id: 'circulating_supply_number',
    numeric: true,
    label: 'Circulating Supply',
    showArrow: true,
    infoTooltip: true,
    infoMsg: GLOBALS.CRYPTO_CIRCULATING_SUPPLY,
    align: 'center',
    isSticky: false
  },
  {
    id: 'price_change_percentage_24h_number',
    numeric: true,
    label: '24H %',
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'price_change_percentage_7d_number',
    numeric: true,
    label: '7D %',
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'action',
    numeric: false,
    label: 'Actions',
    showArrow: false,
    align: 'center',
    isSticky: false
  }
];

const SkeletonLoad = (
  <>
    {[...Array(10)].map((_, index) => (
      <TableRow hover key={index}>
        {[...Array(10)].map((_, index) => (
          <TableCell key={index + 1} align="center">
            <Box sx={{ width: 100 }}>
              <Skeleton animation="wave" />
            </Box>
          </TableCell>
        ))}
      </TableRow>
    ))}
  </>
);

export const TopTokensTable = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('order_rank');
  const { loading, error, tokens } = useSelector((state) => state.topToken);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = tokens.length === 0 ? 0 : 1;

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
          stickyHeader
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={tokens.length}
            headCells={headCells}
          />
          <TableBody>
            {loading && error === ''
              ? SkeletonLoad
              : stableSort(
                  tokens.map((item, index) => {
                    return {
                      ...item,
                      order_rank: String(item.orderBy),
                      current_price_usd_number: _.toNumber(
                        item.current_price_usd
                      ),
                      current_price_usd_string: String(item.current_price_usd),

                      market_cap_usd_number: _.toNumber(item.market_cap_usd),
                      market_cap_usd_string: String(item.market_cap_usd),

                      total_volume_usd_number: _.toNumber(
                        item.total_volume_usd
                      ),
                      total_volume_usd_string: String(item.total_volume_usd),

                      price_change_percentage_24h_number: _.toNumber(
                        item.price_change_percentage_24h
                      ),
                      price_change_percentage_24h_string: String(
                        item.price_change_percentage_24h
                      ),

                      price_change_percentage_7d_number: _.toNumber(
                        item.price_change_percentage_7d
                      ),
                      price_change_percentage_7d_string: String(
                        item.price_change_percentage_7d
                      ),

                      net_purchase_number: _.toNumber(item.net_purchase_change),
                      net_purchase_string: String(item.net_purchase_change),

                      circulating_supply_number: _.toNumber(
                        item.circulating_supply
                      ),
                      circulating_supply_string: String(item.circulating_supply)
                    };
                  }),
                  getComparator(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((token, index) => {
                    const {
                      name,
                      address,
                      ticker_symbol,
                      total_volume_usd_string,
                      circulating_supply_string,
                      current_price_usd_string,
                      market_cap_usd_string,
                      net_purchase_string,
                      price_change_percentage_24h_string,
                      price_change_percentage_7d_string
                    } = token;
                    return (
                      <TableRow hover key={token.id}>
                        <TableCell
                          sx={{
                            position: 'sticky',
                            left: 0,
                            background: (theme) => theme.sidebar.background
                          }}
                        >
                          <Typography noWrap variant="subtitle2">
                            {t(index + 1)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            position: 'sticky',
                            left: 40,
                            background: (theme) => theme.sidebar.background
                          }}
                        >
                          <Tooltip title={address} placement="top" arrow>
                            <Box display="flex" alignItems="center">
                              <Avatar
                                sx={{
                                  mr: 1
                                }}
                                src={coinLogos[ticker_symbol]}
                              />
                              <Box>
                                <Typography variant="h5">{t(name)}</Typography>
                                <Typography noWrap variant="subtitle1">
                                  <b>{ticker_symbol}</b>
                                </Typography>
                              </Box>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          <CommonErrorTooltip
                            data={net_purchase_string}
                            isNumberAsString
                            prefixData="$"
                            style={{ whiteSpace: 'noWrap' }}
                            variant="subtitle2"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <CommonErrorTooltip
                            data={current_price_usd_string}
                            isNumberAsString
                            prefixData="$"
                            style={{ whiteSpace: 'noWrap' }}
                            variant="subtitle2"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <CommonErrorTooltip
                            data={market_cap_usd_string}
                            isNumberAsString
                            prefixData="$"
                            style={{ whiteSpace: 'noWrap' }}
                            variant="subtitle2"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <CommonErrorTooltip
                            data={total_volume_usd_string}
                            isNumberAsString
                            prefixData="$"
                            style={{ whiteSpace: 'noWrap' }}
                            variant="subtitle2"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <CommonErrorTooltip
                            data={circulating_supply_string}
                            isNumberAsString
                            suffixData={' ' + ticker_symbol}
                            variant="subtitle2"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Label
                              color={
                                Math.sign(
                                  price_change_percentage_24h_string
                                ) === 1
                                  ? 'success'
                                  : 'error'
                              }
                            >
                              <CommonErrorTooltip
                                data={price_change_percentage_24h_string}
                                isNumberAsString
                                suffixData="%"
                                style={{ whiteSpace: 'noWrap' }}
                                variant="subtitle2"
                              />
                            </Label>
                            {Math.sign(price_change_percentage_24h_string) ===
                            1 ? (
                              <ArrowUpwardWrapper
                                sx={{
                                  ml: 0.5,
                                  mr: -0.2
                                }}
                                fontSize="small"
                              />
                            ) : (
                              <ArrowDownwardWrapper
                                sx={{
                                  ml: 0.5,
                                  mr: -0.2
                                }}
                                fontSize="small"
                              />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Label
                              color={
                                Math.sign(price_change_percentage_7d_string) ===
                                1
                                  ? 'success'
                                  : 'error'
                              }
                            >
                              <CommonErrorTooltip
                                data={price_change_percentage_7d_string}
                                isNumberAsString
                                suffixData="%"
                                style={{ whiteSpace: 'noWrap' }}
                                variant="subtitle2"
                              />
                            </Label>
                            {Math.sign(price_change_percentage_7d_string) ===
                            1 ? (
                              <ArrowUpwardWrapper
                                sx={{
                                  ml: 0.5,
                                  mr: -0.2
                                }}
                                fontSize="small"
                              />
                            ) : (
                              <ArrowDownwardWrapper
                                sx={{
                                  ml: 0.5,
                                  mr: -0.2
                                }}
                                fontSize="small"
                              />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Typography noWrap>
                            <Tooltip title={t('View')} arrow>
                              <IconButton
                                component={RouterLink}
                                to={`/${
                                  location.pathname.split('/')[1]
                                }/${ticker_symbol}`}
                                color="primary"
                              >
                                <VisibilityTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            {!loading && error !== '' && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <Typography
                    sx={{
                      py: 2,
                      textAlign: { xs: 'center', md: 'left' },
                      mt: { xs: '15px', md: '20px' }
                    }}
                    variant="h4"
                    fontWeight="bold"
                  >
                    {t('Something went wrong :)')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!loading && error === '' && emptyRows === 0 && (
              <TableRow>
                <TableCell colSpan={8}>
                  <Typography
                    sx={{
                      py: 2,
                      textAlign: { xs: 'center', md: 'left' },
                      mt: { xs: '15px', md: '20px' }
                    }}
                    variant="h4"
                    fontWeight="bold"
                  >
                    {t('No Records Found :)')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 25]}
        component="div"
        count={tokens.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
