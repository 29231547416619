import React from 'react';
import _ from 'lodash';
import { useQuery } from '../../../../hooks/useQuery';
import { WalletScreen } from './AddressInfo';
import { WalletAddress } from './AddressRange';
import { RichList } from './RichList';
import { useDispatch } from '../../../../store';
import { getWalletAddressRange } from '../../../../slices/insiders';

const RenderData = () => {
  const query = useQuery();
  const dispatch = useDispatch();

  let balanceRange = {};
  if (query.get('from') !== null && query.get('to') !== null) {
    balanceRange = {
      from: query.get('from'),
      to: query.get('to')
    };
  }

  if (!_.isEmpty(balanceRange)) {
    dispatch(getWalletAddressRange(balanceRange));
  }

  if (query.get('view') === 'AddressRange') {
    return <WalletScreen />;
  } else if (query.get('view') === 'AddressInfo') {
    return <WalletAddress />;
  }
  return <RichList />;
};

const InsidersTab = () => {
  return <RenderData />;
};

export default InsidersTab;
