import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { TokenHeader } from './TokenHeader';
import TokenCandleStickChart from './TokenGraphStatistics';
import { TokenStatistics } from './TokenStatistics';
import { useSelector } from '../../../../store';

const CryptoTab = () => {
  const location = useLocation();
  const { tokenDetails } = useSelector((state) => state.crypto);

  const tickerSymbol = location.pathname.split('/')[2];
  const { loading, error, data } = tokenDetails;

  useEffect(() => {
    if (!loading && _.isEmpty(error)) {
      if (tickerSymbol !== data.ticker_symbol) {
        window.location.reload();
      }
    }
  }, [tickerSymbol, tokenDetails]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <TokenHeader />
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <TokenCandleStickChart />
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <TokenStatistics />
      </Grid>
    </Grid>
  );
};

export default CryptoTab;
