import React, { useEffect } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme, Box, Card, Divider, Typography } from '@mui/material';
import { UniqueAddressesChart } from './UniqueAddressChart';
import { RichAddressesCount } from './RichAddressesCount';
import { useDispatch, useSelector } from '../../../../../store';
import { getTokenDistribution } from '../../../../../slices/insiders';
import { GLOBALS } from '../../../../../globals/Globals';
import { TokenDistributionTable } from './TokenDistributionTable';

export const RichList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const tickerSymbol = location.pathname.split('/')[2];

  const dispatch = useDispatch();
  const { tokenDistribution } = useSelector((state) => state.insiders);
  const { loading, error, data } = tokenDistribution;

  useEffect(() => {
    dispatch(getTokenDistribution(tickerSymbol));
  }, []);

  const _render = () => {
    if (!loading && _.isEmpty(error) && _.isEmpty(data)) {
      return (
        <Typography
          sx={{
            py: 10
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {t("We couldn't find any data")}
        </Typography>
      );
    } else if (!loading && !_.isEmpty(error)) {
      return (
        <Box sx={{ textAlign: 'center', p: 2 }}>
          <Typography variant="h4">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    // render token-distribution table
    return <TokenDistributionTable />;
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
        <Card>
          <Box px={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: theme.colors.alpha.black[100] }}
              >
                {t('Wallet Address')}
              </Typography>
            </Box>
          </Box>
          <Divider />
          {/* Render Conditionally */}
          {_render()}
        </Card>

        {/* Rich List Table */}
        <RichAddressesCount />

        {/* Unique Addresses Chart */}
        <UniqueAddressesChart />
      </Box>
    </>
  );
};
