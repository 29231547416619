import { createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';

const initialState = {
  topHoldersExchange: {
    loading: true,
    error: '',
    data: []
  },
  tokenPrice: {
    loading: true,
    error: '',
    data: []
  },
  topTransactionWithinExchange: {
    loading: true,
    error: '',
    data: []
  },
  tokenOnExchanges: {
    loading: true,
    error: '',
    data: {}
  },
  topTransferBtwExchangeAndIndividualWallet: {
    loading: true,
    error: '',
    data: []
  },
  topTransferBtwExchange: {
    loading: true,
    error: '',
    data: []
  },
  addressDepositing: {
    loading: true,
    error: '',
    data: []
  }
};

const slice = createSlice({
  name: 'owner',
  initialState,
  reducers: {
    // Set Top Holder Exchange
    setTopHolderExchange(state, action) {
      state.topHoldersExchange.data = action.payload;
    },
    setTopHolderExchangeLoading(state, action) {
      state.topHoldersExchange.loading = action.payload;
    },
    setTopHolderExchangeError(state, action) {
      state.topHoldersExchange.error = action.payload;
    },

    // Set Token Price
    setTokenPrice(state, action) {
      state.tokenPrice.data = action.payload;
    },
    setTokenPriceError(state, action) {
      state.tokenPrice.error = action.payload;
    },
    setTokenPriceLoading(state, action) {
      state.tokenPrice.loading = action.payload;
    },

    // Set Token on Exchanges
    setTokenOnExchange(state, action) {
      state.tokenOnExchanges.data = action.payload;
    },
    setTokenOnExchangeError(state, action) {
      state.tokenOnExchanges.error = action.payload;
    },
    setTokenOnExchangeLoading(state, action) {
      state.tokenOnExchanges.loading = action.payload;
    },

    // Set Top Transaction within Exchange
    setTopTransactionWithinExchange(state, action) {
      state.topTransactionWithinExchange.data = action.payload;
    },
    setTopTransactionWithinExchangeError(state, action) {
      state.topTransactionWithinExchange.error = action.payload;
    },
    setTopTransactionWithinExchangeLoading(state, action) {
      state.topTransactionWithinExchange.loading = action.payload;
    },

    // Set Top Transfer Btw Exchange And IndividualWallet
    setTopTransferBtwExchangeAndIndividualWallet(state, action) {
      state.topTransferBtwExchangeAndIndividualWallet.data = action.payload;
    },
    setTopTransferBtwExchangeAndIndividualWalletError(state, action) {
      state.topTransferBtwExchangeAndIndividualWallet.error = action.payload;
    },
    setTopTransferBtwExchangeAndIndividualWalletLoading(state, action) {
      state.topTransferBtwExchangeAndIndividualWallet.loading = action.payload;
    },

    // Set Top Between Exchange
    setTopTransferBtwExchange(state, action) {
      state.topTransferBtwExchange.data = action.payload;
    },
    setTopTransferBtwExchangeError(state, action) {
      state.topTransferBtwExchange.error = action.payload;
    },
    setTopTransferBtwExchangeLoading(state, action) {
      state.topTransferBtwExchange.loading = action.payload;
    },

    // Set Address Deposting
    setAddressDepositing(state, action) {
      state.addressDepositing.data = action.payload;
    },
    setAddressDepositingError(state, action) {
      state.addressDepositing.error = action.payload;
    },
    setAddressDepositingLoading(state, action) {
      state.addressDepositing.loading = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getTopHolderExchange =
  (tickerSymbol, address) => async (dispatch) => {
    dispatch(slice.actions.setTopHolderExchangeLoading(true));
    try {
      const response = await axios.get(`/top-holders/${tickerSymbol}`);
      dispatch(slice.actions.setTopHolderExchange(response.data));
    } catch (err) {
      dispatch(slice.actions.setTopHolderExchangeError(err));
    }
    dispatch(slice.actions.setTopHolderExchangeLoading(false));
  };

export const getTokenPrice = (ticker_symbol, time) => async (dispatch) => {
  dispatch(slice.actions.setTokenPriceLoading(true));
  try {
    const response = await axios.get(
      `/exchanges/top-transfer/between-exchanges/individual-wallet/${ticker_symbol}/graph/${time}`
    );
    // const sortedData = response.data.slice().sort((a, b) =>  new Date(a.last_updated) - new Date(b.last_updated));
    dispatch(slice.actions.setTokenPrice(response.data));
  } catch (err) {
    dispatch(slice.actions.setTokenPriceError(err));
  }
  dispatch(slice.actions.setTokenPriceLoading(false));
};

export const getTokenOnExchange = (ticker_symbol, time) => async (dispatch) => {
  dispatch(slice.actions.setTokenOnExchangeLoading(true));
  try {
    const response = await axios.get(
      `/exchanges/${ticker_symbol}/top-transaction/within-exchange/graph/${time}`
    );
    dispatch(slice.actions.setTokenOnExchange(response.data));
  } catch (err) {
    dispatch(slice.actions.setTokenOnExchangeError(err));
  }
  dispatch(slice.actions.setTokenOnExchangeLoading(false));
};

export const getTopTransactionWithinExchange =
  (ticker_symbol, time) => async (dispatch) => {
    dispatch(slice.actions.setTopTransactionWithinExchangeLoading(true));
    try {
      const response = await axios.get(
        `/exchanges/${ticker_symbol}/top-transaction/within-exchange/${time}`
      );
      dispatch(slice.actions.setTopTransactionWithinExchange(response.data));
    } catch (err) {
      dispatch(slice.actions.setTopTransactionWithinExchangeError(err));
    }
    dispatch(slice.actions.setTopTransactionWithinExchangeLoading(false));
  };

export const getTopTransferBtwExchangeAndIndividualWallet =
  (ticker_symbol, time) => async (dispatch) => {
    dispatch(
      slice.actions.setTopTransferBtwExchangeAndIndividualWalletLoading(true)
    );
    try {
      const response = await axios.get(
        `/exchanges/top-transfer/between-exchanges/individual-wallet/${ticker_symbol}/${time}`
      );
      dispatch(
        slice.actions.setTopTransferBtwExchangeAndIndividualWallet(
          response.data
        )
      );
    } catch (err) {
      dispatch(
        slice.actions.setTopTransferBtwExchangeAndIndividualWalletError(err)
      );
    }
    dispatch(
      slice.actions.setTopTransferBtwExchangeAndIndividualWalletLoading(false)
    );
  };

export const getTopTransferBtwExchange =
  (ticker_symbol, time) => async (dispatch) => {
    dispatch(slice.actions.setTopTransferBtwExchangeLoading(true));
    try {
      const response = await axios.get(
        `/exchanges/top-transfer/between-exchanges/${ticker_symbol}/${time}`
      );
      dispatch(slice.actions.setTopTransferBtwExchange(response.data));
    } catch (err) {
      dispatch(slice.actions.setTopTransferBtwExchangeError(err));
    }
    dispatch(slice.actions.setTopTransferBtwExchangeLoading(false));
  };

export const getAddressDepositing =
  (ticker_symbol, time) => async (dispatch) => {
    dispatch(slice.actions.setAddressDepositingLoading(true));
    try {
      const response = await axios.get(
        `/exchanges/top-transfer/between-exchanges/${ticker_symbol}/graph/${time}`
      );
      dispatch(slice.actions.setAddressDepositing(response.data));
    } catch (err) {
      dispatch(slice.actions.setAddressDepositingError(err));
    }
    dispatch(slice.actions.setAddressDepositingLoading(false));
  };
