import React, { useEffect, useRef, useState } from 'react';
import { TopTransferBtwExchangeChart } from './TopTransferBtwExchangeChart';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem
} from '@mui/material';
import _ from 'lodash';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useDispatch, useSelector } from '../../../../../store';
import { getTopTransferBtwExchange } from '../../../../../slices/owner';
import { useLocation } from 'react-router';
import { GLOBALS } from '../../../../../globals/Globals';
import { TopTransferBtwExchnageTable } from './TopTransferBtwExchnageTable';

export const TopTransferBtwExchange = () => {
  const { t } = useTranslation();

  const periods = [
    {
      value: '1D',
      text: t('1D')
    },
    {
      value: '1W',
      text: t('1W')
    },
    {
      value: '2W',
      text: t('2W')
    },
    {
      value: '3W',
      text: t('3W')
    },
    {
      value: '1M',
      text: t('1M')
    }
  ];

  const location = useLocation();
  const tickerSymbol = location.pathname.split('/')[2];

  const [changeAction, setChangeAction] = useState(periods[1].text);
  const actionRef = useRef(null);
  const [
    openTopTransferBtwExchangeChange,
    setOpenTopTransferBtwExchangeChange
  ] = useState(false);
  const [topTransferBtwExchangeTime, setTopTransferBtwExchangeTime] =
    useState('1W');

  const handleTopTransferBtwExchangeChange = (value) => {
    setChangeAction(value);
    setOpenTopTransferBtwExchangeChange(false);
    if (value === '1D') {
      setTopTransferBtwExchangeTime(value);
    }
    if (value === '1W') {
      setTopTransferBtwExchangeTime(value);
    }
    if (value === '2W') {
      setTopTransferBtwExchangeTime(value);
    }
    if (value === '3W') {
      setTopTransferBtwExchangeTime(value);
    }
    if (value === '1M') {
      setTopTransferBtwExchangeTime(value);
    }
  };

  const dispatch = useDispatch();
  const { topTransferBtwExchange } = useSelector((state) => state.owner);
  const { loading, error, data } = topTransferBtwExchange;

  useEffect(() => {
    dispatch(
      getTopTransferBtwExchange(tickerSymbol, topTransferBtwExchangeTime)
    );
  }, [changeAction]);

  const _render = () => {
    if (!loading && _.isEmpty(error) && _.isEmpty(data)) {
      return (
        <Typography
          sx={{
            py: 10
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {t("We couldn't find any data")}
        </Typography>
      );
    } else if (!loading && !_.isEmpty(error)) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    // render table
    return <TopTransferBtwExchnageTable />;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sm={6}>
          <Card sx={{ minWidth: '140px', minHeight: '500px' }}>
            <Box px={2}>
              <Box px={2}>
                <Grid container display="flex" justifyContent="space-between">
                  <Grid item xs={12}>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Box
                        sx={{ display: 'flex', flexDirection: 'column', py: 2 }}
                      >
                        <Typography variant="h4" fontWeight="bold">
                          {t('Top Transfer')}
                        </Typography>
                        <Typography variant="subtitle">
                          ( {t('Between Exchange')} )
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'nowrap',
                          alignItems: 'center'
                        }}
                      >
                        <Box>
                          <Button
                            color="secondary"
                            ref={actionRef}
                            onClick={() =>
                              setOpenTopTransferBtwExchangeChange(true)
                            }
                            endIcon={<ExpandMoreTwoToneIcon />}
                            size="small"
                          >
                            {changeAction}
                          </Button>
                        </Box>
                        <Menu
                          disableScrollLock
                          anchorEl={actionRef.current}
                          onClose={() =>
                            setOpenTopTransferBtwExchangeChange(false)
                          }
                          open={openTopTransferBtwExchangeChange}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        >
                          {periods.map((_location) => (
                            <MenuItem
                              key={_location.value}
                              onClick={() =>
                                handleTopTransferBtwExchangeChange(
                                  _location.value
                                )
                              }
                            >
                              {_location.text}
                            </MenuItem>
                          ))}
                        </Menu>
                        {/* <Tooltip arrow title="Filter">
                          <IconButtonWrapper color="primary">
                            <FilterAltTwoToneIcon fontSize="small" />
                          </IconButtonWrapper>
                        </Tooltip> */}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Divider />
            {/* Render conditionally and handle errors */}
            {_render()}
          </Card>
        </Grid>
        {/* Top Transfer btw exchage chart */}
        <Grid item xs={12} md={8} sm={6}>
          <TopTransferBtwExchangeChart />
        </Grid>
      </Grid>
    </Box>
  );
};
