import React, { useEffect } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  useTheme,
  Box,
  Card,
  Divider,
  Typography,
  Tooltip,
  IconButton
} from '@mui/material';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { useDispatch, useSelector } from '../../../../../store';
import { getWalletAddress } from '../../../../../slices/insiders';
import { useQuery } from '../../../../../hooks/useQuery';
import { GLOBALS } from '../../../../../globals/Globals';
import { AddressRangeTable } from './AddressRangeTable';

export const WalletAddress = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const tickerSymbol = location.pathname.split('/')[2];

  const dispatch = useDispatch();
  const { walletAddress, walletAddressRange } = useSelector(
    (state) => state.insiders
  );
  const { loading, error, data } = walletAddress;

  let query = useQuery();
  const address = query.get('WalletAddress');

  useEffect(() => {
    dispatch(getWalletAddress(address));
  }, []);

  const _render = () => {
    if (!loading && _.isEmpty(error) && _.isEmpty(data)) {
      return (
        <Typography
          sx={{
            py: 10
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {t("We couldn't find any data")}
        </Typography>
      );
    } else if (!loading && !_.isEmpty(error)) {
      return (
        <Box sx={{ textAlign: 'center', p: 2 }}>
          <Typography variant="h4">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    // render address-range table
    return <AddressRangeTable />;
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
        <Card>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              p: 2
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Tooltip arrow placement="top" title={t('Go back')}>
                <IconButton
                  component={RouterLink}
                  to={`/crypto/${tickerSymbol}/insiders/?view=AddressRange&from=${walletAddressRange.data.from}&to=${walletAddressRange.data.to}`}
                  color="primary"
                  sx={{ mr: 2 }}
                >
                  <ArrowBackTwoToneIcon />
                </IconButton>
              </Tooltip>
              <Typography
                variant="h5"
                sx={{
                  color: theme.colors.alpha.black[100]
                }}
              >
                {t('Wallet Address: ')}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: theme.colors.alpha.black[70],
                  ml: 1,
                  display: { xs: 'none', sm: 'block' }
                }}
              >
                {t(address)}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: theme.colors.alpha.black[70],
                  ml: 1,
                  display: { xs: 'block', sm: 'none' }
                }}
              >
                {`${t(address).slice(0, 8)}...`}
              </Typography>
            </Box>
          </Box>
          <Divider />

          {/* Render Conditionally */}
          {_render()}
        </Card>

        {/* TopHoldersBalance Chart */}
        {/* <CostInformationChart /> */}
      </Box>
    </>
  );
};
