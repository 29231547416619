import React from 'react';
import { useTranslation } from 'react-i18next';
import MovingRoundedIcon from '@mui/icons-material/MovingRounded';
import {
  Button,
  Box,
  Skeleton,
  Typography,
  useTheme,
  Tooltip,
  styled,
  Divider
} from '@mui/material';
import { Label } from '../../../../components/Label';
import { customNumeral, getFriendlyAddress } from '../../../../utils/utils';
import { CommonErrorTooltip } from '../../../../components/CommonErrorTooltip';
import _ from 'lodash';
import { GLOBALS } from '../../../../globals/Globals';

const ChangeBoxWrapper = styled(Box)(
  ({ theme, value }) => `
  min-width: 100px;
  border: 1px solid ${
    Math.sign(value) === 1 ? theme.colors.success.main : theme.colors.error.main
  };
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  row-gap: 10px
`
);

export const TransactionComponent = ({
  loading,
  data,
  setSelectedTransactionIndex,
  selectedTransactionIndex,
  index,
  showReadMore
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const renderReadMore = () => {
    if (!showReadMore) return;
    return (
      <Box>
        {loading ? (
          <Skeleton animation="wave" width={60} />
        ) : (
          <Button
            size="small"
            color={selectedTransactionIndex === index ? 'error' : 'primary'}
            variant="outlined"
            onClick={() => {
              if (selectedTransactionIndex === index) {
                setSelectedTransactionIndex('');
              } else {
                setSelectedTransactionIndex(index);
              }
            }}
          >
            {selectedTransactionIndex === index
              ? t('Read less')
              : t('Read more')}
          </Button>
        )}
      </Box>
    );
  };

  const _renderChangeWithTime = ({
    timePeriod,
    amount,
    changePercentage,
    period
  }) => {
    if (_.isEmpty(timePeriod) && amount === '0' && changePercentage === '0') {
      return (
        <Box
          sx={{
            minWidth: 120,
            minHeight: 120,
            background: theme.sidebar.topSectionBackground,
            boxSizing: 'border-box',
            borderRadius: '5px',
            padding: '5px',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            rowGap: 1
          }}
        >
          <Box>
            <Typography variant="body2">{t(period)}</Typography>
          </Box>
        </Box>
      );
    }
    return (
      <ChangeBoxWrapper value={1}>
        <Box>
          <Label color={Math.sign(1) === 1 ? 'success' : 'error'}>
            <CommonErrorTooltip
              data={amount}
              isNumberAsString
              prefixData="$"
              style={{ whiteSpace: 'noWrap' }}
            />
          </Label>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 0.3 }}>
          <Label color="success">
            <MovingRoundedIcon
              fontSize="medium"
              color="success"
              sx={{
                transform: 'rotate(0deg)'
              }}
            />
            <CommonErrorTooltip
              data={changePercentage}
              isNumberAsString
              suffixData="%"
              style={{ whiteSpace: 'noWrap' }}
            />
          </Label>
        </Box>
        <Box>
          <Label color="success">
            <Typography variant="body2">{`${t(timePeriod)} Days`}</Typography>
          </Label>
        </Box>
      </ChangeBoxWrapper>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        rowGap: 2,
        columnGap: 2,
        mt: { xs: 2, md: 0 }
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end'
        }}
      >
        {loading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <Typography variant="body2" color="text.secondary" fontSize={12}>
            {t(new Date(data.block_timestamp).toDateString())}
          </Typography>
        )}
        <Box sx={{ mt: 1 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                columnGap: 2
              }}
            >
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {t('From')}
                </Typography>
                <Typography variant="body2" color="text.secondary" my={0.3}>
                  {t('To')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t('Amount')}
                </Typography>
              </Box>
              <Box>
                {loading ? (
                  <Skeleton animation="wave" width={150} />
                ) : (
                  <Tooltip title={t(data.from_address)} arrow placement="top">
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontWeight: 'bold' }}
                    >
                      <a
                        href={GLOBALS.NEWS_ETHERSCAN_URL.concat(
                          data.from_address
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        {getFriendlyAddress({ address: data.from_address })}
                      </a>
                    </Typography>
                  </Tooltip>
                )}
                {loading ? (
                  <Skeleton animation="wave" width={150} />
                ) : (
                  <Tooltip title={t(data.to_address)} arrow placement="top">
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      my={0.3}
                      sx={{ fontWeight: 'bold' }}
                    >
                      <a
                        href={GLOBALS.NEWS_ETHERSCAN_URL.concat(
                          data.to_address
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        {getFriendlyAddress({ address: data.to_address })}
                      </a>
                    </Typography>
                  </Tooltip>
                )}
                <Box sx={{ fontWeight: 'bold' }}>
                  {loading ? (
                    <Skeleton animation="wave" width={150} />
                  ) : (
                    <Tooltip arrow placement="top" title={data.value}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        my={0.3}
                        sx={{ fontWeight: 'bold', display: 'inline' }}
                      >
                        {t(`$ ${customNumeral({ value: data.value })}`)}
                      </Typography>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </Box>
            {renderReadMore()}
          </Box>
        </Box>
      </Box>
      <Divider
        orientation="vertical"
        variant="fullWidth"
        flexItem
        sx={{ color: '#E3E5E4', background: '#E3E5E4' }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'flex-start', md: 'flex-end' },
          columnGap: 1,
          order: { xs: 1, sm: 2 }
        }}
      >
        {loading ? (
          [...Array(3)].map((_, index) => {
            return (
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={100}
                height={110}
              />
            );
          })
        ) : (
          <>
            {_renderChangeWithTime({
              timePeriod: data.ChangeIn21D.change_time_frame,
              amount: data.ChangeIn21D.max_positive_change,
              changePercentage:
                data.ChangeIn21D.max_positive_change_in_percentage,
              period: '21 Days'
            })}
            {_renderChangeWithTime({
              timePeriod: data.ChangeIn3M.change_time_frame,
              amount: data.ChangeIn3M.max_positive_change,
              changePercentage:
                data.ChangeIn3M.max_positive_change_in_percentage,
              period: '3 Month'
            })}
            {_renderChangeWithTime({
              timePeriod: data.ChangeIn1Y.change_time_frame,
              amount: data.ChangeIn1Y.max_positive_change,
              changePercentage:
                data.ChangeIn1Y.max_positive_change_in_percentage,
              period: '1 Year'
            })}
          </>
        )}
      </Box>
    </Box>
  );
};
