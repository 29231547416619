import React from 'react';
import { Grid } from '@mui/material';
import { MarketCap } from './MarketCap';
import { FullyDilutedMarketCap } from './FullyDilutedMarketCap';
import { Volume } from './Volume';
import { CirculatingSupply } from './CirculatingSupply';

export const TokenHeader = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} lg={3}>
        <MarketCap />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <FullyDilutedMarketCap />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Volume />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <CirculatingSupply />
      </Grid>
    </Grid>
  );
};
