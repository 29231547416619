import React from 'react';
import { useTranslation } from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackBar = ({
  snackbarError,
  openSnackbar,
  handleSnackbarClose
}) => {
  const { t } = useTranslation();

  const renderSnackbar = () => {
    if (snackbarError == null) return '';
    else if (snackbarError) {
      return (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            {t('Something went wrong. Please try again later.')}
          </Alert>
        </Snackbar>
      );
    }
    return (
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t('You Successfully Registered.')}
        </Alert>
      </Snackbar>
    );
  };

  return renderSnackbar();
};
