import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  Container,
  styled,
  TextField,
  Grid,
  Autocomplete,
  Typography,
  Button
} from '@mui/material';

const MainContent = styled(Box)(
  () => `
      height: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-top: 20px;
  `
);

const TopWrapper = styled(Box)(
  () => `
    display: flex;
    width: 100%;
    flex: 1;
    padding: 20px;
  `
);

const TokenProfiler = () => {
  const { t } = useTranslation();

  const projectTags = [
    { title: 'USDT' },
    { title: 'ZRX' },
    { title: 'MATIC' },
    { title: 'SUSHI' }
  ];

  return (
    <Container maxWidth="xl">
      <MainContent>
        <TopWrapper>
          <Container maxWidth="sm">
            <Card
              sx={{
                mt: 0,
                px: 4,
                pt: 5,
                pb: 3
              }}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Token Profiler')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t('Fill in the fields below. It only takes a few minutes.')}
                </Typography>
              </Box>
              <Grid item xs={12} sm={6} md={12}>
                <Box p={1}>
                  <Autocomplete
                    sx={{
                      m: 0,
                      width: '100%'
                    }}
                    limitTags={2}
                    options={projectTags}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        label={t('Token')}
                        placeholder={t('Select Token')}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Button
                    sx={{
                      mt: 3
                    }}
                    color="primary"
                    size="large"
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    {t('Show Token Data')}
                  </Button>
                </Box>
              </Grid>
            </Card>
          </Container>
        </TopWrapper>
      </MainContent>
    </Container>
  );
};

export default TokenProfiler;
