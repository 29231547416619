import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Slider,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useDispatch, useSelector } from '../../../../../store';
import { useLocation } from 'react-router';
import { clearOHLC, getOHLC } from '../../../../../slices/crypto';
import { ChartWithFeatures } from '../../../../../components/CandleStick';
import { GLOBALS } from '../../../../../globals/Globals';

function TokenCandleStickChart() {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const tickerSymbol = location.pathname.split('/')[2];
  const [sliderValue, setSliderValue] = useState([0]);
  const [candleLevel, setCandleLevel] = useState([]);
  const [showMarker, setShowMarker] = useState(false);
  const ohlcRanges = [
    {
      value: '1D',
      text: t('1D')
    },
    {
      value: '5D',
      text: t('5D')
    },
    {
      value: '1M',
      text: t('1M')
    },
    {
      value: '3M',
      text: t('3M')
    },
    {
      value: '6M',
      text: t('6M')
    },
    {
      value: '1Y',
      text: t('1Y')
    }
  ];
  const [ohlcRange, setOhlcRange] = useState(ohlcRanges[4].value);
  const [buttonSelected, setButtonSelected] = useState('6M');
  const ohlcIntervalChanges = [
    {
      value: '1H',
      text: t('1H')
    },
    {
      value: '2H',
      text: t('2H')
    },
    {
      value: '3H',
      text: t('3H')
    },
    {
      value: '4H',
      text: t('4H')
    },
    {
      value: '1D',
      text: t('1D')
    },
    {
      value: '1W',
      text: t('1W')
    }
  ];
  const [ohlcPeriod, setOhlcPeriod] = useState('1D');
  const [ohlcIntervalChange, setOhlcIntervalChange] = useState(
    ohlcIntervalChanges[4].text
  );
  const [ohlcOffset, setOhlcOffset] = useState(0);
  const actionRef = useRef(null);
  const [openOHLCIntervalChange, setOpenOHLCIntervalChange] = useState(false);

  const { tokenDetails, ohlc } = useSelector((state) => state.crypto);

  const handleOhlcOffsetChange = () => {
    setOhlcOffset((prevOffset) => prevOffset + 1);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (tickerSymbol !== tokenDetails.data.ticker_symbol) {
        // TODO; clear the OHLC
        dispatch(clearOHLC());
      }
      if (!ohlc.loading || ohlc.data.length === 0) {
        dispatch(getOHLC(tickerSymbol, ohlcPeriod, ohlcRange, ohlcOffset));
      }
    }

    return () => {
      isMounted = false;
    };
  }, [ohlcRange, ohlcPeriod, ohlcOffset]);

  useEffect(() => {
    setOhlcOffset(0);
  }, [ohlcRange, ohlcPeriod]);

  const handleSliderChange = (event, newValue, activeThumb) => {
    setSliderValue([newValue]);
  };
  const applyCandleLevel = () => {
    setCandleLevel(sliderValue);
    setShowMarker(true);
  };

  const handleOHLCIntervalChange = (value) => {
    if (value === ohlcPeriod) return;

    // TODO; clear the OHLC
    dispatch(clearOHLC());

    setOhlcIntervalChange(value);
    setOpenOHLCIntervalChange(false);

    // TODO: set the max candle range of the OHLC chart (1Year as default)
    setOhlcRange(ohlcRanges[5].value);
    // Unselect the candle duration button
    setButtonSelected(null);

    if (value === '1H') {
      setOhlcPeriod(value);
    } else if (value === '2H') {
      setOhlcPeriod(value);
    } else if (value === '3H') {
      setOhlcPeriod(value);
    } else if (value === '4H') {
      setOhlcPeriod(value);
    } else if (value === '1D') {
      setOhlcPeriod(value);
    } else if (value === '1W') {
      setOhlcPeriod(value);
    }
  };

  const handleOhlcRange = (value) => {
    setButtonSelected(value);
    setOpenOHLCIntervalChange(false);

    if (value === buttonSelected) return;

    // TODO; clear the OHLC
    dispatch(clearOHLC());

    if (value === '1D') {
      setOhlcPeriod(ohlcIntervalChanges[0].value);
      setOhlcIntervalChange(ohlcIntervalChanges[0].value);
      setOhlcRange(ohlcRanges[5].value);
    } else if (value === '5D') {
      setOhlcPeriod(ohlcIntervalChanges[1].value);
      setOhlcIntervalChange(ohlcIntervalChanges[1].value);
      setOhlcRange(ohlcRanges[5].value);
    } else if (value === '1M') {
      setOhlcPeriod(ohlcIntervalChanges[2].value);
      setOhlcIntervalChange(ohlcIntervalChanges[2].value);
      setOhlcRange(ohlcRanges[5].value);
    } else if (value === '3M') {
      setOhlcPeriod(ohlcIntervalChanges[3].value);
      setOhlcIntervalChange(ohlcIntervalChanges[3].value);
      setOhlcRange(ohlcRanges[5].value);
    } else if (value === '6M') {
      setOhlcPeriod(ohlcIntervalChanges[4].value);
      setOhlcIntervalChange(ohlcIntervalChanges[4].value);
      setOhlcRange(ohlcRanges[5].value);
    } else if (value === '1Y') {
      setOhlcPeriod(ohlcIntervalChanges[5].value);
      setOhlcIntervalChange(ohlcIntervalChanges[5].value);
      setOhlcRange(ohlcRanges[5].value);
    }
  };

  const handleInputChange = (event) => {
    setSliderValue([event.target.value]);
  };

  const _renderChart = () => {
    if (ohlc.loading) {
      // if (ohlc.data.length !== 0) return;
      return (
        <Box
          sx={{
            textAlign: 'center'
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          height="60vh"
        >
          <CircularProgress />
        </Box>
      );
    } else if (ohlc.data.length === 0) {
      return (
        <Box
          sx={{
            textAlign: 'center'
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          height="60vh"
        >
          <Typography variant="h6">No Data available</Typography>
        </Box>
      );
    }
    return (
      <ChartWithFeatures
        loading={ohlc.loading}
        dataStream={ohlc.data}
        candleLevelsValue={candleLevel}
        showMarkers={showMarker}
        handleOhlcOffsetChange={handleOhlcOffsetChange}
      />
    );
  };

  return (
    <Card>
      <Grid maxWidth="xl" container justifyContent="flex-end">
        <Grid item xs={12} md={6} justifyContent="flex-end" width="100%">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
              my: 2
            }}
            width="90%"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                component="div"
                sx={{
                  fontSize: `${theme.typography.pxToRem(17)}`
                }}
                variant="h3"
              >
                {t('Select Candle level')}
              </Typography>
              <Tooltip title={GLOBALS.SELECT_CANDLE_LEVEL_MSG}>
                <IconButton>
                  <HelpOutlineTwoToneIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div
              style={{
                width: '100%',
                margin: '0px 5px'
              }}
            >
              <Slider
                min={0}
                step={1}
                max={100}
                marks
                getAriaValueText={() => 'Minimum Distance'}
                onChange={handleSliderChange}
                onChangeCommitted={handleSliderChange}
                valueLabelDisplay="auto"
                aria-labelledby="non-linear-slider"
                disabled={ohlcPeriod !== '1D'}
                sx={{
                  mx: 2
                }}
              />
            </div>
            <Box
              display="flex"
              justifyContent="space-between"
              alignContent="center"
              flexDirection="row"
              width="100%"
              paddingLeft="24px"
            >
              <TextField
                value={sliderValue[0]}
                size="small"
                onChange={handleInputChange}
                disabled={ohlcPeriod !== '1D'}
                inputProps={{
                  step: 0.1,
                  min: 0,
                  max: 100,
                  type: 'number',
                  'aria-labelledby': 'input-slider'
                }}
              />
              <Button
                variant="contained"
                size="small"
                onClick={applyCandleLevel}
                disabled={ohlcPeriod !== '1D'}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="start"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <Box
            sx={{
              textAlign: 'center'
            }}
          >
            <Typography variant="h3">
              {tokenDetails.loading && tokenDetails.error === '' ? (
                <Skeleton animation="wave" height={20} width="100px" />
              ) : (
                tokenDetails.data.ticker_symbol
              )}
            </Typography>
          </Box>
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Tooltip
                key={ohlcIntervalChange}
                arrow
                title={t(`${ohlcIntervalChange} Interval`)}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  ref={actionRef}
                  onClick={() => setOpenOHLCIntervalChange(true)}
                  endIcon={<ExpandMoreTwoToneIcon />}
                  size="small"
                >
                  {ohlcIntervalChange}
                </Button>
              </Tooltip>
            </Box>
            <Menu
              disableScrollLock
              anchorEl={actionRef.current}
              onClose={() => setOpenOHLCIntervalChange(false)}
              open={openOHLCIntervalChange}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              {ohlcIntervalChanges.map((_location) => (
                <MenuItem
                  key={_location.value}
                  onClick={() => handleOHLCIntervalChange(_location.value)}
                >
                  {_location.text}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Stack>

        {/* conditionally render */}
        {_renderChart()}

        <Divider />
        <Box
          display="flex"
          flex={1}
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <Box
            p={3}
            sx={{
              textAlign: 'left'
            }}
          >
            {ohlcRanges.map((ohlcRange) => {
              return (
                <Tooltip
                  key={ohlcRange.value}
                  arrow
                  title={t(`${ohlcRange.text} Change`)}
                >
                  <Button
                    variant={
                      buttonSelected === ohlcRange.value
                        ? 'contained'
                        : 'outlined'
                    }
                    color="primary"
                    size="small"
                    sx={{
                      ml: 1
                    }}
                    onClick={() => {
                      handleOhlcRange(ohlcRange.value);
                    }}
                  >
                    {ohlcRange.text}
                  </Button>
                </Tooltip>
              );
            })}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default TokenCandleStickChart;
