import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useDispatch, useSelector } from '../../../../../store';
import { getTokenPrice } from '../../../../../slices/owner';
import { useLocation } from 'react-router';
import { GLOBALS } from '../../../../../globals/Globals';
import { customNumeral } from '../../../../../utils/utils';

const periods = [
  {
    value: '1D',
    text: '1D'
  },
  {
    value: '1W',
    text: '1W'
  },
  {
    value: '2W',
    text: '2W'
  },
  {
    value: '3W',
    text: '3W'
  },
  {
    value: '1M',
    text: '1M'
  }
];

function RenderCharts(props) {
  const theme = useTheme();

  const [dateArray, setDateArray] = useState([]);
  const [priceArray, setPriceArray] = useState([]);
  const [totalVolArray, setTotalVolArray] = useState([]);

  const options = {
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
      offsetY: 20
    },
    theme: {
      mode: theme.palette.mode
    },
    series: [
      {
        name: 'Price',
        data: priceArray
      },
      {
        name: 'Volume',
        data: totalVolArray
      }
    ],
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [2, 2],
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '20%'
      }
    },
    xaxis: {
      categories: dateArray,
      type: 'datetime'
    },
    yaxis: [
      {
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: '#FF1654'
        },
        labels: {
          style: {
            colors: '#FF1654'
          },
          formatter(value) {
            if (typeof value !== 'undefined') {
              return customNumeral({ value });
            }
            return value;
          }
        },
        title: {
          text: 'Price (USD)',
          style: {
            color: '#FF1654'
          }
        }
      },
      {
        opposite: true,
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: '#247BA0'
        },
        labels: {
          formatter(value) {
            if (typeof value !== 'undefined') {
              return customNumeral({ value });
            }
            return value;
          }
        },
        title: {
          text: 'Volume',
          style: {
            color: '#247BA0'
          }
        }
      }
    ],
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    tooltip: {
      shared: true,
      y: {
        formatter(value) {
          if (typeof value !== 'undefined') {
            return value;
          }
          return value;
        }
      }
    },
    colors: ['#0D8BFF', '#F49200']
  };
  const [chart, setChart] = React.useState(options);
  useEffect(() => {
    const date = [];
    const price = [];
    const totalVol = [];
    _.map(props.data, (item, index) => {
      date.push(item.last_updated);
      price.push(item.current_price_usd);
      totalVol.push(item.total_volume_usd);
    });
    setDateArray(date);
    setPriceArray(price);
    setTotalVolArray(totalVol);
  }, [props.data]);

  useEffect(() => {
    setChart(options);
  }, [totalVolArray]);

  return (
    <Chart options={chart} series={chart.series} width="100%" height="85%" />
  );
}
export const TopTxnBtExAndInChart = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const tickerSymbol = location.pathname.split('/')[2];

  const dispatch = useDispatch();
  const { tokenPrice } = useSelector((state) => state.owner);

  const [changeAction, setChangeAction] = useState(periods[1].text);
  const actionRef = useRef(null);
  const [openTopTxnBtwExAndInChartChange, setOpenTopTxnBtwExAndInChange] =
    useState(false);
  const [topTxnBtwExAndInChartTime, setTopTxnBtwExAndInChartTime] =
    useState('1W');

  const handleTopTxnBtwExAndInChartChange = (value) => {
    setChangeAction(value);
    setOpenTopTxnBtwExAndInChange(false);
    if (value === '1D') {
      setTopTxnBtwExAndInChartTime(value);
    }
    if (value === '1W') {
      setTopTxnBtwExAndInChartTime(value);
    }
    if (value === '2W') {
      setTopTxnBtwExAndInChartTime(value);
    }
    if (value === '3W') {
      setTopTxnBtwExAndInChartTime(value);
    }
    if (value === '1M') {
      setTopTxnBtwExAndInChartTime(value);
    }
  };

  useEffect(() => {
    dispatch(getTokenPrice(tickerSymbol, topTxnBtwExAndInChartTime));
  }, [changeAction]);

  const { loading, error, data } = tokenPrice;

  const _render = () => {
    if (loading) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      );
    } else if (!loading && (!_.isEmpty(error) || error === undefined)) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return <RenderCharts data={data} />;
  };

  return (
    <>
      {/* TODO: fixed height */}
      <Box
        component={Paper}
        sx={{ height: { xs: '400px', sm: '100%', md: '100%' }, p: 2 }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4">{t('Token Price')}</Typography>
            <Box sx={{ p: 1, display: 'flex', cursor: 'pointer' }}>
              <Tooltip title={GLOBALS.OWNER_TOKEN_PRICE_GRAPH} arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </Box>
          </Box>
          <Box>
            <Button
              color="secondary"
              ref={actionRef}
              onClick={() => setOpenTopTxnBtwExAndInChange(true)}
              endIcon={<ExpandMoreTwoToneIcon />}
              size="small"
            >
              {changeAction}
            </Button>
          </Box>
          <Menu
            disableScrollLock
            anchorEl={actionRef.current}
            onClose={() => setOpenTopTxnBtwExAndInChange(false)}
            open={openTopTxnBtwExAndInChartChange}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {periods.map((_location) => (
              <MenuItem
                key={_location.value}
                onClick={() =>
                  handleTopTxnBtwExAndInChartChange(_location.value)
                }
              >
                {_location.text}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {_render()}
      </Box>
    </>
  );
};
