import React from 'react';
import { Container } from '@mui/material';
import { CryptoNavTabs } from './CryptoNavTabs';
import { CryptoTrackerHeader } from './CryptoTrackerHeader';

const CoinTracker = () => {
  return (
    <Container maxWidth="xl" spacing={4}>
      {/* render CryptoTrackerHeader component */}
      <CryptoTrackerHeader />

      {/* render Crypto Navigation tabs */}
      <CryptoNavTabs />
    </Container>
  );
};

export default CoinTracker;
