import React from 'react';
import { Box, Grid } from '@mui/material';
import { TokenPriceStatistics } from './TokenPriceStatistics';
import { TokenPriceHistory } from './TokenPriceHistory';

export const TokenStatistics = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container columnSpacing={{ md: 3 }} rowSpacing={{ xs: 3 }}>
        <Grid item xs={12} md={6}>
          <TokenPriceStatistics />
        </Grid>
        <Grid item xs={12} md={6}>
          <TokenPriceHistory />
        </Grid>
      </Grid>
    </Box>
  );
};
