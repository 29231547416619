import React, { useContext } from 'react';
import { Box, styled, IconButton } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from '../../context/SidebarContext';

const ContaineWrapper = styled(Box)(
  () => `
    display: flex;
    align-items: center;
  `
);

function Logo() {
  const { toggleSidebar } = useContext(SidebarContext);

  return (
    <ContaineWrapper>
      <Box>
        <IconButton
          aria-label="menu"
          color="inherit"
          sx={{ marginLeft: '10px', marginTop: '10px', color: '#1A75FF' }}
          onClick={toggleSidebar}
        >
          <MenuTwoToneIcon fontSize="small" />
        </IconButton>
      </Box>
    </ContaineWrapper>
  );
}

export default Logo;
