import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import _ from 'lodash';
import {
  Box,
  Typography,
  useTheme,
  Card,
  Skeleton,
  Button,
  Menu,
  MenuItem,
  Tooltip
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { Label } from '../../../../../components/Label';
import { ReactComponent as VolumeIcon } from '../../../../../assets/Volume.svg';
import { getVolume } from '../../../../../slices/crypto';
import { useDispatch, useSelector } from '../../../../../store';
import { GLOBALS } from '../../../../../globals/Globals';
import { CommonErrorTooltip } from '../../../../../components/CommonErrorTooltip';
import { nFormatter } from '../../../../../utils/utils';

export const Volume = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const tokenSymbol = location.pathname.split('/')[2];
  const { volume } = useSelector((state) => state.crypto);

  const volumeChanges = [
    {
      value: '1D',
      text: t('1D')
    },
    {
      value: '1W',
      text: t('1W')
    },
    {
      value: '2W',
      text: t('2W')
    },
    {
      value: '3W',
      text: t('3W')
    },
    {
      value: '1M',
      text: t('1M')
    }
  ];

  const [volumeChange, setVolumeChange] = useState(volumeChanges[1].text);
  const actionRef = useRef(null);
  const [openVolumeChange, setOpenVolumeChange] = useState(false);
  const [volumeTime, setVolumeTime] = useState('1D');

  useEffect(() => {
    dispatch(getVolume(tokenSymbol, volumeTime));
  }, [volumeTime, dispatch]);

  const { loading, error, data } = volume;

  const handleVolumeChange = (value) => {
    setVolumeChange(value);
    setOpenVolumeChange(false);
    if (value === '1D') {
      setVolumeTime(value);
    }
    if (value === '1M') {
      setVolumeTime(value);
    }
    if (value === '1W') {
      setVolumeTime(value);
    }
    if (value === '2W') {
      setVolumeTime(value);
    }
    if (value === '3W') {
      setVolumeTime(value);
    }
  };

  return (
    <Card
      sx={{
        px: 3,
        pb: 3,
        pt: 3,
        height: '100%'
      }}
    >
      <Box display="flex" alignItems="center">
        <VolumeIcon />
        <Typography
          sx={{
            ml: 1,
            fontSize: `${theme.typography.pxToRem(15)}`,
            fontWeight: 'bold'
          }}
          variant="subtitle2"
          component="div"
        >
          {t('Volume')}
        </Typography>
        <Box sx={{ p: 1, display: 'flex' }}>
          <Tooltip title={GLOBALS.CRYPTO_VOLUME_TRADED} arrow>
            <InfoOutlinedIcon fontSize="small" sx={{ cursor: 'pointer' }} />
          </Tooltip>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          ml: -2,
          pt: 2,
          pb: 1.5,
          justifyContent: 'center'
        }}
      >
        {volume.loading ? (
          <>
            <Skeleton
              animation="wave"
              height={20}
              width="200px"
              style={{ marginBottom: 6 }}
            />
          </>
        ) : (
          <Tooltip placement="top" arrow title={volume.data.currentVolumeInUsd}>
            <Typography
              variant="h4"
              sx={{
                pl: 1,
                whiteSpace: 'noWrap',
                fontSize: 18
              }}
            >
              {`$ ${nFormatter(volume.data.currentVolumeInUsd)}`}
            </Typography>
          </Tooltip>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {/* CustomSelector */}
        <Box mb={2} display="flex" alignItems="center">
          <Label
            color={
              Math.sign(volume.data.percentageDifferenceOfVolumeInUsd) === 1
                ? 'success'
                : 'error'
            }
          >
            <CommonErrorTooltip
              data={volume.data.percentageDifferenceOfVolumeInUsd}
              isNumberAsString
              suffixData="%"
              style={{ whiteSpace: 'noWrap' }}
            />
          </Label>
          {/* <Box>
              <Button
                color="secondary"
                ref={actionRef}
                onClick={() => setOpenVolumeChange(true)}
                endIcon={<ExpandMoreTwoToneIcon />}
                size={'small'}
              >
                {volumeChange}
              </Button>
            </Box>
            <Menu
              disableScrollLock
              anchorEl={actionRef.current}
              onClose={() => setOpenVolumeChange(false)}
              open={openVolumeChange}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              {volumeChanges.map((_location) => (
                <MenuItem
                  key={_location.value}
                  onClick={() => handleVolumeChange(_location.value)}
                >
                  {_location.text}
                </MenuItem>
              ))}
            </Menu> */}
        </Box>
        {/* <Typography */}
        {/*   sx={{ */}
        {/*     pl: 1, */}
        {/*     color: 'text.secondary', */}
        {/*     whiteSpace: 'nowrap' */}
        {/*   }} */}
        {/*   variant="h5" */}
        {/* > */}
        {/*  {_.toNumber(data.currentVolumeInUsd) / _.toNumber(marketCap.data.currentMarketCapInUsd) } */}
        {/*   /!*{t(Number(data.currentVolumeInUsd) / Number(marketCap.data.currentMarketCapInUsd))}*!/ */}
        {/*   <span style={{ fontSize: '10px' }}>{t('Market cap')}</span> */}
        {/* </Typography> */}
      </Box>
    </Card>
  );
};
