import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import _ from 'lodash';
import {
  Box,
  Card,
  Divider,
  Button,
  Grid,
  Typography,
  Menu,
  MenuItem
} from '@mui/material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { TopTxWithinExchangeChart } from './TopTxWithinExchangeChart';
import { useDispatch, useSelector } from '../../../../../store';
import { getTopTransactionWithinExchange } from '../../../../../slices/owner';
import { GLOBALS } from '../../../../../globals/Globals';
import { TopTxWithingExchangeTable } from './TopTxWithingExchangeTable';

export const TopTxnWithinExchange = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const tickerSymbol = location.pathname.split('/')[2];

  const periods = [
    {
      value: '1D',
      text: t('1D')
    },
    {
      value: '1W',
      text: t('1W')
    },
    {
      value: '2W',
      text: t('2W')
    },
    {
      value: '3W',
      text: t('3W')
    },
    {
      value: '1M',
      text: t('1M')
    }
  ];

  const [changeAction, setChangeAction] = useState(periods[1].value);
  const actionRef = useRef(null);
  const [openTopTxWithinExchangeChange, setOpenTopTxWithinExchangeChange] =
    useState(false);
  const [topTxWithinExchangeTime, setTopTxWithinExchangeTime] = useState('1W');

  const handleTopTxWithinExchangeChange = (value) => {
    setChangeAction(value);
    setOpenTopTxWithinExchangeChange(false);
    if (value === '1D') {
      setTopTxWithinExchangeTime(value);
    }
    if (value === '1W') {
      setTopTxWithinExchangeTime(value);
    }
    if (value === '2W') {
      setTopTxWithinExchangeTime(value);
    }
    if (value === '3W') {
      setTopTxWithinExchangeTime(value);
    }
    if (value === '1M') {
      setTopTxWithinExchangeTime(value);
    }
  };

  const { topTransactionWithinExchange } = useSelector((state) => state.owner);
  const { loading, error, data } = topTransactionWithinExchange;

  useEffect(() => {
    dispatch(
      getTopTransactionWithinExchange(tickerSymbol, topTxWithinExchangeTime)
    );
  }, [changeAction]);

  const _render = () => {
    if (!loading && _.isEmpty(error) && _.isEmpty(data)) {
      return (
        <Typography
          sx={{
            py: 10
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {t("We couldn't find any data")}
        </Typography>
      );
    } else if (!loading && !_.isEmpty(error)) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">{GLOBALS.ERROR_MSG}</Typography>
        </Box>
      );
    }
    // render table
    return <TopTxWithingExchangeTable />;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sm={6}>
          <Card sx={{ minWidth: '140px', minHeight: '500px' }}>
            <Box px={2}>
              <Box px={2}>
                <Grid container display="flex" justifyContent="space-between">
                  <Grid item xs={12}>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Box
                        sx={{ display: 'flex', flexDirection: 'column', py: 2 }}
                      >
                        <Typography variant="h4" fontWeight="bold">
                          {t('Top Transfer')}
                        </Typography>
                        <Typography variant="subtitle">
                          ( {t('Within Exchange')} )
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'nowrap',
                          alignItems: 'center'
                        }}
                      >
                        <Box>
                          <Button
                            color="secondary"
                            ref={actionRef}
                            onClick={() =>
                              setOpenTopTxWithinExchangeChange(true)
                            }
                            endIcon={<ExpandMoreTwoToneIcon />}
                            size="small"
                          >
                            {changeAction}
                          </Button>
                        </Box>
                        <Menu
                          disableScrollLock
                          anchorEl={actionRef.current}
                          onClose={() =>
                            setOpenTopTxWithinExchangeChange(false)
                          }
                          open={openTopTxWithinExchangeChange}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        >
                          {periods.map((_location) => (
                            <MenuItem
                              key={_location.value}
                              onClick={() =>
                                handleTopTxWithinExchangeChange(_location.value)
                              }
                            >
                              {_location.text}
                            </MenuItem>
                          ))}
                        </Menu>
                        {/* <Tooltip arrow title="Filter"> */}
                        {/*  <IconButtonWrapper color="primary"> */}
                        {/*    <FilterAltTwoToneIcon fontSize="small" /> */}
                        {/*  </IconButtonWrapper> */}
                        {/* </Tooltip> */}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Divider />
            {/* Render conditionally and handle errors */}
            {_render()}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2
              }}
            >
              <Typography
                sx={{
                  pl: 1,
                  color: 'text.secondary'
                }}
                variant="h5"
              >
                {t('Why these 3 exchanges?')}
              </Typography>
              <a
                href={GLOBALS.OWNER_WHY_THESE_EXCHANGE_KNOW_MORE}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {t('Know more')}
                </Button>
              </a>
            </Box>
          </Card>
        </Grid>
        {/* Top Txn withing exchange graph */}
        <Grid item xs={12} md={8} sm={6}>
          <TopTxWithinExchangeChart />
        </Grid>
      </Grid>
    </Box>
  );
};
