import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import useInView from 'react-cool-inview';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/system';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import {
  Button,
  Divider,
  Skeleton,
  Typography,
  useTheme,
  Card,
  Collapse
} from '@mui/material';
import Scrollbar from '../../../../components/Scrollbar';
import { getNews } from '../../../../slices/news';
import { useDispatch, useSelector } from '../../../../store';
import { GLOBALS } from '../../../../globals/Globals';
import { TransactionComponent } from './TransactionComponent';
import { NewsComponent } from './NewsComponent';
import { Label } from '../../../../components/Label';
import { NewsSkeleton } from '../../../../components/NewsSkeleton';

const LabelWrapper = styled(Box)(
  ({ theme }) => `
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      border-radius: ${theme.general.borderRadiusSm};
      padding: ${theme.spacing(1, 2)};
    `
);

const NewsTimeline = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectedTransactionIndex, setSelectedTransactionIndex] = useState('');
  const [selectedNewsIndex, setSelectedNewsIndex] = useState('');

  const dispatch = useDispatch();
  const location = useLocation();
  const tokenSymbol = location.pathname.split('/')[2];
  const { loading, error, news } = useSelector((state) => state.news);

  const [pageNumber, setPageNumber] = useState(GLOBALS.NEWS_OFFSET);

  const { observe } = useInView({
    // For better UX, we can grow the root margin so the data will be loaded earlier
    rootMargin: '50px 0px',
    // When the last item comes to the viewport
    onEnter: ({ unobserve }) => {
      // Load more data
      unobserve();
      setPageNumber((prevPageNumber) => prevPageNumber + 7);
    }
  });

  useEffect(() => {
    dispatch(getNews(tokenSymbol, GLOBALS.NEWS_LIMIT, pageNumber));
  }, [tokenSymbol, pageNumber]);

  const renderNewsTimeline = () => {
    if (error) {
      return (
        <Typography
          sx={{
            py: 10
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {t("We couldn't find any news")}
        </Typography>
      );
    }
    return _.map(news, (item, index) => {
      return (
        <TimelineItem
          key={item.date}
          sx={{
            p: 0,
            mb: 3
          }}
        >
          {loading ? (
            <Skeleton animation="wave" width={40} height={20} />
          ) : (
            <TimelineOppositeContent
              sx={{
                width: '76px',
                flex: 'none'
              }}
              color="text.secondary"
            >
              {t(new Date(item.date).toDateString())}
            </TimelineOppositeContent>
          )}
          <TimelineSeparator
            sx={{
              position: 'relative'
            }}
          >
            <TimelineDot
              sx={{
                marginTop: 0,
                top: theme.spacing(1.2)
              }}
              variant="outlined"
              color="primary"
            />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ ml: 2 }}>
            {!_.isEmpty(item.values.transaction) && (
              <Box component={Card} p={2}>
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: { xs: 'column', sm: 'row' },
                      alignItems: 'center',
                      rowGap: 2,
                      columnGap: 2,
                      my: 2
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Box>
                        {loading ? (
                          <Skeleton animation="wave" width={200} />
                        ) : (
                          <LabelWrapper
                            component="span"
                            sx={{
                              background: 'rgba(170, 92, 207, 0.15)',
                              color: '#AA5CCF'
                            }}
                          >
                            {t(
                              `Top ${item.values.transaction.length} transaction`
                            )}
                          </LabelWrapper>
                        )}
                      </Box>
                      <TransactionComponent
                        loading={loading}
                        data={item.values.transaction[0]}
                        setSelectedTransactionIndex={
                          setSelectedTransactionIndex
                        }
                        selectedTransactionIndex={selectedTransactionIndex}
                        index={index}
                        showReadMore
                      />
                    </Box>
                  </Box>
                </Box>
                <Collapse
                  in={index === selectedTransactionIndex}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ paddingLeft: { xs: 0.5, sm: 4 } }}>
                    {_.map(
                      item.values.transaction.slice(1),
                      (transaction, i) => {
                        return (
                          <Box key={i}>
                            <Divider />
                            <Box my={2}>
                              <TransactionComponent
                                loading={loading}
                                data={transaction}
                                setSelectedTransactionIndex={
                                  setSelectedTransactionIndex
                                }
                                selectedTransactionIndex={
                                  selectedTransactionIndex
                                }
                                index={0}
                                showReadMore={false}
                              />
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                </Collapse>
              </Box>
            )}
            {/* {!_.isEmpty(item.values.news) && (
              <Box component={Card} p={2} mt={1.5}>
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: { xs: 'column', sm: 'row' },
                      alignItems: 'center',
                      rowGap: 2,
                      columnGap: 2,
                      my: 2
                    }}
                  >
                    <Box sx={{ order: { xs: 2, sm: 1 }, flexGrow: 1 }}>
                      {loading ? (
                        <Skeleton animation="wave" />
                      ) : (
                        <Label
                          component="span"
                          color="primary"
                          sx={{ padding: '15px 20px' }}
                        >
                          {t(`${item.values.news.length} News Mentions`)}
                        </Label>
                      )}
                      <NewsComponent
                        loading={loading}
                        data={item.values.news[0]}
                        setSelectedNewsIndex={setSelectedNewsIndex}
                        selectedNewsIndex={selectedNewsIndex}
                        index={index}
                        showReadMore
                      />
                    </Box>
                  </Box>
                </Box>
                <Collapse
                  in={index === selectedNewsIndex}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ paddingLeft: { xs: 0.5, sm: 4 } }}>
                    {_.map(item.values.news.slice(1), (news, i) => {
                      return (
                        <Box key={i}>
                          <Divider />
                          <Box my={2}>
                            <NewsComponent
                              loading={loading}
                              data={news}
                              setSelectedNewsIndex={setSelectedNewsIndex}
                              selectedNewsIndex={selectedNewsIndex}
                              index={index}
                              showReadMore={false}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Collapse>
              </Box>
            )} */}
            <div ref={index === news.length - 1 ? observe : null} />
          </TimelineContent>
        </TimelineItem>
      );
    });
  };

  const _render = () => {
    return (
      <Box
        sx={{
          height: 720
        }}
      >
        <Scrollbar>
          <Timeline>
            {loading && error === '' && _.isEmpty(news)
              ? NewsSkeleton
              : renderNewsTimeline()}
          </Timeline>
        </Scrollbar>
      </Box>
    );
  };

  return _render();
};

export default NewsTimeline;
