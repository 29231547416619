import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { CommonErrorTooltip } from '../../../../../components/CommonErrorTooltip';
import { Label } from '../../../../../components/Label';
import { SkeletonLoad } from '../../../../../components/SkeletonLoad';
import {
  EnhancedTableHead,
  getComparator,
  stableSort
} from '../../../../../components/Table';
import { coinLogos } from '../../../../../utils/coinLogos';
import { getDateString } from '../../../../../utils/utils';
import { useSelector } from '../../../../../store';
import { GLOBALS } from '../../../../../globals/Globals';
import { ArrowUpwardWrapper } from '../../../../../components/StyledComponents/ArrowUpwardWrapper';
import { ArrowDownwardWrapper } from '../../../../../components/StyledComponents/ArrowDownwardWrapper';

const headCells = [
  {
    id: 'name',
    numeric: false,
    label: 'Name',
    showArrow: true,
    align: 'center',
    isSticky: true,
    left: 0
  },
  {
    id: 'balance_number',
    numeric: true,
    label: 'Balance',
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'ownership_number',
    numeric: true,
    label: '% Ownership',
    infoTooltip: true,
    infoMsg: GLOBALS.OWNER_TOP_HOLDERS_OWNERSHIP,
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'received_number',
    numeric: false,
    label: 'Received',
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'sent_number',
    numeric: true,
    label: 'Sent',
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'net_change_1d_number',
    numericL: true,
    label: 'Net Change (1D)',
    infoTooltip: true,
    infoMsg: GLOBALS.OWNER_TOP_HOLDERS_NET_CHANGE_1D,
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'net_change_1w_number',
    numericL: true,
    label: 'Net Change (1W)',
    infoTooltip: true,
    infoMsg: GLOBALS.OWNER_TOP_HOLDERS_NET_CHANGE_1W,
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'net_change_2w_number',
    numericL: true,
    label: 'Net Change (2W)',
    infoTooltip: true,
    infoMsg: GLOBALS.OWNER_TOP_HOLDERS_NET_CHANGE_2W,
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'net_change_3w_number',
    numericL: true,
    label: 'Net Change (3W)',
    infoTooltip: true,
    infoMsg: GLOBALS.OWNER_TOP_HOLDERS_NET_CHANGE_3W,
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'net_change_1m_number',
    numericL: true,
    label: 'Net Change (1M)',
    infoTooltip: true,
    infoMsg: GLOBALS.OWNER_TOP_HOLDERS_NET_CHANGE_1M,
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'firstIn',
    numeric: false,
    label: 'First In',
    showArrow: false,
    align: 'center',
    isSticky: false
  }
];

export const TopHoldersTable = () => {
  const { t } = useTranslation();

  const { topHoldersExchange } = useSelector((state) => state.owner);
  const { loading, error, data } = topHoldersExchange;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const [selected, setSelected] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
          stickyHeader
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headCells={headCells}
          />
          <TableBody>
            {loading && _.isEmpty(error) ? (
              <SkeletonLoad rows={3} cols={11} />
            ) : (
              stableSort(
                data.map((item) => {
                  return {
                    ...item,
                    balance_number: _.toNumber(item.balance),
                    balance_string: String(item.balance),
                    name: String(item.name),
                    net_change_1d_number: _.toNumber(item.net_change_1d),
                    net_change_1d_string: String(item.net_change_1d),
                    net_change_1m_number: _.toNumber(item.net_change_1m),
                    net_change_1m_string: String(item.net_change_1m),
                    net_change_1w_number: _.toNumber(item.net_change_1w),
                    net_change_1w_string: String(item.net_change_1w),
                    net_change_3w_number: _.toNumber(item.net_change_3w),
                    net_change_3w_string: String(item.net_change_3w),
                    net_change_2w_number: _.toNumber(item.net_change_2w),
                    net_change_2w_sting: String(item.net_change_2w),
                    ownership_number: _.toNumber(item.ownership),
                    ownership_string: String(item.ownership),
                    received_number: _.toNumber(item.received),
                    received_string: String(item.received),
                    sent_number: _.toNumber(item.sent),
                    sent_string: String(item.sent)
                  };
                }),
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const {
                    balance_string,
                    firstIn,
                    name,
                    net_change_1d_string,
                    net_change_1m_string,
                    net_change_1w_string,
                    net_change_2w_sting,
                    net_change_3w_string,
                    ownership_string,
                    received_string,
                    sent_string
                  } = row;
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0
                        }
                      }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          position: 'sticky',
                          left: 0,
                          background: (theme) => theme.sidebar.background
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <Avatar
                            sx={{
                              mr: 1,
                              background: (theme) =>
                                theme.colors.alpha.trueWhite[100]
                            }}
                            src={coinLogos[name.toUpperCase()]}
                            alt={name.toUpperCase()}
                          />
                          <Box>
                            <CommonErrorTooltip
                              data={name.toUpperCase()}
                              isText
                              variant="h5"
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <CommonErrorTooltip
                          data={balance_string}
                          style={{ whiteSpace: 'nowrap' }}
                          isNumberAsString
                        />
                      </TableCell>
                      <TableCell align="center">
                        <CommonErrorTooltip
                          data={ownership_string}
                          style={{ whiteSpace: 'nowrap' }}
                          isNumberAsString
                          suffixData="%"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <CommonErrorTooltip
                          data={received_string}
                          style={{ whiteSpace: 'nowrap' }}
                          isNumberAsString
                        />
                      </TableCell>
                      <TableCell align="center">
                        <CommonErrorTooltip
                          data={sent_string}
                          style={{ whiteSpace: 'nowrap' }}
                          isNumberAsString
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Label
                            color={
                              net_change_1d_string > 0 ? 'success' : 'error'
                            }
                          >
                            <CommonErrorTooltip
                              data={net_change_1d_string}
                              style={{ whiteSpace: 'nowrap' }}
                              isNumberAsString
                              suffixData="%"
                            />
                          </Label>
                          {net_change_1d_string > 0 ? (
                            <ArrowUpwardWrapper
                              sx={{
                                ml: 0.5,
                                mr: -0.2
                              }}
                              fontSize="small"
                            />
                          ) : (
                            <ArrowDownwardWrapper
                              sx={{
                                ml: 0.5,
                                mr: -0.2
                              }}
                              fontSize="small"
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Label
                            color={
                              net_change_1w_string > 0 ? 'success' : 'error'
                            }
                          >
                            <CommonErrorTooltip
                              data={net_change_1w_string}
                              style={{ whiteSpace: 'nowrap' }}
                              isNumberAsString
                              suffixData="%"
                            />
                          </Label>
                          {net_change_1w_string > 0 ? (
                            <ArrowUpwardWrapper
                              sx={{
                                ml: 0.5,
                                mr: -0.2
                              }}
                              fontSize="small"
                            />
                          ) : (
                            <ArrowDownwardWrapper
                              sx={{
                                ml: 0.5,
                                mr: -0.2
                              }}
                              fontSize="small"
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Label
                            color={
                              net_change_2w_sting > 0 ? 'success' : 'error'
                            }
                          >
                            <CommonErrorTooltip
                              data={net_change_2w_sting}
                              style={{ whiteSpace: 'nowrap' }}
                              isNumberAsString
                              suffixData="%"
                            />
                          </Label>
                          {net_change_2w_sting > 0 ? (
                            <ArrowUpwardWrapper
                              sx={{
                                ml: 0.5,
                                mr: -0.2
                              }}
                              fontSize="small"
                            />
                          ) : (
                            <ArrowDownwardWrapper
                              sx={{
                                ml: 0.5,
                                mr: -0.2
                              }}
                              fontSize="small"
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Label
                            color={
                              net_change_3w_string > 0 ? 'success' : 'error'
                            }
                          >
                            <CommonErrorTooltip
                              data={net_change_3w_string}
                              style={{ whiteSpace: 'nowrap' }}
                              isNumberAsString
                              suffixData="%"
                            />
                          </Label>
                          {net_change_3w_string > 0 ? (
                            <ArrowUpwardWrapper
                              sx={{
                                ml: 0.5,
                                mr: -0.2
                              }}
                              fontSize="small"
                            />
                          ) : (
                            <ArrowDownwardWrapper
                              sx={{
                                ml: 0.5,
                                mr: -0.2
                              }}
                              fontSize="small"
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Label
                            color={
                              net_change_1m_string > 0 ? 'success' : 'error'
                            }
                          >
                            <CommonErrorTooltip
                              data={net_change_1m_string}
                              style={{ whiteSpace: 'nowrap' }}
                              isNumberAsString
                              suffixData="%"
                            />
                          </Label>
                          {net_change_1m_string > 0 ? (
                            <ArrowUpwardWrapper
                              sx={{
                                ml: 0.5,
                                mr: -0.2
                              }}
                              fontSize="small"
                            />
                          ) : (
                            <ArrowDownwardWrapper
                              sx={{
                                ml: 0.5,
                                mr: -0.2
                              }}
                              fontSize="small"
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <CommonErrorTooltip
                          data={getDateString(firstIn)}
                          isFirstInDate
                          style={{ whiteSpace: 'nowrap' }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
