import React, { useContext } from 'react';
import { Box, Drawer, styled, Divider, useTheme } from '@mui/material';
import Scrollbar from '../Scrollbar';
import Logo from '../LogoSign';
import { SidebarContext } from '../../context/SidebarContext';
import { SidebarMenu } from './SidebarMenu';
import { SidebarFooter } from './SidebarFooter';
import { SidebarTopSection } from './SidebarTopSection';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 98%;
        padding-bottom: 61px;
`
);

export const Sidebar = () => {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <Drawer
      sx={{
        boxShadow: `${theme.sidebar.boxShadow}`
      }}
      anchor={theme.direction === 'rtl' ? 'right' : 'left'}
      open={sidebarToggle}
      onClose={closeSidebar}
      variant="temporary"
      elevation={9}
    >
      <SidebarWrapper
        sx={{
          background: theme.sidebar.background
        }}
      >
        <Scrollbar>
          <Box mt={3}>
            <Box
              mx={2}
              sx={{
                width: 52
              }}
            >
              <Logo />
            </Box>
          </Box>
          <Divider
            sx={{
              my: theme.spacing(1),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10]
            }}
          />
          <SidebarTopSection />
          <Divider
            sx={{
              my: theme.spacing(1),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10]
            }}
          />
          <SidebarMenu />
        </Scrollbar>
        <SidebarFooter />
      </SidebarWrapper>
    </Drawer>
  );
};
