import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, Card, Skeleton } from '@mui/material';
import React from 'react';
import { NewsComponent } from '../../pages/CryptoTracker/CryptoNavTabs/News/NewsComponent';
import { TransactionComponent } from '../../pages/CryptoTracker/CryptoNavTabs/News/TransactionComponent';

const NewsSkeleton = (
  <Box sx={{ ml: 2 }}>
    {[...Array(2)].map((_, index) => (
      <TimelineItem
        key={index}
        sx={{
          p: 0,
          mb: 3
        }}
      >
        <TimelineOppositeContent
          sx={{
            width: '85px',
            flex: 'none'
          }}
          color="text.secondary"
        >
          <Skeleton animation="wave" width={40} height={20} />
        </TimelineOppositeContent>
        <TimelineSeparator
          sx={{
            position: 'relative'
          }}
        >
          <TimelineDot
            sx={{
              marginTop: 0,
              top: '12px'
            }}
            variant="outlined"
            color="primary"
          />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ ml: 2 }}>
          <Box component={Card} p={2}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: 'center',
                  rowGap: 2,
                  columnGap: 2,
                  my: 2
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Skeleton variant="text" width={200} animation="wave" />
                  <TransactionComponent loading data={{}} />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box component={Card} p={2} mt={1.5}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: 'center',
                  rowGap: 2,
                  columnGap: 2
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Skeleton variant="text" width={200} animation="wave" />
                  <NewsComponent loading data={{}} />
                </Box>
              </Box>
            </Box>
          </Box>
        </TimelineContent>
      </TimelineItem>
    ))}
  </Box>
);

export { NewsSkeleton };
