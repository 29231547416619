import React, { useState } from 'react';
import _ from 'lodash';
import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import MovingRoundedIcon from '@mui/icons-material/MovingRounded';
import { CommonErrorTooltip } from '../../../../../components/CommonErrorTooltip';
import { SkeletonLoad } from '../../../../../components/SkeletonLoad';
import {
  EnhancedTableHead,
  getComparator,
  stableSort
} from '../../../../../components/Table';
import Text from '../../../../../components/Text';
import { GLOBALS } from '../../../../../globals/Globals';
import { useSelector } from '../../../../../store';
import { coinLogos } from '../../../../../utils/coinLogos';

const headCells = [
  {
    id: 'name',
    numeric: false,
    label: 'Exchange',
    showArrow: true,
    align: 'left',
    isSticky: true,
    left: 0
  },
  {
    id: 'net_change_number',
    numeric: true,
    label: 'Net Change',
    infoTooltip: true,
    infoMsg: GLOBALS.OWNER_TOP_TRANSFER_BETWEEN_EXCHAGNE_NET_CHANGE,
    showArrow: true,
    align: 'left',
    disablePadding: true,
    isSticky: false
  }
];

export const TopTransferBtwExchnageTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);

  const { topTransferBtwExchange } = useSelector((state) => state.owner);
  const { loading, error, data } = topTransferBtwExchange;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="medium" stickyHeader>
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headCells={headCells}
          />
          <TableBody>
            {loading && error === '' ? (
              <SkeletonLoad rows={3} cols={2} />
            ) : (
              stableSort(
                data.map((item) => {
                  return {
                    ...item,
                    name: String(item.name),
                    net_change_number: _.toNumber(item.net_change),
                    net_change_string: String(item.net_change),
                    net_percentage_change_string: String(
                      item.net_percentage_change
                    ),
                    volume: String(item.volume)
                  };
                }),
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((token, index) => {
                  const {
                    name,
                    net_change_string,
                    net_percentage_change_string
                  } = token;
                  return (
                    <TableRow hover key={token.name}>
                      <TableCell
                        align="left"
                        sx={{
                          position: 'sticky',
                          left: 0,
                          background: (theme) => theme.sidebar.background,
                          zIndex: 10
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Avatar
                            sx={{
                              mr: 1,
                              background: (theme) =>
                                theme.colors.alpha.trueWhite[100]
                            }}
                            src={coinLogos[name.toUpperCase()]}
                            alt={name}
                          />
                          <Box>
                            <Typography variant="h5">
                              <CommonErrorTooltip
                                data={name.toUpperCase()}
                                isText
                                style={{ whiteSpace: 'nowrap' }}
                              />
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell align="center" padding="none">
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start'
                          }}
                        >
                          <MovingRoundedIcon
                            fontSize="medium"
                            color={
                              Math.sign(net_change_string) === 1
                                ? 'success'
                                : 'error'
                            }
                            sx={{
                              transform: `${
                                Math.sign(net_change_string) === 1
                                  ? 'rotate(0deg)'
                                  : 'rotate(72deg)'
                              }`
                            }}
                          />
                          <Text
                            color={
                              Math.sign(net_change_string) === 1
                                ? 'success'
                                : 'error'
                            }
                            sx={{ display: 'flex', alignItems: 'center' }}
                          >
                            <CommonErrorTooltip
                              data={net_change_string}
                              style={{ whiteSpace: 'nowrap' }}
                              isNumberAsString
                            />
                            &nbsp;(
                            <CommonErrorTooltip
                              data={net_percentage_change_string}
                              style={{ whiteSpace: 'nowrap' }}
                              isNumberAsString
                              suffixData="%"
                            />
                            )
                          </Text>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
