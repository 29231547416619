import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import {
  alpha,
  Avatar,
  Box,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { CommonErrorTooltip } from '../../components/CommonErrorTooltip';
import { Label } from '../../components/Label';
import Text from '../../components/Text';
import { GLOBALS } from '../../globals/Globals';
import { coinLogos } from '../../utils/coinLogos';
import { useDispatch, useSelector } from '../../store';
import {
  getTokenDetails,
  getBasicTokenDetails,
  getTokenPrice,
  getTokenPriceHistory
} from '../../slices/crypto';

export const CryptoTrackerHeader = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const tickerSymbol = location.pathname.split('/')[2];

  const { basicTokenDetails, tokenPrice } = useSelector(
    (state) => state.crypto
  );
  const { name, ticker_symbol } = basicTokenDetails.data;

  const [marketCapTime, setMarketCapTime] = useState('1D');

  useEffect(() => {
    if (ticker_symbol !== tickerSymbol) {
      dispatch(getTokenDetails(tickerSymbol));
      dispatch(getTokenPrice(tickerSymbol, marketCapTime, true));
      dispatch(getTokenPriceHistory(tickerSymbol));
      dispatch(getBasicTokenDetails(tickerSymbol));
    }
  }, []);

  return (
    <Box
      sx={{
        display: { xs: 'block', sm: 'flex' },
        alignItems: 'center',
        justifyContent: 'space-between',
        mr: { xs: 1, sm: 2 }
      }}
    >
      <Box>
        <Tooltip arrow placement="top" title={t('Go back')}>
          <IconButton
            component={RouterLink}
            to={GLOBALS.HOME_ROUTE}
            color="primary"
            sx={{ mr: 2 }}
          >
            <ArrowBackTwoToneIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          my: 3,
          flexGrow: 1
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              p: 0.3,
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mr: 0.5,
              background:
                theme.palette.mode === 'dark'
                  ? theme.colors.alpha.trueWhite[30]
                  : alpha(theme.colors.alpha.black[100], 0.07)
            }}
          >
            {basicTokenDetails.loading ? (
              <Skeleton
                animation="wave"
                variant="circular"
                width={60}
                height={60}
              />
            ) : (
              <Avatar
                alt="BTC"
                src={coinLogos[ticker_symbol]}
                sx={{
                  height: { xs: theme.spacing(5.5), sm: theme.spacing(8) },
                  width: { xs: theme.spacing(5.5), sm: theme.spacing(8) },
                  padding: theme.spacing(0.5),
                  background: theme.colors.alpha.trueWhite[100]
                }}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {basicTokenDetails.loading ? (
              <>
                <Skeleton
                  animation="wave"
                  height={20}
                  width="150px"
                  style={{ marginBottom: 6 }}
                />
                <Skeleton animation="wave" height={20} width="100px" />
              </>
            ) : (
              <>
                <Typography
                  variant="h1"
                  sx={{
                    color:
                      theme.palette.mode === 'dark'
                        ? theme.colors.alpha.trueWhite[100]
                        : theme.colors.alpha.black[100],
                    fontSize: { xs: '18px', sm: '30px' }
                  }}
                >
                  {t(name)}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontSize: { xs: '15px', sm: '20px' } }}
                >
                  {t(ticker_symbol)}
                </Typography>
              </>
            )}
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            {tokenPrice.loading ? (
              <Skeleton
                animation="wave"
                height={20}
                width="200px"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <>
                <CommonErrorTooltip
                  data={tokenPrice.last24h.closePriceInUsd}
                  isNumberAsString
                  isShortFlag
                  prefixData="$"
                  style={{
                    pr: 1,
                    mt: { xs: 0.5, sm: '-4px' },
                    whiteSpace: 'noWrap',
                    color:
                      theme.palette.mode === 'dark'
                        ? theme.colors.alpha.trueWhite[100]
                        : theme.colors.alpha.black[100],
                    fontSize: { xs: '18px', sm: '30px' },
                    fontWeight: 'bold'
                  }}
                />
                <Text
                  color={
                    Math.sign(
                      tokenPrice.last24h
                        .percentageDifferenceOfOpenAndClosePriceInUsd
                    ) === 1
                      ? 'success'
                      : 'error'
                  }
                  sx={{ marginTop: { xs: '5px' } }}
                >
                  <b>
                    <CommonErrorTooltip
                      data={
                        tokenPrice.last24h
                          .percentageDifferenceOfOpenAndClosePriceInUsd
                      }
                      isNumberAsString
                      suffixData="%"
                      style={{
                        whiteSpace: 'noWrap'
                      }}
                    />
                  </b>
                </Text>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            {tokenPrice.loading ? (
              <>
                <Skeleton animation="wave" height={20} width="150px" />
              </>
            ) : (
              <>
                <Label
                  color={
                    Math.sign(
                      tokenPrice.last24h.absoluteChangeInOpenAndCloseInUsd
                    ) === 1
                      ? 'success'
                      : 'error'
                  }
                >
                  <CommonErrorTooltip
                    data={tokenPrice.last24h.absoluteChangeInOpenAndCloseInUsd}
                    isNumberAsString
                    isShortFlag
                    prefixData="$"
                    style={{
                      whiteSpace: 'noWrap'
                    }}
                  />
                </Label>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    pl: 1
                  }}
                >
                  {t('last 24h')}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
