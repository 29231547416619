import INCH1 from '../assets/Tokens/1INCH.png';
import AAVE from '../assets/Tokens/AAVE.png';
import ALICE from '../assets/Tokens/ALICE.png';
import AMP from '../assets/Tokens/AMP.png';
import ANKR from '../assets/Tokens/ANKR.png';
import AXS from '../assets/Tokens/AXS.png';
import BAT from '../assets/Tokens/BAT.png';
import BNB from '../assets/Tokens/BNB.png';
import BNT from '../assets/Tokens/BNT.png';
import BTC from '../assets/Tokens/BTC.png';
import BUSD from '../assets/Tokens/BUSD.png';
import CELR from '../assets/Tokens/CELR.png';
import CHZ from '../assets/Tokens/CHZ.png';
import COMP from '../assets/Tokens/COMP.png';
import COIN from '../assets/Tokens/COIN.png';
import CRO from '../assets/Tokens/CRO.png';
import CRV from '../assets/Tokens/CRV.png';
import CTSI from '../assets/Tokens/CTSI.png';
import CTXC from '../assets/Tokens/CTXC.png';
import DAI from '../assets/Tokens/DAI.png';
import DENT from '../assets/Tokens/DENT.png';
import DYDX from '../assets/Tokens/DYDX.png';
import ENJ from '../assets/Tokens/ENJ.png';
import ENS from '../assets/Tokens/ENS.png';
import ETH from '../assets/Tokens/ETH.png';
import FET from '../assets/Tokens/FET.png';
import FTM from '../assets/Tokens/FTM.png';
import FTT from '../assets/Tokens/FTT.png';
import FTX from '../assets/Tokens/FTX.png';
import GALA from '../assets/Tokens/GALA.png';
import GLM from '../assets/Tokens/GLM.png';
import GRT from '../assets/Tokens/GRT.png';
import HOT from '../assets/Tokens/HOT.png';
import HT from '../assets/Tokens/HT.png';
import INJ from '../assets/Tokens/INJ.png';
import JASMY from '../assets/Tokens/JASMY.png';
import KCS from '../assets/Tokens/KCS.png';
import KEEP from '../assets/Tokens/KEEP.png';
import LINK from '../assets/Tokens/LINK.png';
import LPT from '../assets/Tokens/LPT.png';
import LRC from '../assets/Tokens/LRC.png';
import LUNA from '../assets/Tokens/LUNA.png';
import MANA from '../assets/Tokens/MANA.png';
import MATIC from '../assets/Tokens/MATIC.png';
import MKR from '../assets/Tokens/MKR.png';
import NU from '../assets/Tokens/NU.png';
import NULS from '../assets/Tokens/NULS.png';
import OKB from '../assets/Tokens/OKB.png';
import OMG from '../assets/Tokens/OMG.png';
import ONE from '../assets/Tokens/ONE.png';
import PERP from '../assets/Tokens/PERP.png';
import PLA from '../assets/Tokens/PLA.png';
import POLY from '../assets/Tokens/POLY.png';
import QNT from '../assets/Tokens/QNT.png';
import REN from '../assets/Tokens/REN.png';
import REQ from '../assets/Tokens/REQ.png';
import RNDR from '../assets/Tokens/RNDR.png';
import RSR from '../assets/Tokens/RSR.png';
import RUNE from '../assets/Tokens/RUNE.png';
import SAND from '../assets/Tokens/SAND.png';
import SHIB from '../assets/Tokens/SHIB.png';
import SKL from '../assets/Tokens/SKL.png';
import SNX from '../assets/Tokens/SNX.png';
import STORJ from '../assets/Tokens/STORJ.png';
import SUSHI from '../assets/Tokens/SUSHI.png';
import TRIBE from '../assets/Tokens/TRIBE.png';
import TRX from '../assets/Tokens/TRX.png';
import TUSD from '../assets/Tokens/TUSD.png';
import UMA from '../assets/Tokens/UMA.png';
import UNI from '../assets/Tokens/UNI.png';
import USDC from '../assets/Tokens/USDC.png';
import USDP from '../assets/Tokens/USDP.png';
import USDT from '../assets/Tokens/USDT.png';
import VGX from '../assets/Tokens/VGX.png';
import WAVES from '../assets/Tokens/WAVES.png';
import WBTC from '../assets/Tokens/WBTC.png';
import WTC from '../assets/Tokens/WTC.png';
import YFI from '../assets/Tokens/YFI.png';
import ZRX from '../assets/Tokens/ZRX.png';

export const coinLogos = {
  '1INCH': INCH1,
  AAVE,
  ALICE,
  AMP,
  ANKR,
  AXS,
  BAT,
  BNB,
  BINANCE: BNB,
  BNT,
  BTC,
  BUSD,
  CELR,
  CHZ,
  COMP,
  COIN,
  COINBASE: COIN,
  CRO,
  CRV,
  CTSI,
  CTXC,
  DAI,
  DENT,
  DYDX,
  ENJ,
  ENS,
  ETH,
  FET,
  FTM,
  FTT,
  FTX,
  GALA,
  GLM,
  GRT,
  HOT,
  HT,
  INJ,
  JASMY,
  KCS,
  KEEP,
  LINK,
  LPT,
  LRC,
  LUNA,
  MANA,
  MATIC,
  MKR,
  NU,
  NULS,
  OKB,
  OMG,
  ONE,
  PERP,
  PLA,
  POLY,
  QNT,
  REN,
  REQ,
  RNDR,
  RSR,
  RUNE,
  SAND,
  SHIB,
  SKL,
  SNX,
  STORJ,
  SUSHI,
  TRIBE,
  TRX,
  TUSD,
  UMA,
  UNI,
  USDC,
  USDP,
  USDT,
  VGX,
  WAVES,
  WBTC,
  WTC,
  YFI,
  ZRX
};
