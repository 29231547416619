import React from 'react';
import _ from 'lodash';
import { IconButton, Tooltip, Typography } from '@mui/material';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import { GLOBALS } from '../../globals/Globals';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { customNumeral } from '../../utils/utils';

export const CommonErrorTooltip = ({
  data,
  style,
  isText,
  variant,
  isCurrency,
  prefixData,
  isNumberAsString,
  isShortFlag,
  suffixData,
  isCurrencyWithoutUnit,
  isPercentage,
  isWithOutPercentange,
  isNumeralValue,
  isFirstInDate
}) => {
  const { t } = useTranslation();

  const handleNullData = () => {
    if (
      data == null ||
      data === undefined ||
      data === 'null' ||
      data === 'undefined' ||
      data === 'UNDEFINED'
    ) {
      return (
        <Tooltip placement="top" arrow title={GLOBALS.DATA_NULL_MSG}>
          <IconButton size="small" color="error">
            <ErrorOutlineTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      );
    } else if (isCurrency) {
      return (
        <Tooltip placement="top" arrow title={data}>
          <Typography variant={variant} sx={style}>
            {_.round(data, 4)}
          </Typography>
        </Tooltip>
      );
    } else if (isPercentage) {
      return (
        <Tooltip placement="top" arrow title={data}>
          <Typography variant={variant} sx={style}>
            {`${data} %`}
          </Typography>
        </Tooltip>
      );
    } else if (isWithOutPercentange) {
      return (
        <Tooltip placement="top" arrow title={data}>
          <Typography variant={variant} sx={style}>
            {t(data)}
          </Typography>
        </Tooltip>
      );
    } else if (isNumeralValue) {
      return (
        <Tooltip placement="top" arrow title={data}>
          <Typography variant={variant} sx={style}>
            {numeral(data).format(`$0,0.[0000]`)}
          </Typography>
        </Tooltip>
      );
    } else if (isCurrencyWithoutUnit) {
      return (
        <Tooltip placement="top" arrow title={data}>
          <Typography variant={variant} sx={style}>
            {numeral(data).format(`0.00a`).toUpperCase()}
          </Typography>
        </Tooltip>
      );
    } else if (isFirstInDate) {
      return (
        <Tooltip placement="top" arrow title={data}>
          <Typography variant={variant} sx={style}>
            {data}
          </Typography>
        </Tooltip>
      );
    } else if (isNumberAsString) {
      return (
        <>
          <Tooltip placement="top" arrow title={data}>
            <Typography variant={variant} sx={style}>
              {prefixData}{' '}
              {customNumeral({ value: data, shortFlag: isShortFlag })}
              {suffixData}
            </Typography>
          </Tooltip>
        </>
      );
    } else if (isText) {
      return (
        <Typography sx={style} variant={variant}>
          {t(data)}
        </Typography>
      );
    }
    return '';
  };

  return handleNullData();
};
