import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem,
  Tooltip
} from '@mui/material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { Label } from '../../components/Label';
import { List } from '@material-ui/core';
import { useDispatch, useSelector } from '../../store';
import { getTopTokens } from '../../slices/topTokens';
import { TopTokensTable } from './TopTokensTable';

export const TopTokensComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tokenChangeSelected, setTokenChangeSelected] = useState('1D');
  const actionRef = useRef(null);

  const tokenChanges = [
    {
      value: '15MIN',
      text: t('15MIN')
    },
    {
      value: '45MIN',
      text: t('45MIN')
    },
    {
      value: '90MIN',
      text: t('90MIN')
    },
    {
      value: '3H',
      text: t('3H')
    },
    {
      value: '6H',
      text: t('6H')
    },
    {
      value: '12H',
      text: t('12H')
    },
    {
      value: '1D',
      text: t('1D')
    },
    {
      value: '1W',
      text: t('1W')
    }
  ];

  const [tokenChange, setTokenChange] = useState(tokenChanges[6].value);
  const [openTokenChange, setOpenTokenChange] = useState(false);

  const handleTokenChange = (value) => {
    setTokenChange(value);
    setOpenTokenChange(false);
    if (value === '15MIN') {
      setTokenChangeSelected(value);
    }
    if (value === '45MIN') {
      setTokenChangeSelected(value);
    }
    if (value === '90MIN') {
      setTokenChangeSelected(value);
    }
    if (value === '3H') {
      setTokenChangeSelected(value);
    }
    if (value === '6H') {
      setTokenChangeSelected(value);
    }
    if (value === '12H') {
      setTokenChangeSelected(value);
    }
    if (value === '1D') {
      setTokenChangeSelected(value);
    }
    if (value === '1W') {
      setTokenChangeSelected(value);
    }
  };

  const { loading, error, tokens } = useSelector((state) => state.topToken);

  useEffect(() => {
    if (tokenChangeSelected !== undefined) {
      dispatch(getTopTokens(tokenChangeSelected));
    }
  }, [tokenChangeSelected]);

  return (
    <Card>
      <Box pl={2} pb={1} sx={{ pr: { xs: 0, md: 2 } }}>
        <Grid container>
          <Grid item xs={8} md={3}>
            <Typography
              sx={{
                py: 2,
                textAlign: { xs: 'left', md: 'left' },
                mt: { xs: '10px', md: '20px' }
              }}
              variant="h4"
              fontWeight="bold"
            >
              {t(`Top ${tokens.length} Cryptocurrencies`)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            md={9}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-end', md: 'flex-end' },
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                alignContent: 'flex-end',
                pl: 1,
                py: { md: '5px' },
                mt: { xs: '10px', md: '15px' },
                columnGap: 2
              }}
            >
              <Box
                alignItems="center"
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  justifyContent: 'flex-end'
                }}
              >
                <Box>
                  <Label
                    color="primary"
                    sx={{ padding: '0px', maxHeight: '30px' }}
                  >
                    <Button
                      color="primary"
                      ref={actionRef}
                      onClick={() => setOpenTokenChange(true)}
                      endIcon={<ExpandMoreTwoToneIcon />}
                      size="small"
                    >
                      {tokenChange}
                    </Button>
                  </Label>
                </Box>
                <Menu
                  disableScrollLock
                  anchorEl={actionRef.current}
                  onClose={() => setOpenTokenChange(false)}
                  open={openTokenChange}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  {tokenChanges.map((_location) => (
                    <MenuItem
                      key={_location.value}
                      onClick={() => handleTokenChange(_location.value)}
                    >
                      {_location.text}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <List
                sx={{
                  width: '100%',
                  overflow: 'auto'
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: 'none', md: 'flex' },
                    flexWrap: 'wrap',
                    rowGap: 0.5
                  }}
                >
                  {tokenChanges.map((tokenChange) => {
                    return (
                      <Tooltip
                        key={tokenChange.value}
                        arrow
                        title={t(`${tokenChange.text} Change`)}
                      >
                        <Button
                          variant={
                            tokenChangeSelected === tokenChange.value
                              ? 'contained'
                              : 'outlined'
                          }
                          color="primary"
                          size="small"
                          sx={{
                            ml: 1
                          }}
                          onClick={() => {
                            handleTokenChange(tokenChange.value);
                          }}
                        >
                          {tokenChange.text}
                        </Button>
                      </Tooltip>
                    );
                  })}
                </Box>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      {/* render top-tokens table */}
      <TopTokensTable />
    </Card>
  );
};

TopTokensComponent.propTypes = {
  tokensStatic: PropTypes.array.isRequired
};

TopTokensComponent.defaultProps = {
  tokensStatic: []
};
