import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import axios from '../../utils/axios';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import countryCodes from 'country-codes-list';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import parsePhoneNumber from 'libphonenumber-js';
import {
  Box,
  Card,
  Typography,
  Container,
  styled,
  Button,
  FormHelperText,
  TextField,
  CircularProgress,
  InputAdornment,
  Tooltip,
  IconButton,
  alpha,
  Popover,
  InputBase,
  useTheme
} from '@mui/material';
import {
  AccountCircle,
  LockOutlined,
  MailOutlineOutlined
} from '@mui/icons-material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { SnackBar } from '../../components/SnackBar';
import { CountryCode } from '../../components/CountryCode';

const MainContent = styled(Box)(
  () => `
      height: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-top: 20px;
  `
);

const TopWrapper = styled(Box)(
  () => `
    display: flex;
    width: 100%;
    flex: 1;
    padding: 20px;
  `
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `

  background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    transition: ${theme.transitions.create(['background', 'color'])};

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`
);

const SearchInputWrapper = styled(InputBase)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(2)};
    width: 100%;
`
);

const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`
);

const wait = (time) => new Promise((res) => setTimeout(res, time));

const SmartAlert = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const [openSnackbar, setSnackbarOpen] = useState(false);
  const [snackbarError, setSnackbarError] = useState(true);
  const handleSnackbarClick = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const [selectedFlag, setSelectedFlag] = useState('IN');
  const [selectedCountry, setSelectedCountry] = useState('India');
  const [selectedCountryCode, setSelectedCountryCode] = useState('');

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const countryCodeArray = selectedCountry.split(':');
      setSelectedCountryCode(countryCodeArray[1]);
    }

    return () => {
      isMounted = false;
    };
  }, [selectedFlag, selectedCountry, snackbarError]);

  const myCountryCodesObject = Array(
    countryCodes.customList(
      'countryCode',
      '{countryNameEn}: +{countryCallingCode}'
    )
  );

  const countryList =
    myCountryCodesObject &&
    _.map(myCountryCodesObject[0], (item, index) => {
      const country_info = item.split(':');
      return {
        countryCode: index,
        countryName: country_info[0],
        countryCallingCode: country_info[1]
      };
    });

  const [searchValue, setSearchValue] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(countryList);

  const submitSearch = async (event) => {
    event.preventDefault();
    setSearchLoading(true);
    await wait(1500);
    setSearchLoading(false);
  };

  const handleSearchChange = async (event) => {
    event.preventDefault();

    if (event.target.value) {
      setSearchValue(event.target.value);
      setSearchLoading(true);

      const results = countryList.filter((country) => {
        return country.countryName.toLowerCase().includes(event.target.value);
      });

      await wait(1500);
      setSearchResults(results);
      setSearchLoading(false);
    } else {
      setSearchValue('');
      setSearchResults(countryList);
    }
  };

  const handleSelectChange = (country) => {
    setSelectedFlag(country.countryCode);
    setSelectedCountry(country.countryName);
    setSelectedCountryCode(country.countryCallingCode);
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // phone number validation regex
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  return (
    <Container maxWidth="xl">
      <MainContent>
        <TopWrapper>
          <Container maxWidth="sm">
            <Card
              sx={{
                mt: 0,
                px: 4,
                pt: 5,
                pb: 3
              }}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Smart Alert')}
                </Typography>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t(
                    'Smart alerts can be used to set an alert to notify users when a specific event occurs. Users can create alerts like simple price alerts or alerts to get notified when a specific wallet creates a new contract or makes a transaction. Register Now to get notified when this feature is live.'
                  )}
                </Typography>
              </Box>

              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  phone: '',
                  terms: false,
                  submit: null
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string()
                    .max(255)
                    .required(t('The name field is required')),
                  phone: Yup.string()
                    .matches(
                      phoneRegExp,
                      t('Invalid phone number must contained min 10 or max 15')
                    )
                    .min(10)
                    .max(15)
                    .required(t('The phone number field is required')),
                  email: Yup.string()
                    .email(
                      t('The email provided should be a valid email address')
                    )
                    .max(255)
                    .required(t('The email field is required'))
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    const phoneNumber = parsePhoneNumber(
                      values.phone,
                      selectedFlag
                    );
                    if (phoneNumber && phoneNumber.isValid() === true) {
                      const response = await axios.post(
                        `/smart-alert/add-smart-alert-info`,
                        {
                          full_name: values.name,
                          email: values.email,
                          phone_number: selectedCountryCode + values.phone
                        }
                      );

                      // created & success
                      if (response.status === 201) {
                        setStatus({ success: true });
                        setSubmitting(false);
                        setSnackbarError(false);
                      } else {
                        setStatus({ success: false });
                        setSubmitting(false);
                        setSnackbarError(true);
                      }
                    } else {
                      setSnackbarError(true);
                    }
                  } catch (err) {
                    setSnackbarError(true);
                  }
                  handleSnackbarClick();
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label={t('Full Name')}
                      placeholder={t('Enter Your Name...')}
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.name}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        )
                      }}
                    />

                    {/* TODO: Country Code Switcher */}
                    <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      helperText={touched.phone && errors.phone}
                      label={t('Phone Number')}
                      placeholder={t('Enter Your Number...')}
                      margin="normal"
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.phone}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <>
                            <Tooltip arrow title={t('Select Country Code')}>
                              <IconButtonPrimary
                                color="primary"
                                ref={ref}
                                onClick={handleOpen}
                              >
                                {getUnicodeFlagIcon(selectedFlag)}
                              </IconButtonPrimary>
                            </Tooltip>
                            <InputAdornment position="start">
                              <LockOutlined />
                            </InputAdornment>
                          </>
                        )
                      }}
                    />
                    {/* Popper */}
                    <Popover
                      disableScrollLock
                      anchorEl={ref.current}
                      onClose={handleClose}
                      open={isOpen}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    >
                      <Box
                        sx={{
                          maxWidth: 360,
                          maxHeight: 360
                        }}
                      >
                        <Box>
                          <form onSubmit={submitSearch}>
                            <Box display="flex" alignItems="center">
                              <Box
                                flexGrow={1}
                                display="flex"
                                alignItems="center"
                              >
                                <SearchTwoToneIcon
                                  sx={{
                                    ml: 2,
                                    color: theme.colors.secondary.main
                                  }}
                                />
                                <SearchInputWrapper
                                  value={searchValue}
                                  onChange={handleSearchChange}
                                  autoFocus
                                  placeholder={t('Search Country...')}
                                  fullWidth
                                />
                              </Box>
                              <Card
                                sx={{
                                  ml: 'auto',
                                  mr: 2,
                                  py: 0.5,
                                  px: 1,
                                  background: theme.colors.alpha.black[10]
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  fontWeight="bold"
                                >
                                  esc
                                </Typography>
                              </Card>
                            </Box>
                          </form>
                        </Box>
                        <SectionHeading variant="body2" color="text.primary">
                          {t('Country Codes')}
                        </SectionHeading>
                        {/* Country Code */}
                        <CountryCode
                          searchResults={searchResults}
                          handleSelectChange={handleSelectChange}
                        />
                      </Box>
                    </Popover>

                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label={t('Email address')}
                      placeholder={t('Enter Your Email Address...')}
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineOutlined />
                          </InputAdornment>
                        )
                      }}
                    />
                    {Boolean(touched.terms && errors.terms) && (
                      <FormHelperText error>{errors.terms}</FormHelperText>
                    )}
                    <Tooltip arrow title={t("We're working on this feature")}>
                      <Button
                        sx={{
                          mt: 3
                        }}
                        color="primary"
                        startIcon={
                          isSubmitting ? <CircularProgress size="1rem" /> : null
                        }
                        disabled={true}
                        size="large"
                        fullWidth
                        type="submit"
                        variant="contained"
                      >
                        {t('Register for early access')}
                      </Button>
                    </Tooltip>
                  </form>
                )}
              </Formik>
            </Card>
          </Container>
        </TopWrapper>
      </MainContent>
      {/* Snackbar */}
      <SnackBar
        snackbarError={snackbarError}
        openSnackbar={openSnackbar}
        handleSnackbarClose={handleSnackbarClose}
      />
    </Container>
  );
};

export default SmartAlert;
