import React from 'react';
import { Box, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import SortTwoToneIcon from '@mui/icons-material/SortTwoTone';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { visuallyHidden } from '@mui/utils';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align !== undefined ? headCell.align : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              position: headCell.isSticky ? 'sticky' : 'static',
              left: headCell.left ? headCell.left : 0,
              zIndex: 10
            }}
          >
            {headCell.showArrow ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                hideSortIcon={false}
              >
                <SortTwoToneIcon
                  sx={{
                    mr: 1
                  }}
                  fontSize="small"
                />
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
                {headCell.infoTooltip ? (
                  <Box sx={{ p: 1, display: 'flex' }}>
                    <Tooltip title={headCell.infoMsg} arrow>
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  </Box>
                ) : (
                  ''
                )}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
