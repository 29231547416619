import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useDispatch, useSelector } from '../../../../../store';
import { getUniqueAddressForToken } from '../../../../../slices/insiders';
import { useLocation } from 'react-router';
import { GLOBALS } from '../../../../../globals/Globals';
import { customNumeral } from '../../../../../utils/utils';

function RenderChart({ uniqueAddressForToken, changeAction }) {
  const theme = useTheme();

  const { loading, error, data } = uniqueAddressForToken;
  const [dateArray, setDateArray] = useState([]);

  useEffect(() => {
    if (!loading) {
      _.map(data.dates, (date, index) => {
        setDateArray((oldState) => [...oldState, date]);
      });
    }
  }, [changeAction]);

  let options = {
    chart: {
      height: '200',
      type: 'area',
      id: 'basic-line', // id of the chart
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        autoSelected: 'zoom'
      },
      offsetY: 20
    },
    series: [
      {
        name: 'Address',
        type: 'area',
        data: data.count_of_unique_address
      }
    ],
    theme: {
      mode: theme.palette.mode
    },
    dataLabels: {
      enabled: false
    },
    noData: {
      text: 'No Data Available.',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined
      }
    },
    stroke: {
      curve: 'smooth'
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    xaxis: {
      categories: dateArray,
      type: 'datetime'
    },
    yaxis: {
      title: {
        text: 'Addresses'
      },
      labels: {
        formatter(value) {
          if (typeof value !== 'undefined') {
            return customNumeral(value);
          }
          return value;
        }
      }
    },
    tooltip: {
      shared: true,
      y: {
        formatter(value) {
          if (typeof value !== 'undefined') {
            return value;
          }
          return value;
        }
      }
    }
  };

  const [chart, setChart] = useState(options);

  useEffect(() => {
    setChart(options);
  }, [changeAction]);

  return (
    <Chart
      type="area"
      options={options}
      series={chart.series}
      width="100%"
      height="100%"
    />
  );
}

export const UniqueAddressesChart = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const tickerSymbol = location.pathname.split('/')[2];

  const uniqueAddressChartChages = [
    {
      value: '1D',
      text: '1D'
    },
    {
      value: '1W',
      text: '1W'
    },
    {
      value: '2W',
      text: '2W'
    },
    {
      value: '3W',
      text: '3W'
    },
    {
      value: '1M',
      text: '1M'
    }
  ];

  const dispatch = useDispatch();
  const { uniqueAddressForToken } = useSelector((state) => state.insiders);

  const [changeAction, setChangeAction] = useState(
    uniqueAddressChartChages[1].text
  );
  const actionRef = useRef(null);
  const [openUniqueAddressChartChange, setOpenUniqueAddressChartChange] =
    useState(false);
  const [uniqueAddressChartTime, setUniqueAddressChartTime] = useState('1W');

  const handleVolumeChange = (value) => {
    setChangeAction(value);
    setOpenUniqueAddressChartChange(false);
    if (value === '1D') {
      setUniqueAddressChartTime(value);
    }
    if (value === '1W') {
      setUniqueAddressChartTime(value);
    }
    if (value === '2W') {
      setUniqueAddressChartTime(value);
    }
    if (value === '3W') {
      setUniqueAddressChartTime(value);
    }
    if (value === '1M') {
      setUniqueAddressChartTime(value);
    }
    if (value === '1M') {
      setUniqueAddressChartTime(value);
    }
  };

  const { loading, error, data } = uniqueAddressForToken;

  useEffect(() => {
    dispatch(getUniqueAddressForToken(tickerSymbol, uniqueAddressChartTime));
  }, [changeAction]);

  const _render = () => {
    if (loading) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      );
    } else if (!loading && (!_.isEmpty(error) || error === undefined)) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return (
      <RenderChart
        uniqueAddressForToken={uniqueAddressForToken}
        changeAction={changeAction}
      />
    );
  };

  return (
    <Box component={Paper} sx={{ minHeight: 600, p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h4"
            sx={{ color: theme.colors.alpha.black[100] }}
          >
            {t('Unique Address for Token')}
          </Typography>
          <Box sx={{ p: 1, display: 'flex', cursor: 'pointer' }}>
            <Tooltip
              title={GLOBALS.INSIDERS_UNIQUE_ADDRESS_FOR_TOKEN_GRAPH}
              arrow
            >
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </Box>
        </Box>
        <Box>
          <Button
            color="secondary"
            ref={actionRef}
            onClick={() => setOpenUniqueAddressChartChange(true)}
            endIcon={<ExpandMoreTwoToneIcon />}
            size="small"
          >
            {changeAction}
          </Button>
        </Box>
        <Menu
          disableScrollLock
          anchorEl={actionRef.current}
          onClose={() => setOpenUniqueAddressChartChange(false)}
          open={openUniqueAddressChartChange}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          {uniqueAddressChartChages.map((_location) => (
            <MenuItem
              key={_location.value}
              onClick={() => handleVolumeChange(_location.value)}
            >
              {_location.text}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {_render()}
    </Box>
  );
};
