import React from 'react';
import _ from 'lodash';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { List, ListItem, ListItemText } from '@mui/material';

export const CountryCode = ({ searchResults, handleSelectChange }) => {
  const renderCountries = () => {
    if (searchResults && searchResults.length > 0) {
      return _.map(searchResults, (country, index) => {
        return (
          <List
            sx={{
              p: 2
            }}
            component="nav"
          >
            <ListItem
              key={index}
              className="active"
              button
              onClick={() => {
                handleSelectChange(country);
              }}
            >
              {getUnicodeFlagIcon(country.countryCode)}
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary={country.countryName}
              />
            </ListItem>
          </List>
        );
      });
    }
  };

  return renderCountries();
};
