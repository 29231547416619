import React from 'react';
import { Box, TableCell, TableRow, Skeleton } from '@mui/material';

export const SkeletonLoad = ({ rows, cols }) => (
  <>
    {[...Array(rows)].map((_, index) => (
      <TableRow hover key={index}>
        {[...Array(cols)].map((_, index) => (
          <TableCell align="center" key={index + 1}>
            <Box sx={{ width: 100 }}>
              <Skeleton animation="wave" />
            </Box>
          </TableCell>
        ))}
      </TableRow>
    ))}
  </>
);
