// Search
import React, { createContext, useCallback } from 'react';
import { useLocation } from 'react-router';

export const SearchContext = createContext({});

export const SearchProvider = ({ children }) => {
  const location = useLocation();

  const requestSearch = useCallback(
    (searched_ticker_symbol) => {
      window.open(
        `/${location.pathname.split('/')[1]}/${searched_ticker_symbol}`,
        '_self'
      );
    },
    [location]
  );

  return (
    <SearchContext.Provider
      value={{
        requestSearch
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
