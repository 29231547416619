import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import {
  Box,
  Paper,
  Typography,
  useTheme,
  CircularProgress,
  MenuItem,
  Menu,
  Button,
  Tooltip
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useDispatch, useSelector } from '../../../../../store';
import { getTopHolders } from '../../../../../slices/insiders';
import { useQuery } from '../../../../../hooks/useQuery';
import { GLOBALS } from '../../../../../globals/Globals';
import { customNumeral } from '../../../../../utils/utils';

function RenderChart({ topHolders, changeAction }) {
  const theme = useTheme();

  const { loading, error, data } = topHolders;

  const [dateArray, setDateArray] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (!loading) {
      setAddresses(data[data.length - 1]);
      _.map(data, (item, index) => {
        if (index === data[data.length - 1]) return;
        if (index === 0) {
          setDateArray(item[1]);
        }
        setValues((oldState) => [...oldState, item[0]]);
      });
    }
  }, [data, loading]);

  // let minY = 0;
  // let maxY = 0;
  // const minY = useRef(0);
  // const maxY = useRef(0);

  // useEffect(() => {
  //   if (values.length > 0) {
  //     _.map(values, (item, index) => {
  //       minY.current = Math.min(item);
  //       maxY.current = Math.max(item);
  //     });
  //   }
  // }, [values]);

  // console.log(minY.current, maxY.current);

  let options = {
    chart: {
      height: '200',
      id: 'basic-line', // id of the chart
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        autoSelected: 'zoom'
      },
      offsetY: 20,
      offsetX: 0,
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      }
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      type: 'datetime',
      categories: dateArray
    },
    yaxis: {
      title: {
        text: 'Addresses'
      },
      // min: minY.current, // Math.min(values), // min value of the Y axis
      // max: maxY.current, // Math.max(values), // max value of the Y axis
      labels: {
        formatter(value) {
          if (typeof value !== 'undefined') {
            return customNumeral({ value });
          }
          return value;
        }
      }
    },
    noData: {
      text: 'No Data Available.',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined
      }
    },
    dataLabels: {
      enabled: false
    },

    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    colors: ['#0D8BFF', '#3BB900', '#0AB6FF', '#F49200', '#F4002C'],
    stroke: {
      show: true,
      curve: 'straight',
      lineCap: 'butt',
      colors: undefined,
      width: 2,
      dashArray: 0
    },
    tooltip: {
      shared: true,
      y: {
        formatter(value) {
          if (typeof value !== 'undefined') {
            return customNumeral({ value });
          }
          return value;
        }
      }
    },
    markers: { size: 0 },
    series: _.map(addresses, (address, index) => {
      return {
        name: address,
        data: values[index]
      };
    })
  };

  const [chart, setChart] = useState(options);

  useEffect(() => {
    setChart(options);
  }, [changeAction, addresses]);

  return (
    <Chart
      options={options}
      series={chart.series}
      type="line"
      width="100%"
      height="100%"
    />
  );
}

export const TopHoldersBalanceChart = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const tickerSymbol = location.pathname.split('/')[2];

  const coinsPeriods = [
    {
      value: 'bitcoin',
      text: 'Bitcoin'
    },
    {
      value: 'ethereum',
      text: 'Ethereum'
    },
    {
      value: 'binance',
      text: 'Binance'
    }
  ];
  // const [openCoinPeriod, setOpenCoinMenuPeriod] = useState(false);
  // const [coinPeriod, setCoinPeriod] = useState(coinsPeriods[0].text);
  // const actionRef1 = useRef(null);

  const periods = [
    {
      value: '1D',
      text: '1D'
    },
    {
      value: '1W',
      text: '1W'
    },
    {
      value: '2W',
      text: '2W'
    },
    {
      value: '3W',
      text: '3W'
    },
    {
      value: '1M',
      text: '1M'
    }
  ];

  // const [openPeriod, setOpenMenuPeriod] = useState(false);
  // const [period, setPeriod] = useState(periods[3].text);
  const actionRef = useRef(null);

  const [changeAction, setChangeAction] = useState(periods[1].text);
  const [openTopHoldersChange, setOpenTopHoldersChange] = useState(false);
  const [topHoldersTime, setTopHoldersTime] = useState('1W');

  const handleTopTransferBtwExchangeChartChange = (value) => {
    setChangeAction(value);
    setOpenTopHoldersChange(false);
    if (value === '1D') {
      setTopHoldersTime(value);
    }
    if (value === '1W') {
      setTopHoldersTime(value);
    }
    if (value === '2W') {
      setTopHoldersTime(value);
    }
    if (value === '3W') {
      setTopHoldersTime(value);
    }
    if (value === '1M') {
      setTopHoldersTime(value);
    }
  };

  const dispatch = useDispatch();
  const { topHolders } = useSelector((state) => state.insiders);
  const { loading, error } = topHolders;

  const query = useQuery();
  const from = query.get('from');
  const to = query.get('to');

  useEffect(() => {
    dispatch(getTopHolders(tickerSymbol, topHoldersTime, from, to));
  }, [changeAction]);

  const _render = () => {
    if (loading) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      );
    } else if (!loading && (!_.isEmpty(error) || error === undefined)) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">{GLOBALS.API_ERROR_MSG}</Typography>
        </Box>
      );
    }
    return <RenderChart topHolders={topHolders} changeAction={changeAction} />;
  };

  return (
    <Box component={Paper} sx={{ minHeight: 600, p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4">{t('Top Holders Balance')}</Typography>
          <Box sx={{ p: 1, display: 'flex', cursor: 'pointer' }}>
            <Tooltip title={GLOBALS.INSIDERS_TOP_HOLDERS_BALANCE_GRAPH} arrow>
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 1 }}>
          {/* CustomDropdown */}
          {/* <CustomDropdown */}
          {/*  actionRef={actionRef1} */}
          {/*  setOpenMenuPeriod={setOpenCoinMenuPeriod} */}
          {/*  openPeriod={openCoinPeriod} */}
          {/*  period={coinPeriod} */}
          {/*  periods={coinsPeriods} */}
          {/*  setPeriod={setCoinPeriod} */}
          {/* /> */}
          <Box>
            <Button
              color="secondary"
              ref={actionRef}
              onClick={() => setOpenTopHoldersChange(true)}
              endIcon={<ExpandMoreTwoToneIcon />}
              size="small"
            >
              {changeAction}
            </Button>
          </Box>
          <Menu
            disableScrollLock
            anchorEl={actionRef.current}
            onClose={() => setOpenTopHoldersChange(false)}
            open={openTopHoldersChange}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {periods.map((_location) => (
              <MenuItem
                key={_location.value}
                onClick={() =>
                  handleTopTransferBtwExchangeChartChange(_location.value)
                }
              >
                {_location.text}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      {_render()}
    </Box>
  );
};
