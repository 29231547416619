import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip
} from '@mui/material';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import { CommonErrorTooltip } from '../../../../../components/CommonErrorTooltip';
import { SkeletonLoad } from '../../../../../components/SkeletonLoad';
import {
  EnhancedTableHead,
  getComparator,
  stableSort
} from '../../../../../components/Table';
import { GLOBALS } from '../../../../../globals/Globals';
import { useSelector } from '../../../../../store';
import { getDateString, getFriendlyAddress } from '../../../../../utils/utils';

const headCells = [
  {
    id: 'rank',
    numeric: false,
    label: 'Wallet',
    showArrow: true,
    align: 'center',
    isSticky: true,
    left: 0
  },
  {
    id: 'balance_number',
    numeric: true,
    label: 'Balance',
    infoTooltip: true,
    infoMsg: GLOBALS.INSIDERS_SELECT_ADDRESS_WALLET_ADDRESS_BALANCE,
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'ownership_number',
    numeric: true,
    label: '% Ownership',
    infoTooltip: true,
    infoMsg: GLOBALS.INSIDERS_SELECT_ADDRESS_WALLET_ADDRESS_OWNERSHIP,
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'receive_number',
    numeric: true,
    label: 'Received',
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'send_number',
    numeric: true,
    label: 'Send',
    showArrow: true,
    align: 'center',
    isSticky: false
  },
  {
    id: 'firstIn',
    numeric: false,
    label: 'First In',
    showArrow: false,
    align: 'center',
    isSticky: false
  },
  {
    id: 'action',
    numeric: false,
    label: 'Actions',
    showArrow: false
  }
];

export const AddressInfoTable = () => {
  const location = useLocation();
  const tickerSymbol = location.pathname.split('/')[2];

  const { walletAddresses } = useSelector((state) => state.insiders);
  const { loading, error, data } = walletAddresses;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('balance_number');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
          stickyHeader
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headCells={headCells}
          />
          <TableBody>
            {loading && error === '' ? (
              <SkeletonLoad rows={5} cols={7} />
            ) : (
              stableSort(
                data.map((item) => {
                  return {
                    ...item,
                    rank: String(item.wallet_address),
                    balance_number: _.toNumber(item.balance),
                    balance_string: String(item.balance),
                    ownership_number: _.toNumber(item.ownership),
                    ownership_string: String(item.ownership),
                    receive_number: _.toNumber(item.receive),
                    receive_string: String(item.receive),
                    send_number: _.toNumber(item.send),
                    send_string: String(item.send)
                  };
                }),
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const {
                    wallet_address,
                    balance_string,
                    ownership_string,
                    receive_string,
                    send_string,
                    first_in
                  } = row;
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0
                        }
                      }}
                    >
                      <Tooltip title={wallet_address} arrow placement="top">
                        <TableCell
                          align="center"
                          sx={{
                            position: 'sticky',
                            left: 0,
                            background: (theme) => theme.sidebar.background,
                            zIndex: 10
                          }}
                        >
                          {getFriendlyAddress({ address: wallet_address })}
                        </TableCell>
                      </Tooltip>
                      <TableCell align="center">
                        <CommonErrorTooltip
                          data={balance_string}
                          style={{ whiteSpace: 'nowrap' }}
                          isNumberAsString
                        />
                      </TableCell>
                      <TableCell align="center">
                        <CommonErrorTooltip
                          data={ownership_string}
                          isNumberAsString
                          suffixData="%"
                          style={{ whiteSpace: 'nowrap' }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <CommonErrorTooltip
                          data={receive_string}
                          isNumberAsString
                          style={{ whiteSpace: 'nowrap' }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <CommonErrorTooltip
                          data={send_string}
                          isNumberAsString
                          style={{ whiteSpace: 'nowrap' }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <CommonErrorTooltip
                          data={getDateString(first_in)}
                          isFirstInDate
                          style={{ whiteSpace: 'nowrap' }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Link
                          to={`/crypto/${tickerSymbol}/insiders/?view=AddressInfo&WalletAddress=${row.wallet_address}`}
                        >
                          <VisibilityTwoToneIcon
                            sx={{
                              color: '#0AB6FF',
                              cursor: 'pointer'
                            }}
                            fontSize="small"
                          />
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
