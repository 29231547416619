export const GLOBALS = {};

// routes
GLOBALS.HOME_ROUTE = '/crypto';
GLOBALS.WEALTH_ROUTE = '/crypto/service/wealth';
GLOBALS.WATCHLIST_ROUTE = '/crypto/service/watchlist';
GLOBALS.SMART_ALERT_ROUTE = '/crypto/service/smart-alert';

// Header
GLOBALS.STOCKS = '#';
GLOBALS.COMMUNITY_ROUTE = '#';

// Hamburger Menu
GLOBALS.PROFILE = '#';
GLOBALS.SETTING = '#';
GLOBALS.SUPPORT = '#';
GLOBALS.LOGOUT = '#';
GLOBALS.UPGRADE = '#';

// App
GLOBALS.DIGITS_AFTER_DECIMAL = 2;

// Footer
GLOBALS.COMPANY_NAME = 'Chainlake';
GLOBALS.YOUTUBE_LINK = '#';
GLOBALS.FACEBOOK_LINK = '#';
GLOBALS.TWITTER_LINK = '#';
GLOBALS.INSTAGRAM_LINK = '#';
GLOBALS.LINKDIN_LINK = '#';
GLOBALS.EMAIL_LINK = '#';
GLOBALS.MEDIUM_LINK = '#';
GLOBALS.SUPPORT_LINK = '#';
GLOBALS.AS_FEATURED_ON_LINK = '#';
GLOBALS.LEGAL_LINK = '#';
GLOBALS.PRIVACY_POLICY_LINK = '#';
GLOBALS.TERMS_OF_USE_LINK = '#';
GLOBALS.EARNINGS_DISCLAIMER_LINK = '#';
GLOBALS.FINANCIAL_DISCLAIMER_LINK = '#';
GLOBALS.AFFILIATE_DISCLOSER_LINK = '#';
GLOBALS.REFUND_POLICY_LINK = '#';
GLOBALS.EULA_LINK = '#';
GLOBALS.DONT_SELL_MY_PERSONAL_INFO_LINK = '#';
GLOBALS.TESTIMONIALS_RESULT_LINK = '#';

// Stories
GLOBALS.STORIES_PER_PAGE = 6;

// Owner
GLOBALS.OWNER_WHY_THESE_EXCHANGE_KNOW_MORE =
  'https://sg.news.yahoo.com/robinhood-coinbase-ftx-heres-whos-winning-the-crypto-trading-game-145331322.html';

// NEWS
GLOBALS.NEWS_API = '#';
GLOBALS.NEWS_LIMIT = '7';
GLOBALS.NEWS_OFFSET = 1;
GLOBALS.NEWS_NOT_AVAILABLE = 'News not available for';
GLOBALS.TRANSACTION_NOT_AVAILABLE = 'Transaction not available for';
GLOBALS.NEWS_ETHERSCAN_URL = 'https://etherscan.io/address/';

// Common Error Msg
GLOBALS.DATA_NULL_MSG = 'Data not available or might be outdated.';
GLOBALS.API_ERROR_MSG = 'Something Went Wrong';

// Select Candle Level Help MSG
GLOBALS.SELECT_CANDLE_LEVEL_MSG = 'Candle only works >1Day candle size.';

// Tooltip info
GLOBALS.CRYPTO_MARKET_CAP =
  "Market Cap = Current Price x Circulating Supply.\n Refers to the total market value of a cryptocurrency's circulating supply. It is similar to the stock market's measurement of multiplying price per share by shares readily available.";
GLOBALS.CRYPTO_VOLUME_TRADED =
  'A measure of how much of a cryptocurrency was traded in the last 24 hour.';
GLOBALS.CRYPTO_CIRCULATING_SUPPLY =
  'The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market.';
GLOBALS.CRYPTO_DILUTED_MARKET_CAP =
  'The market cap if the max supply was in circulation.';
GLOBALS.CRYPTO_CICULATING_SUPPLY =
  'The amount of coins that are circulating in the market and are in public hands.';
GLOBALS.CRYPTO_ROI =
  'The approximate return on investment if purchased at the time of launch.';
GLOBALS.OWNER_TOP_TRANSFER_WITHIN_EXCHANGE_CHANGE =
  "Change= Coins transfer in X time - Coins transfer in last X time.\n Difference between the tranfered coin within exchange's wallet in the selected timeframe with previous timeframe.";
GLOBALS.OWNER_TOP_TRANSFER_WITHIN_EXCHANGE_VOLUME =
  "Total quantity of coins transfered in a selected timeframe within exchange's wallet.";
GLOBALS.OWNER_TOKEN_ON_EXCHANGE_GRAPH =
  'Token on Exchange chart shows the number of coins present on each Exchange over the selected period of time.';
GLOBALS.OWNER_TOKEN_PRICE_GRAPH =
  'Token Price chart shows the relation between the number of coins in the market and the price of the coin.';
GLOBALS.OWNER_ADDRESS_DEPOSITING_GRAPH =
  'Address depositing chart shows the balance changes in the exchange, Depositer shows the number of wallets depositing the coin in their wallet and withdrawers shows the number of wallets withdrawing the coin in their wallet.';
GLOBALS.OWNER_TOP_TRANSFER_BETWEEN_EXCHAGNE_INDIVIDUAL_WALLET_NET_CHANGE =
  "Net change is the Net total coins that have been transfered from exchange's wallet to individual wallets and Individual wallets to exchange's wallet.";
GLOBALS.OWNER_TOP_TRANSFER_BETWEEN_EXCHAGNE_NET_CHANGE =
  'Net change is the Net total coins that have been transferred in and out of the exchange.';
GLOBALS.OWNER_TOP_HOLDERS_OWNERSHIP =
  'ownership percent is the percent of the selected coin present in the respective exchange.';
GLOBALS.OWNER_TOP_HOLDERS_NET_CHANGE_1D =
  'Net change is the net percent change in the coins in the exchange in last 24h.';
GLOBALS.OWNER_TOP_HOLDERS_NET_CHANGE_1W =
  'Net change is the net percent change in the coins in the exchange in last 1W.';
GLOBALS.OWNER_TOP_HOLDERS_NET_CHANGE_2W =
  'Net change is the net percent change in the coins in the exchange in last 2W.';
GLOBALS.OWNER_TOP_HOLDERS_NET_CHANGE_3W =
  'Net change is the net percent change in the coins in the exchange in last 3W.';
GLOBALS.OWNER_TOP_HOLDERS_NET_CHANGE_1M =
  'Net change is the net percent change in the coins in the exchange in last 1M.';
GLOBALS.INSIDERS_TOKEN_DISTRIBUTION_ADDRESS_COUNT =
  'Total number of wallet  whose coin balance is within the range.';
GLOBALS.INSIDERS_TOKEN_DISTRIBUTION_PERCENT_ADDRESS =
  'Percent Address = Total number of wallet present within the range/ Total number of wallet X 100.';
GLOBALS.INSIDERS_TOKEN_DISTRIBUTION_COIN =
  'Total number of coin balance is within the range.';
GLOBALS.INSIDERS_TOKEN_DISTRIBUTION_USD =
  'USD price of the total coins present within the price range.';
GLOBALS.INSIDERS_TOKEN_DISTRIBUTION_COIN_PERCENT =
  'Percent Coin = Total number of Coins present within the range/ Total Number of Coins X 100.';
GLOBALS.INSIDERS_RICH_LIST_1 =
  'Total number of wallet with more than $1 and less than $100 for selected coin.';
GLOBALS.INSIDERS_RICH_LIST_100 =
  'Total number of wallet with more than $100 and less than $10,000 for selected coin.';
GLOBALS.INSIDERS_RICH_LIST_10000 =
  'Total number of wallet with more than $10,000 and less than $1,000,000 for selected coin.';
GLOBALS.INSIDERS_RICH_LIST_1000000 =
  'Total number of wallet with more than $1,000,000 and less than $100,000,000 for selected coin.';
GLOBALS.INSIDERS_RICH_LIST_100000000 =
  'Total number of wallet with more than $100,000,000 for selected coin.';
GLOBALS.INSIDERS_UNIQUE_ADDRESS_FOR_TOKEN_GRAPH =
  'This graph shows the number unique wallets which are adding the selected coin over the selected period of time.';
GLOBALS.INSIDERS_TOP_HOLDERS_BALANCE_GRAPH =
  'Top holders balance shows the number of coins present on top 20 wallets.';
GLOBALS.INSIDERS_COST_INFORMATION_GRAPH =
  'Cost information charts showns the price of coins, number of coins present in the wallet and cost average.';
GLOBALS.INSIDERS_SELECT_ADDRESS_WALLET_ADDRESS_BALANCE =
  'Value of total  coins present in the wallet.';
GLOBALS.INSIDERS_SELECT_ADDRESS_WALLET_ADDRESS_OWNERSHIP =
  '% Ownership = Total coin present in the wallet/ total number of coins X 100.';
