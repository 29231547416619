import React, { Fragment, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Typography,
  useTheme,
  Box,
  Card,
  CircularProgress,
  Slide,
  Divider,
  InputBase,
  Dialog
} from '@mui/material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';
import { styled, alpha } from '@mui/material/styles';
import Scrollbar from '../Scrollbar';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-container {
          height: auto;
      }
      
      .MuiDialog-paperScrollPaper {
          max-height: calc(100vh - 64px)
      }
  `
);
const SearchInputWrapper = styled(InputBase)(
  ({ theme }) => `
      font-size: ${theme.typography.pxToRem(18)};
      padding: ${theme.spacing(2)};
      width: 100%;
  `
);

const ListButton = styled(Box)(
  ({ theme }) => `
        background-color: transparent;
        color:  ${theme.colors.alpha.black[100]};
        transition: ${theme.transitions.create(['all'])};
        border: ${theme.colors.alpha.black[10]} solid 1px;
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(1)};
        margin: ${theme.spacing(1, 0)};
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        & > div > .MuiSvgIcon-root {
          color:  ${theme.colors.alpha.black[50]};
          transition: ${theme.transitions.create(['all'])};
        }
  
        &:hover {
          background-color: ${alpha(theme.colors.primary.main, 0.08)};
          color:  ${theme.colors.primary.main};
          border-color: ${alpha(theme.colors.primary.main, 0.3)};
  
          & > div > .MuiSvgIcon-root {
            color:  ${theme.colors.primary.main};
          }
        }
  `
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const DialogWrapperComponent = ({
  open,
  setOpen,
  handleClose,
  searchValue,
  handleSearchChange,
  searchLoading,
  searchResults,
  tokenResults
}) => {
  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <DialogWrapper
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="sm"
      fullWidth
      scroll="paper"
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-start',
          alignItems: 'flex-start'
        }
      }}
      PaperProps={{
        sx: {
          m: 0,
          top: 50,
          left: 5
        }
      }}
    >
      <Box>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1} display="flex" alignItems="center">
            <SearchTwoToneIcon
              sx={{
                ml: 2,
                color: theme.colors.secondary.main
              }}
            />
            <SearchInputWrapper
              value={searchValue}
              onChange={handleSearchChange}
              placeholder={t('Search tokens here...')}
              fullWidth
              id="token_dialog_input"
            />
          </Box>
          <Card
            sx={{
              ml: 'auto',
              mr: 2,
              py: 0.5,
              px: 1,
              background: theme.colors.alpha.black[10]
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              fontWeight="bold"
            >
              esc
            </Typography>
          </Card>
        </Box>
      </Box>
      <Divider />
      {!searchLoading && (
        <>
          {!searchResults && (
            <Typography
              variant="subtitle1"
              component="div"
              sx={{
                background: theme.colors.info.lighter,
                color: theme.colors.info.main,
                borderRadius: theme.general.borderRadius,
                fontSize: theme.typography.pxToRem(13),
                display: 'flex',
                alignItems: 'center',
                p: 1,
                mx: 2,
                my: 2
              }}
            >
              <ContactSupportTwoToneIcon
                sx={{
                  mr: 0.8,
                  fontSize: theme.typography.pxToRem(18)
                }}
              />
              {t('Start typing to see the search results...')}
            </Typography>
          )}
        </>
      )}
      {searchLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            my: 5
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {searchResults && tokenResults.length !== 0 && (
            <Box
              sx={{
                height: 450
              }}
            >
              <Scrollbar>
                {tokenResults.map((result, index) => (
                  <Box key={result.ticker_symbol} px={2} py={1}>
                    <Typography
                      sx={{
                        py: 1
                      }}
                      variant="h5"
                    >
                      {result.ticker_symbol}
                    </Typography>
                    <Fragment key={result.id}>
                      <ListButton
                        component={RouterLink}
                        onClick={() => setOpen(false)}
                        to={`/${location.pathname.split('/')[1]}/${
                          result.ticker_symbol
                        }`}
                      >
                        <Box display="flex" alignItems="flex-start">
                          <CurrencyExchangeTwoToneIcon
                            sx={{
                              mr: 1
                            }}
                            fontSize="small"
                          />
                          <Typography>{result.name}</Typography>
                        </Box>
                        <KeyboardArrowRightTwoToneIcon fontSize="small" />
                      </ListButton>
                    </Fragment>
                  </Box>
                ))}
              </Scrollbar>
            </Box>
          )}
        </>
      )}
    </DialogWrapper>
  );
};
