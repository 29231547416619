import React from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import {
  Button,
  Box,
  Skeleton,
  Typography,
  useTheme,
  Avatar,
  Divider
} from '@mui/material';
import { useLocation } from 'react-router';
import { coinLogos } from '../../../../utils/coinLogos';

export const NewsComponent = ({
  loading,
  data,
  setSelectedNewsIndex,
  selectedNewsIndex,
  index,
  showReadMore
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const tokenSymbol = location.pathname.split('/')[2];

  const renderReadMore = () => {
    if (!showReadMore) return;
    return (
      <>
        {loading ? (
          <Skeleton animation="wave" width={60} />
        ) : (
          <Button
            size="small"
            color={selectedNewsIndex === index ? 'error' : 'primary'}
            variant="outlined"
            onClick={() => {
              if (selectedNewsIndex === index) {
                setSelectedNewsIndex('');
              } else {
                setSelectedNewsIndex(index);
              }
            }}
          >
            {selectedNewsIndex === index ? t('Read less') : t('Read more')}
          </Button>
        )}
      </>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        rowGap: 2,
        columnGap: 2
      }}
    >
      <Box flexGrow={1}>
        <Box sx={{ mt: 2, minWidth: '280px' }}>
          {loading ? (
            <Box sx={{ width: '80%' }}>
              <Skeleton animation="wave" />
            </Box>
          ) : (
            <a
              href={data.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Typography color="text.primary">{t(data.title)}</Typography>
            </a>
          )}
        </Box>
        <Box
          display="flex"
          mt={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body2" color="text.secondary" fontSize={12}>
            {loading ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              t(new Date(data.published_date).toDateString())
            )}
          </Typography>
          {renderReadMore()}
        </Box>
      </Box>
      <Divider
        orientation="vertical"
        variant="fullWidth"
        flexItem
        sx={{ color: '#E3E5E4', background: '#E3E5E4' }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          order: { xs: 1, sm: 2 }
        }}
      >
        {loading ? (
          <Skeleton variant="circular" width={80} height={80} />
        ) : (
          <LazyLoadComponent>
            <Avatar
              variant="square"
              src={data.image_url ? data.image_url : coinLogos[tokenSymbol]}
              sx={{
                height: {
                  xs: theme.spacing(5.5),
                  sm: theme.spacing(8)
                },
                width: {
                  xs: theme.spacing(5.5),
                  sm: theme.spacing(8)
                },
                padding: theme.spacing(0.5),
                background: theme.colors.alpha.trueWhite[100]
              }}
            />
          </LazyLoadComponent>
        )}
      </Box>
    </Box>
  );
};
