import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { useMeasureDimension } from '../../hooks';
import { Box } from '@mui/material';

function ChartContainer({
  loading,
  dataStream = [],
  setLegendInfo,
  candleLevels,
  showMarkers,
  handleOhlcOffsetChange
}) {
  const chartDivRef = useRef();
  const { width = 100, height = 100 } = useMeasureDimension(chartDivRef);
  const [chart, setChart] = useState(null);
  const [barSeries, setBarSeries] = useState(null);

  const ohlc = useMemo(
    () =>
      dataStream.map((item) => {
        const { time, open, high, low, close } = item;
        return { time, open, high, low, close };
      }),
    [dataStream]
  );

  const handleCrosshairMoved = useCallback(
    (param) => {
      if (!param.point) return;
      const iterator = param.seriesPrices.values();
      const ohlc = iterator.next().value;
      const volume = iterator.next().value;
      const time = param.time;

      setLegendInfo({ ...ohlc, volume, time });

      // TODO; dispatch when user hover the last candle
      // if (dataStream[0].time === time) {
      // handleOhlcOffsetChange();
      // }
    },
    [dataStream, setLegendInfo]
  );

  useEffect(() => {
    let isMounted = true;

    const chart = createChart(chartDivRef.current, {
      width: 100,
      height: 100,
      localization: {
        locale: 'en-IN'
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: true,
        leftOffset: 0,
        rightOffset: 10,
        minBarSpacing: 0.001
      },
      layout: {
        // backgroundColor: 'transparent',
        // textColor: '#c1c1c1'
        backgroundColor: '#131722',
        textColor: '#d1d4dc'
      },
      rightPriceScale: {
        scaleMargins: {
          top: 0.1,
          bottom: 0.1
        },
        borderVisible: true
      },
      crosshair: {
        mode: CrosshairMode.Normal,
        vertLine: {
          labelVisible: false
        }
      },
      grid: {
        vertLines: {
          color: 'rgba(42, 46, 57, 0)',
          style: 1,
          visible: true
        },
        horzLines: {
          color: 'rgba(42, 46, 57, 0.6)',
          style: 1,
          visible: true
        }
      }
    });

    const barSeries = chart.addCandlestickSeries({
      upColor: '#17d492',
      downColor: '#f34045',
      thinBars: false,
      openVisible: true,
      priceFormat: {
        type: 'price',
        precision: 6,
        minMove: 0.000001
      },
      priceScale: {
        autoScale: true
      },
      timeScale: {
        autoScale: true
      }
    });

    setChart(chart);

    if (isMounted && !loading) setBarSeries(barSeries);

    chart.subscribeCrosshairMove(handleCrosshairMoved);

    // TODO; Remove the fitContent when we've sufficient data to render
    // fit the chart to the data
    // chart.timeScale().fitContent();

    // TODO: The below code is not working due to library issue
    // set the visible range using time based
    // chart.timeScale().setVisibleRange({
    //   from: dataStream[dataStream.length - 1].time,
    //   to: dataStream[0].time
    // });

    // console.log(dataStream[0].time, dataStream[dataStream.length - 1].time);
    // chart.timeScale().setVisibleRange({
    //   from: 1646280000,
    //   to: 1646280000
    // });

    (() => {
      // always show the last candle (right to left scrolling)
      // chart.timeScale().scrollToPosition(100, true);

      setTimeout(() => {
        // time scale for dispatch previous candles
        const timeScale = chart.timeScale();
        let timer = null;
        timeScale.subscribeVisibleLogicalRangeChange(() => {
          if (timer !== null) return;
          timer = setTimeout(() => {
            const logicalRange = timeScale.getVisibleLogicalRange();
            if (logicalRange !== null) {
              const barsInfo = barSeries.barsInLogicalRange(logicalRange);

              if (barsInfo !== null && barsInfo.barsBefore < -10) {
                if (!loading && isMounted) {
                  // TODO; here we can set the API call with the increase the barsBefore value.
                  // handleOhlcOffsetChange();
                  timer = null;
                  isMounted = false;
                  return;
                }
              }
            }
            timer = null;
          }, 50);
        });
      }, 1500);
    })();

    // TODO: Didn't work properly
    // chart.timeScale().setVisibleRange({
    //   from: 1614556800,
    //   to: 1645099200
    // });

    // Cleanup
    return () => {
      chart.unsubscribeCrosshairMove(handleCrosshairMoved);
      isMounted = false;
    };
  }, [handleCrosshairMoved]);

  useEffect(() => {
    chart && chart.resize(width, height);
  }, [width, height, chart]);

  // TODO: Effect issue is here.
  useEffect(() => {
    let isMounted = true;
    if (barSeries && !loading && isMounted) {
      barSeries.setData(ohlc);
    }

    return () => {
      isMounted = false;
    };
  }, [barSeries, ohlc, loading]);

  useEffect(() => {
    if (barSeries) {
      barSeries.setMarkers(candleLevels);
    }
  }, [showMarkers, candleLevels, barSeries]);

  return <Box ref={chartDivRef} height="60vh" />;
}

export default ChartContainer;
