import React from 'react';
import { Box } from '@mui/material';
import { TopTxnWithinExchange } from './TopTxWithinExchange';
import { TopTxnBtwExAndIn } from './TopTxnBtwExAndIn';
import { TopTransferBtwExchange } from './TopTransferBtwExchange';
import { TopHolders } from './TopHolders';
// import { CoinMarketTable } from './CoinMarketTable';

const OwnerTab = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
      <TopTxnWithinExchange />
      <TopTxnBtwExAndIn />
      <TopTransferBtwExchange />
      <TopHolders />
      {/* <CoinMarketTable /> */}
    </Box>
  );
};

export default OwnerTab;
