import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { Header } from '../../components/Header';
import { Sidebar } from '../../components/Sidebar';
import { Footer } from '../../components/Footer';
import TopCrypto from '../../pages/TopCrypto';
import CoinTracker from '../../pages/CryptoTracker';
import SmartAlert from '../../pages/SmartAlert';
import Watchlist from '../../pages/Watchlist';
import Wealth from '../../pages/Wealth';
import TokenProfiler from '../../pages/TokenProfiler';
import PageNotFound from '../../components/PageNotFound';
import { useSelector } from '../../store';

export const AppLayout = () => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const tickerSymbol = location.pathname.split('/')[2];
  const { tokens, loading, error } = useSelector((state) => state.topToken);

  const supportedTokens = tokens.map((token) => token.ticker_symbol);

  const [validToken, setValidToken] = useState(null);

  useEffect(() => {
    if (location.pathname.split('/')[1] !== 'crypto') {
      history.push('/crypto');
      window.location.reload();
    }
  }, [location.pathname]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !loading && !error && !_.isEmpty(tokens)) {
      if (tickerSymbol && supportedTokens.includes(tickerSymbol)) {
        setValidToken(tickerSymbol);
      } else if (tickerSymbol && tickerSymbol !== 'service') {
        history.push('/404');
      }
    }

    return () => {
      isMounted = false;
    };
  }, [loading, error, tokens, tickerSymbol, history, supportedTokens]);

  return (
    <div
      style={{
        background: theme.general.background
      }}
    >
      <Router>
        {/* header */}
        <Header />
        <Sidebar />

        <Switch>
          {/* Main */}
          <Box
            sx={{
              position: 'relative',
              zIndex: 5,
              flex: 1,
              display: 'flex'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                width: '100%'
              }}
            >
              {/* Main Routes */}
              <Route exact path="/crypto">
                <TopCrypto />
              </Route>
              <Route exact path={`/crypto/:${validToken}`}>
                <CoinTracker />
              </Route>
              <Route exact path={`/crypto/:${validToken}/owners`}>
                <CoinTracker />
              </Route>
              <Route exact path={`/crypto/:${validToken}/insiders`}>
                <CoinTracker />
              </Route>
              <Route exact path={`/crypto/:${validToken}/news`}>
                <CoinTracker />
              </Route>
              <Route exact path="/crypto/service/smart-alert">
                <SmartAlert />
              </Route>
              <Route exact path="/crypto/service/watchlist">
                <Watchlist />
              </Route>
              <Route exact path="/crypto/service/wealth">
                <Wealth />
              </Route>
              <Route exact path="/crypto/token-profiler">
                <TokenProfiler />
              </Route>
              <Route exact path="/404">
                <PageNotFound />;
              </Route>
            </Box>
          </Box>
        </Switch>

        {/* Footer */}
        {/* <Footer /> */}
      </Router>
    </div>
  );
};
