import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import { CommonErrorTooltip } from '../../../../../components/CommonErrorTooltip';
import { Label } from '../../../../../components/Label';
import { SkeletonLoad } from '../../../../../components/SkeletonLoad';
import { ArrowDownwardWrapper } from '../../../../../components/StyledComponents/ArrowDownwardWrapper';
import { ArrowUpwardWrapper } from '../../../../../components/StyledComponents/ArrowUpwardWrapper';
import {
  EnhancedTableHead,
  getComparator,
  stableSort
} from '../../../../../components/Table';
import { GLOBALS } from '../../../../../globals/Globals';
import { useSelector } from '../../../../../store';
import { customNumeral } from '../../../../../utils/utils';

const headCells = [
  {
    id: 'balance_sort',
    numeric: false,
    label: 'Balance',
    showArrow: true,
    align: 'center',
    isSticky: true,
    disablePadding: true,
    left: 0
  },
  {
    id: 'address_count_number',
    numeric: true,
    label: 'Address Count',
    infoTooltip: true,
    infoMsg: GLOBALS.INSIDERS_TOKEN_DISTRIBUTION_ADDRESS_COUNT,
    showArrow: true,
    align: 'center',
    disablePadding: true,
    isSticky: false
  },
  {
    id: 'percentage_address_number',
    numeric: true,
    label: 'Percent Address',
    infoTooltip: true,
    infoMsg: GLOBALS.INSIDERS_TOKEN_DISTRIBUTION_PERCENT_ADDRESS,
    showArrow: true,
    align: 'center',
    disablePadding: true,
    isSticky: false
  },
  {
    id: 'coin_count_number',
    numeric: true,
    label: 'Coin',
    infoTooltip: true,
    infoMsg: GLOBALS.INSIDERS_TOKEN_DISTRIBUTION_COIN,
    showArrow: true,
    align: 'center',
    disablePadding: true,
    isSticky: false
  },
  {
    id: 'coin_value_in_usd_number',
    numeric: true,
    label: 'Usd',
    infoTooltip: true,
    infoMsg: GLOBALS.INSIDERS_TOKEN_DISTRIBUTION_USD,
    showArrow: true,
    align: 'center',
    disablePadding: true,
    isSticky: false
  },
  {
    id: 'percentage_of_coin_number',
    numeric: true,
    label: 'Percent Coin',
    infoTooltip: true,
    infoMsg: GLOBALS.INSIDERS_TOKEN_DISTRIBUTION_COIN_PERCENT,
    showArrow: true,
    align: 'center',
    disablePadding: true,
    isSticky: false
  },
  {
    id: 'action',
    numeric: false,
    label: 'Actions',
    showArrow: false,
    align: 'center',
    disablePadding: true,
    isSticky: false
  }
];

export const TokenDistributionTable = () => {
  const location = useLocation();
  const tickerSymbol = location.pathname.split('/')[2];

  const { tokenDistribution } = useSelector((state) => state.insiders);
  const { loading, error, data } = tokenDistribution;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('balance_sort');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
          stickyHeader
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headCells={headCells}
          />
          <TableBody>
            {loading && error === '' ? (
              <SkeletonLoad rows={10} cols={7} />
            ) : (
              stableSort(
                data.map((item) => {
                  return {
                    ...item,
                    balance: {
                      start_point: String(item.balance.start_point),
                      end_point: String(item.balance.end_point)
                    },
                    balance_sort: _.toNumber(item.balance.start_point),

                    address_count_number: _.toNumber(item.address_count),
                    address_count_string: String(item.address_count),
                    percentage_address_number: _.toNumber(
                      item.percentage_address
                    ),
                    percentage_address_string: String(item.percentage_address),
                    coin_count_number: _.toNumber(item.coin_count),
                    coin_count_string: String(item.coin_count),
                    coin_value_in_usd_number: _.toNumber(
                      item.coin_value_in_usd
                    ),
                    coin_value_in_usd_string: String(item.coin_value_in_usd),
                    percentage_of_coin_number: _.toNumber(
                      item.percentage_of_coin
                    ),
                    percentage_of_coin_string: String(item.percentage_of_coin)
                  };
                }),
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const {
                    address_count_string,
                    percentage_address_string,
                    coin_count_string,
                    coin_value_in_usd_string,
                    percentage_of_coin_number,
                    percentage_of_coin_string
                  } = row;
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0
                        }
                      }}
                    >
                      <TableCell
                        align="center"
                        sx={{
                          position: 'sticky',
                          left: 0,
                          background: (theme) => theme.sidebar.background
                        }}
                      >
                        {row.balance.start_point === '0' ? (
                          <Tooltip
                            placement="top"
                            arrow
                            title={`${row.balance.start_point} - ${row.balance.end_point}`}
                          >
                            <Typography sx={{ whiteSpace: 'nowrap' }}>
                              {`(${customNumeral({
                                value: row.balance.start_point
                              })} - ${customNumeral({
                                value: row.balance.end_point
                              })})`}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            placement="top"
                            arrow
                            title={`${row.balance.start_point} - ${row.balance.end_point}`}
                          >
                            <Typography sx={{ whiteSpace: 'nowrap' }}>
                              {`[${customNumeral({
                                value: row.balance.start_point
                              })} - ${customNumeral({
                                value: row.balance.end_point
                              })})`}
                            </Typography>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <CommonErrorTooltip
                          data={address_count_string}
                          isNumberAsString
                          style={{ whiteSpace: 'nowrap' }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <CommonErrorTooltip
                          data={percentage_address_string}
                          style={{ whiteSpace: 'nowrap' }}
                          isNumberAsString
                          suffixData="%"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <CommonErrorTooltip
                          data={coin_count_string}
                          style={{ whiteSpace: 'nowrap' }}
                          isNumberAsString
                        />
                      </TableCell>
                      <TableCell align="center">
                        <CommonErrorTooltip
                          data={coin_value_in_usd_string}
                          isNumberAsString
                          prefixData="$"
                          style={{ whiteSpace: 'nowrap' }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Label
                            color={
                              percentage_of_coin_number > 0
                                ? 'success'
                                : 'error'
                            }
                          >
                            <CommonErrorTooltip
                              data={percentage_of_coin_string}
                              style={{ whiteSpace: 'nowrap' }}
                              isNumberAsString
                              suffixData="%"
                            />
                          </Label>
                          {percentage_of_coin_number > 0 ? (
                            <ArrowUpwardWrapper
                              sx={{
                                ml: 0.5,
                                mr: -0.2
                              }}
                              fontSize="small"
                            />
                          ) : (
                            <ArrowDownwardWrapper
                              sx={{
                                ml: 0.5,
                                mr: -0.2
                              }}
                              fontSize="small"
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Link
                          to={`/crypto/${tickerSymbol}/insiders/?view=AddressRange&from=${row.balance.start_point}&to=${row.balance.end_point}`}
                        >
                          <VisibilityTwoToneIcon
                            sx={{
                              color: '#0AB6FF',
                              cursor: 'pointer'
                            }}
                            fontSize="small"
                          />
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
