import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Divider, Typography } from '@mui/material';
import _ from 'lodash';
import { useDispatch, useSelector } from '../../../../../store';
import { getTopHolderExchange } from '../../../../../slices/owner';
import { useLocation } from 'react-router';
import { GLOBALS } from '../../../../../globals/Globals';
import { TopHoldersTable } from './TopHoldersTable';

export const TopHolders = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const tickerSymbol = location.pathname.split('/')[2];

  const dispatch = useDispatch();
  const { topHoldersExchange } = useSelector((state) => state.owner);
  const { tokenDetails } = useSelector((state) => state.crypto);
  const { loading, error, data } = topHoldersExchange;

  useEffect(() => {
    if (!tokenDetails.data.loading) {
      dispatch(getTopHolderExchange(tickerSymbol));
    }
  }, [tokenDetails, tickerSymbol]);

  const _render = () => {
    if (!loading && _.isEmpty(error) && _.isEmpty(data)) {
      return (
        <Typography
          sx={{
            py: 10
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {t("We couldn't find any data")}
        </Typography>
      );
    } else if (!loading && !_.isEmpty(error)) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">{GLOBALS.ERROR_MSG}</Typography>
        </Box>
      );
    }
    // render table
    return <TopHoldersTable />;
  };

  return (
    <>
      <Card>
        <Box px={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              {t('Top Holders (Exchange)')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center'
              }}
            >
              {/* <Tooltip arrow title="Filter">
                <IconButtonWrapper color="primary">
                  <FilterAltTwoToneIcon fontSize="small" />
                </IconButtonWrapper>
              </Tooltip> */}
            </Box>
          </Box>
        </Box>
        <Divider />

        {/* Render Conditionally */}
        {_render()}
      </Card>
      {/* TopHolders Chart */}
      {/* <TopTxnWithinExchangeChart /> */}
    </>
  );
};
