import axios from 'axios';
import { GLOBALS } from '../globals/Globals';
// ----------------------------------------------------------------------

let cancelToken;

// Check if there are any previous pending requests
if (typeof cancelToken !== typeof undefined) {
  cancelToken.cancel('Operation canceled due to new request.');
}

console.log(cancelToken);

// Save the cancel token for the current request
cancelToken = axios.CancelToken.source();

// --------------- DEV -------------------//

const axiosInstance = axios.create({
  baseURL: 'https://dev.spiking-crypto.tk'
});

// --------------- LOCAL ----------------//

// const axiosInstance = axios.create({
//   baseURL: 'http://192.168.10.88:3000'
// });

// const axiosInstance = axios.create({
//   baseURL: 'http://192.168.11.170:3000'
// });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject((error.response && error.response.data) || GLOBALS.ERROR_MSG)
);

export default axiosInstance;
