import { IconButton } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PageNotFoundImg from '../../assets/404landingpage.jpg';

const PageNotFound = () => {
  return (
    <IconButton component={RouterLink} to="/crypto">
      <img src={PageNotFoundImg} alt="404 not found" />
    </IconButton>
  );
};

export default PageNotFound;
